
export default {
    fa: {
        home: {
            'Advertise': 'تبلیغ ها',
            'Report': 'گزارش',
            'Live': 'پخش زنده',
            'Archive': 'آرشیو',
            'Profile': 'پروفایل',
            'Notification': 'پیام ها',
            'Search video from': 'جستجوی تبلیغ در',
            'Choose company': 'انتخاب شرکت',
            'Choose category': 'انتخاب دسته بندی',
            'Add video': 'افزودن تبلیغ',
            'All videos': 'تمام تبلیغ ها',
            'Save': 'ذخیره',
            'Cancel': 'انصراف',
            'Description about video': 'توضیحات درباره تبلیغ',
            'Your text': 'متن مورد نظر',
            'Name': 'نام',
            'Date from': 'تاریخ از',
            'Date to': 'تاریخ تا',
            'Active': 'فعال',
            'Inactive': 'غیر فعال',
            'Upload video': 'بارگزاری تبلیغ',
            'Username': 'نام کاربری',
            'Password': 'رمز عبور',
            'Login': 'ورود',
            'No': 'ردیف',
            'Advertise name': 'نام تبلیغ',
            'No': 'ردیف',
            'Company name': 'نام شرکت',
            'Broadcast date': 'تاریخ پخش',
            'Channel': 'کانال',
            'Box': 'جعبه',
            'Video': 'تبلیغ',
            'All reports': 'گزارش ها',
            'Period': 'مدت',
            'Apply filter': 'جستجو',
            'Delete items': 'حذف آیتم‌ها',
            'Profile': 'کاربری',
            'No': 'ردیف',
            'All users': 'کاربران',
            'Companies': 'شرکت ها',
            'Categories': 'دسته ها',
            'Trends': 'تگ ها',
            'Create new user': 'ایجاد کاربر جدید',
            'First Name': 'نام',
            'Last name': 'نام خانوادگی',
            'Tel': 'تلفن',
            'Email': 'ایمیل',
            'Status': 'وضعیت',
            'Active': 'فعال',
            'Suspend': 'غیرفعال',
            'Delete user': 'حذف کاربر',
            'Warning! Do You want to continue?': 'از انجام این عملیات اطمینان دارید؟',
            'Yes': 'بله',
            'No': 'خیر',
            'Cancel': 'انصراف',
            'User name': 'نام کاربری',
            'First name': 'نام',
            'Full name': 'نام و نام خانوادگی',
            'Repeat password': 'تکرار رمز عبور',
            'Mega trend': 'مگاترند',
            'Sub trend': 'ساب ترند',
            'Choose channel': 'کانال را انتخاب کنید',
            'Edit information': 'ویرایش اطلاعات',
            'From (date / time)': 'تاریخ و زمان (از)',
            'To (date / time)': 'تاریخ و زمان (تا)',
            'Role': 'نقش',
            'Choose type': 'انتخاب نوع تبلیغ',
            'Teaser': 'تیزر',
            'Advertisement logo': 'آرم آگهی',
            'Subtitle': 'زیرنویس',
            'from': 'از',
            'Choose Advertise': 'انتخاب کنید',
            'Ad type': 'نوع تبلیغ',
            'Choose item': 'انتخاب کنید',
            'Type': 'نوع',
            'Wrong user name or password': 'نام کاربری یا رمز عبور اشتباه است',
            'Map': 'نقشه',
            'All companies': 'همه شرکت ها',
            'Current password': 'رمز عبور جاری',
            'New password': 'رمز عبور جدید',
            'Delete': 'حذف',
            'Chart': 'نمودار',
            'Uploading': 'در حال بارگزاری',
            'Repeat new password': 'تکرار رمز عبور',
            'View': 'شاهد',
            'Clear filters': 'پاک سازی فیلتر',
            'Category': 'دسته بندی',
            'All channels': 'همه کانال ها',
            'All media': 'همه رسانه ها',
            'All type': 'همه انواع آگهی',
            'Ad reporting': 'ریپورتاژ آگهی',
            'Program presentation': 'تقدیم برنامه',
            'TV': 'تلوزیون',
            'Radio': 'رادیو',
            'Fee class': 'طبقه',
            'Fee class special': 'طبقه صنف',
            'Position': 'محل پخش',
            'Before': 'قبل',
            'Between': 'بین',
            'After': 'بعد',
            'Program': 'عنوان برنامه',
            'Cost': 'مبلغ',
            'Conductor': 'کنداکتور',
            'Media type': 'انتخاب نوع رسانه',
            'All categories': 'همه دسته بندی ها',
            'Export excel': 'خروجی اکسل',
            'Export box': 'خروجی باکس',
            'Items': 'آیتم',
            'Pie': 'پای',
            'Bar': 'میله ای',
            'Company': 'شرکت',
            'Category': 'دسته بندی',
            'Subcategory': 'زیر دسته بندی',
            'All medias': 'همه رسانه ها',
            'All ad types': 'همه انواع آگهی',
            'Clear filters': 'پاک سازی فیلتر',
            'Play time': 'زمان پخش',
            'Code': 'کد',
            'Advanced Search': 'جستجوی پیشرفته',
            'Enter Ads Code then Press Enter': 'کد تبلیغ را وارد و اینتر بزنید',
            'Start Time': 'زمان شروع',
            'End Time': 'زمان پایان',
            'No Choice': 'بدون انتخاب',
            'Edit': 'ویرایش',
            'Channel not selected': 'کانال انتخاب نشده است',
            'No start or end date entered': 'تاریخ شروع یا پایان وارد نشده است',
            'The start date must be before the end date': 'تاریخ شروع میبایست قبل از تاریخ پایان باشد',
            'The operation was successful': 'عملیات با موفقیت صورت گرفت',
            'The operation was unsuccessful': 'عملیات با خطا مواجه شد',
            'The company name is duplicate': 'نام شرکت تکراری است',
            'The new password is not the same as repeating it': 'رمز جدید با تکرار آن یکسان نیست',
            'The current password is incorrect': 'رمز جاری صحیح نیست',
            'The current password and the new password must be different': 'رمز جاری و رمز جدید میبایست متفاوت باشند',
            'Position Not Selected': 'محل پخش انتخاب نشده است',
            'Program Title Not Selected': 'عنوان برنامه وارد نشده است',
            'Group Value Is Invalid': 'مقدار گروه به درستی وارد نشده است',
            'It is necessary to enter the date range to get the output': 'برای گرفتن خروجی وارد کردن بازه تاریخ الزامی است',
            'The title of the category is duplicate': 'عنوان دسته تکراری است',
            'Ad successfully added': 'تبلیغ با موفقیت افزوده شد',
            'The Ad file is duplicate': 'فایل تبلیغ تکراری است',
            'The Ad name is duplicate': 'نام تبلیغ تکراری است',
            'Ad successfully edited': 'تبلیغ با موفقیت ویرایش شد',
            'Ad name not entered': 'نام تبلیغ وارد نشده است',
            'Ad file not entered': 'فایل تبلیغ وارد نشده است',
            'No company selected': 'شرکت انتخاب نشده است',
            'No brand selected': 'برند انتخاب نشده است',
            'The minimum allowed size of the code is 4 digits': 'حداقل اندازه مجاز کد ۴ رقم است',
            'Ad type not selected': 'نوع تبلیغ انتخاب نشده است',
            'Item added successfully': 'آیتم با موفقیت افزوده شد',
            'Choose Brand': 'انتخاب برند',
            'Brand name': 'نام برند',
            'Brands name': 'نام برندها',
            'Subcategories': 'زیردسته ها',
            'Subcategory': 'زیر دسته بندی',
            'Session': 'وقت',
            'All brands': 'همه برندها',
            'All subcategories': 'همه زیردسته بندی ها',
            'Ad code': 'کد تبلیغ',
            'Count': 'تعداد',
            'Duration': 'ثانیه',
            'Download': 'دانلود',
            'Choose brands': 'برندها را انتخاب کنید',
            'Group': 'گروه',
            'For full system operation, please use Firefox browser':'برای عملکرد کامل سامانه، لطفا از مرورگر فایرفاکس استفاده کنید',
        },
    },
    ru: {
        home: {
            'Advertise': 'Реклама',
            'Report': 'Отчет',
            'Live': 'Прямой эфир',
            'Archive': 'Архив',
            'Profile': 'Профиль',
            'Notification': 'Уведомления',
            'Search video from': 'Поиск рекламы в',
            'Choose company': 'Выбрать компанию',
            'Choose category': 'Выбрать категорию',
            'Add video': 'Добавить рекламу',
            'All videos': 'Все рекламы',
            'Save': 'Сохранить',
            'Cancel': 'Отмена',
            'Description about video': 'Описание рекламы',
            'Your text': 'Ваш текст',
            'Name': 'Имя',
            'Date from': 'Дата с',
            'Date to': 'Дата до',
            'Active': 'Активный',
            'Inactive': 'Неактивный',
            'Upload video': 'Загрузить рекламу',
            'Username': 'Имя пользователя',
            'Password': 'Пароль',
            'Login': 'Войти',
            'No': '№',
            'Advertise name': 'Название рекламы',
            'Company name': 'Название компании',
            'Broadcast date': 'Дата трансляции',
            'Channel': 'Канал',
            'Box': 'Коробка',
            'Video': 'Реклама',
            'All reports': 'Все отчеты',
            'Period': 'Период',
            'Apply filter': 'Применить фильтр',
            'Delete items': 'Удалить элементы',
            'Profile': 'Профиль',
            'No': 'Номер',
            'All users': 'Все пользователи',
            'Companies': 'Компании',
            'Categories': 'Категории',
            'Trends': 'Тренды',
            'Create new user': 'Создать нового пользователя',
            'First Name': 'Имя',
            'Last name': 'Фамилия',
            'Tel': 'Телефон',
            'Email': 'Электронная почта',
            'Status': 'Статус',
            'Active': 'Активен',
            'Suspend': 'Приостановлен',
            'Delete user': 'Удалить пользователя',
            'Warning! Do You want to continue?': 'Внимание! Вы хотите продолжить?',
            'Yes': 'Да',
            'No': 'Нет',
            'Cancel': 'Отмена',
            'User name': 'Имя пользователя',
            'First name': 'Имя',
            'Full name': 'Полное имя',
            'Repeat password': 'Повторите пароль',
            'Mega trend': 'Мегатренд',
            'Sub trend': 'Субтренд',
            'Choose channel': 'Выберите канал',
            'Edit information': 'Редактировать информацию',
            'From (date / time)': 'С (дата / время)',
            'To (date / time)': 'До (дата / время)',
            'Role': 'Роль',
            'Choose type': 'Выберите тип рекламы',
            'Teaser': 'Тизер',
            'Advertisement logo': 'Логотип рекламы',
            'Subtitle': 'Субтитры',
            'from': 'от',
            'Choose Advertise': 'Выберите рекламу',
            'Ad type': 'Тип рекламы',
            'Choose item': 'Выберите элемент',
            'Type': 'Тип',
            'Wrong user name or password': 'Неверное имя пользователя или пароль',
            'Map': 'Карта',
            'All companies': 'Все компании',
            'Current password': 'Текущий пароль',
            'New password': 'Новый пароль',
            'Delete': 'Удалить',
            'Chart': 'График',
            'Uploading': 'Загрузка',
            'Repeat new password': 'Повторите новый пароль',
            'View': 'Просмотр',
            'Clear filters': 'Очистить фильтры',
            'Category': 'Категория',
            'All channels': 'Все каналы',
            'All media': 'Все медиа',
            'All type': 'Все типы рекламы',
            'Ad reporting': 'Рекламный отчет',
            'Program presentation': 'Презентация программы',
            'TV': 'Телевидение',
            'Radio': 'Радио',
            'Fee class': 'Класс тарифа',
            'Fee class special': 'Специальный класс тарифа',
            'Position': 'Позиция',
            'Before': 'До',
            'Between': 'Между',
            'After': 'После',
            'Program': 'Программа',
            'Cost': 'Стоимость',
            'Conductor': 'Проводник',
            'Media type': 'Тип медиа',
            'All categories': 'Все категории',
            'Export excel': 'Экспорт в Excel',
            'Export box': 'Экспорт коробки',
            'Items': 'Элементы',
            'Pie': 'Круговая диаграмма',
            'Bar': 'Столбчатая диаграмма',
            'Company': 'Компания',
            'Category': 'Категория',
            'Subcategory': 'Подкатегория',
            'All medias': 'Все медиа',
            'All ad types': 'Все типы рекламы',
            'Clear filters': 'Очистить фильтры',
            'Play time': 'Время воспроизведения',
            'Code': 'Код',
            'Advanced Search': 'Расширенный поиск',
            'Enter Ads Code then Press Enter': 'Введите код рекламы и нажмите Enter',
            'Start Time': 'Время начала',
            'End Time': 'Время окончания',
            'No Choice': 'Без выбора',
            'Edit': 'Редактировать',
            'Channel not selected': 'Канал не выбран',
            'No start or end date entered': 'Дата начала или окончания не введена',
            'The start date must be before the end date': 'Дата начала должна быть раньше даты окончания',
            'The operation was successful': 'Операция прошла успешно',
            'The operation was unsuccessful': 'Операция не удалась',
            'The company name is duplicate': 'Имя компании дублируется',
            'The new password is not the same as repeating it': 'Новый пароль не совпадает с его повтором',
            'The current password is incorrect': 'Текущий пароль неверен',
            'The current password and the new password must be different': 'Текущий пароль и новый пароль должны отличаться',
            'Position Not Selected': 'Позиция не выбрана',
            'Program Title Not Selected': 'Название программы не выбрано',
            'Group Value Is Invalid': 'Значение группы неверно',
            'It is necessary to enter the date range to get the output': 'Для получения вывода необходимо ввести диапазон дат',
            'The title of the category is duplicate': 'Название категории дублируется',
            'Ad successfully added': 'Реклама успешно добавлена',
            'The Ad file is duplicate': 'Файл рекламы дублируется',
            'The Ad name is duplicate': 'Имя рекламы дублируется',
            'Ad successfully edited': 'Реклама успешно отредактирована',
            'Ad name not entered': 'Имя рекламы не введено',
            'Ad file not entered': 'Файл рекламы не введен',
            'No company selected': 'Компания не выбрана',
            'No brand selected': 'Бренд не выбран',
            'The minimum allowed size of the code is 4 digits': 'Минимально допустимый размер кода - 4 цифры',
            'Ad type not selected': 'Тип рекламы не выбран',
            'Item added successfully': 'Элемент успешно добавлен',
            'Choose Brand': 'Выберите бренд',
            'Brand name': 'Название бренда',
            'Brands name': 'Названия брендов',
            'Subcategories': 'Подкатегории',
            'Subcategory': 'Подкатегория',
            'Session': 'Сессия',
            'All brands': 'Все бренды',
            'All subcategories': 'Все подкатегории',
            'Ad code': 'Код рекламы',
            'Count': 'Количество',
            'Duration': 'Продолжительность',
            'Download': 'Загрузить',
            'Choose brands': 'Выберите бренды',
            'Group': 'Группа',
            'For full system operation, please use Firefox browser':'Для полноценной работы системы, пожалуйста, используйте браузер Firefox',
        }
    },
    // add other language to the object
};