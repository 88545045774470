import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function DropDown(props) {
    const [selected, setSelected] = useState([])
    const ref = useRef();
    const [searchText, setSearchText] = useState('')
    const [displayList, setDisplayList] = useState(false)
    const [labelText, setLabelText] = useState('')
    const [multiple, setMultiple] = useState(true)
    const { t } = useTranslation(['home']); // "home" is namespace
    useOnClickOutside(ref, () => setDisplayList(false));
    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }

                    handler(event);
                };

                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);

                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },
            [ref, handler]
        );
    }
    useEffect(() => {
        //console.info(props.selectedValues)
        if (props.multiple === false)
            setDisplayList(false)
        if (props.selectedValues)
            setSelected(props.selectedValues)
        if (props.selectedValues?.length === 0) {
            setLabelText(props.placeholder)
        }
        else if (props.selectedValues) {
            if (props.selectedValues.length === 1)
                setLabelText(props.items.filter(x => props.selectedValues.indexOf(x.id) > -1).map(x => x.name).join('، '))
            else
                setLabelText('(' + props.selectedValues.length + ') ' + t('Items'))
        }
        return () => {

        }
    }, [props.selectedValues, props.placeholder, selected])
    useEffect(() => {
        setMultiple(props.multiple === undefined || props.multiple)
    }, [props.multiple])

    return (
        <div className='drop-down-container' ref={ref} style={props.style}>
            <label onClick={e => { if (!props.disabled) setDisplayList(!displayList) }} className={props.disabled ? 'disabled' : ''}>
                {labelText}
                {props.selectedValues.length > 0 && <i className='fa fa-times' onClick={() => { props.onChange([]) }}></i>}
            </label>
            <ul className='drop-down-list' style={{ display: displayList ? 'block' : 'none' }} >
                <li>
                    <input onChange={e => setSearchText(e.target.value)} />
                    {props.hasInsert && props.onAdd && searchText.length > 2 && props.items.filter(x => x.name === searchText).length === 0
                        && <button type='button' className='insert'
                            onClick={() => props.onAdd(searchText)}>
                            <img alt="img" src={require('../img/plus.png')} />
                        </button>}
                </li>
                {props.items
                    .filter(x => x.name.indexOf(searchText) > -1 || props.selectedValues.indexOf(x.id) > -1)
                    //.sort((x,y) => selected.indexOf(x.id) > -1 ? 0 : props.items.indexOf(x) + 1)
                    .map((item, i) => {
                        return <li key={i}
                            className={selected.indexOf(item.id) > -1 ? 'choosed' : ''}
                            onClick={() => {
                                if (multiple) {
                                    if (selected.indexOf(item.id) > -1) {
                                        props.onChange(selected.filter(x => x !== item.id))
                                        setSelected(selected.filter(x => x !== item.id))
                                    }
                                    else {
                                        props.onChange([...selected, item.id])
                                        setSelected([...selected, item.id])
                                    }
                                }
                                else {
                                    if (selected.indexOf(item.id) === -1) {
                                        props.onChange([item.id])
                                        setSelected([item.id])
                                    }
                                    else {
                                        setSelected([])
                                        props.onChange([])
                                    }
                                }
                            }}>
                            {item.name}
                        </li>
                    })}
            </ul>
        </div>
    )
}