import React, { useState } from "react";
import './index.css';

export default function ToggleSwitch(props) {
    const [active, setActive] = useState(props.active)

    return (
        <div style={{ padding: '0.5rem ', direction: 'ltr' }} >
            {props.dir === 'ltr' ?
                <>
                    <label onClick={() => { props.onChange(!active); setActive(!active) }} style={{marginRight:'13px'}}>{props.label}</label>
                    &nbsp;
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name={props.Name}
                            id={props.Name}
                            defaultChecked={active}
                            checked={active}
                        />
                        <label className="toggle-switch-label" htmlFor={props.Name} onClick={() => { props.onChange(!active); setActive(!active) }}>
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                        </label>
                    </div>
                </>
                :
                <>
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            name={props.Name}
                            id={props.Name}
                            defaultChecked={active}
                            checked={active}
                        />
                        <label className="toggle-switch-label" htmlFor={props.Name}
                         onClick={() => { props.onChange(!active); setActive(!active) }}>
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                        </label>
                    </div>
                    &nbsp;
                    <label style={{marginLeft:'13px'}} onClick={() => { props.onChange(!active); setActive(!active) }} >{props.label}</label>
                </>
            }
        </div>
    );

}
