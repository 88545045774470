import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../css/right.css'
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBIcon,
  MDBLink,
  MDBProgress,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  toast,
} from 'mdbreact';
import { useUserValue, useActions } from '../features/login';
import { Link } from 'react-router-dom'
import "./userBadge.css";
import { Language, BASE_URL, convertDateStr, instanceAxios, isRtl } from '../config';
import axios from 'axios';
import { useInterval } from 'react-interval-hook';
import { deleteBoxExport, getBoxStatusExport, logout } from '../api';

export default function TopNavigation(props) {
  const [visible, setVisibility] = useState(false);

  const { setLogin } = useActions();
  const { user } = useUserValue();
  const { onLinkClick } = props;


  const handleToggleClickA = () => {
    props.onSideNavToggleClick();
  }


  const logoutHandler = async () => {

    const { data, error } = await logout()
    if (data) {
      setLogin({ user: {}, isLogin: false });
    }
  }
  return (
    <>
      <MDBNavbar
        light
        expand='md'
        scrolling
        style={{ zIndex: 11, backgroundColor: '#F9FAFD', borderBottom: '.01rem solid #DCE1EA', paddingBottom: '2rem' }}
      >
        <MDBNavbarNav>
          <div
            className={(isRtl() ? 'flex-row' : 'flex-row-reverse') + " d-flex  justify-content-center align-items-center user-badge-container"}
            style={{ direction: 'ltr' }}
          >
            <MDBLink to='/Profile' topLevel={true} onClick={onLinkClick}
              className={(isRtl() ? 'mr-3' : 'ml-3') + " d-flex flex-column justify-content-center"} style={{ textAlign: 'right' }}>
              <div>
                <p className='m-0' style={{ color: 'black', fontWeight: 'bold', fontSize: '20px' }}>{user?.first_name + ' ' + user?.last_name}</p>
              </div>
            </MDBLink>
            <div className="user-activity" style={{}}></div>
          </div>
        </MDBNavbarNav>
        <MDBNavbarNav
          expand='sm'
          style={{ flexDirection: 'row' }}
        >
        </MDBNavbarNav>
        <Link topLevel={true} to='/'
          onClick={() => {
            logoutHandler();
          }} className='mt-3'>
          <img src={require('../assets/img/logout.png')} alt='logout' style={isRtl() ? { width: '24px', height: '24px' } : { width: '24px', height: '24px', transform: 'rotate(180deg)' }} />

        </Link>
        <MyPopover />

        <div
          onClick={handleToggleClickA}
          key='sideNavToggleA'
          style={isRtl() ? {
            lineHeight: '32px',
            marginLeft: '1em',
            verticalAlign: 'middle',
            cursor: 'pointer',
            top: '3rem',
            position: 'absolute',
            right: '3rem'
          } : {
            lineHeight: '32px',
            marginRight: '1em',
            verticalAlign: 'middle',
            cursor: 'pointer',
            top: '3rem',
            position: 'absolute',
            left: '3rem'
          }}
        >
          <MDBIcon icon='bars' color='white' pull={isRtl() ? 'right' : 'left'} size='lg' />
        </div>
      </MDBNavbar >

    </>
  )
}
const MyPopover = () => {
  const [show, setShow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [processes, setProcesses] = useState([])
  const [showModal, setShowModal] = useState(false)
  const deleteProcess = async e => {

    if (window.confirm('آیا از انجام این عمیات مطمئنید؟')) {

      const { data, error } = await deleteBoxExport(e);
      if (data) {
        getStatus()
        toast.success('عملیات با موفقت انجام شد', {
          closeButton: false
        });
      }
      else {
        toast.error('خطا در انجام عملیات', {
          closeButton: false
        });
      }
    }
  }
  const getStatus = async () => {
    if (isSent)
      return
    if (!show)
      return
    setIsSent(true);
    const { data, error } = await getBoxStatusExport();
    if (data) {
      setProcesses(data)
    }
    setIsSent(false);
  }
  const { } = useInterval(getStatus, 5000, { autoStart: true, immediate: true, selfCorrecting: true })

  return <div style={{ position: 'relative' }}>
    <button className='mt-3 mx-3'
      style={{ background: 'none', border: 'none' }} color="primary"
      onClick={() => setShow(pre => !pre)}
    >
      <i className='fa fa-cog'></i>
    </button>
    <div style={{
      position: 'absolute', display: show ? 'block' : 'none',
      background: 'white',
      padding: '5px',
      left: '10px',
      width: '200px',
      boxShadow: '0 0 2px #CCC',
      lineHeight: '1rem'
    }}>
      <div className='d-flex justify-content-between align-items-center mb-3' >
        <b style={{ fontFamily: 'DiodrumArabic' }}>درخواستهای در حال اجرا</b>
      </div>
      <div>
        {
          processes && processes?.filter(x => x.progress < 100).map((item, i) => <>
            <p key={i} style={{ lineHeight: '1rem' }}>
              <label className='text-center'>{convertDateStr(item.timestamp, true, true)}</label>
              <MDBProgress min={0} max={100} value={item.progress} className="mb-2" animated color='success' />
            </p>
            <hr />
          </>)
        }
        <button type='button' className='w-100' style={{ fontFamily: 'DiodrumArabic', border: 'none' }} onClick={e => setShowModal(true)}>بیشتر ...</button>
      </div>
    </div>

    <MDBModal size='lg' isOpen={showModal} toggle={() => setShowModal(!showModal)} >
      <MDBModalHeader toggle={() => setShowModal(!showModal)}></MDBModalHeader>
      <MDBModalBody>
        {
          processes?.map((item, i) => {
            return <p key={i} style={{ fontFamily: 'DiodrumArabic' }}>
              <p className='bg-light p-3 rounded'>زمان درخواست:&nbsp;
                <p style={{ direction: 'ltr', display: 'inline' }}>
                  {convertDateStr(item.timestamp, true, true)}
                </p>
              </p>

              {
                Object.entries(item.filters).map((x, j) => <p key={j}>{`${x[0]} : ${x[1]}`}</p>)
              }

              {item.progress < 100 && <MDBProgress min={0} max={100} value={item.progress} className="my-2" animated />}
              {item.progress < 100 && <MDBBtn size='sm' onClick={e => deleteProcess(item.timestamp)}>انصراف</MDBBtn>}
              <hr />
            </p>
          })
        }
      </MDBModalBody>
    </MDBModal>
  </div>
}