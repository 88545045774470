import { SET_LOGIN } from './actionTypes';

const initialState = {
  user: {
    user: null,
    isLogin: false
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        user: action.user,
        isLogin: action.isLogin
      };

    default:
      return state;
  }
};

export default reducer;
