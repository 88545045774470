import {
    MDBCol, MDBContainer, MDBIcon, MDBRow,
    MDBTable, MDBTableHead, MDBTableBody, MDBModal, MDBModalHeader, MDBModalBody,
    toast
} from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Language, BASE_URL, convertDateStr, convertTimestap, refreshToken, convertToHijri, isRtl, getTimeMode } from '../../config';
import './index.scss'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useTranslation } from 'react-i18next';
import ClapprPlayer from '../../assets/clapper';
import DatePicker from "react-modern-calendar-datepicker";
import { useUserValue } from '../../features/login';
import DropDown from '../../assets/DropDown';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import * as api from '../../api';
import ToggleSwitch from '../common/ToggleSwitch';
import Fee from '../common/Fee';

export default function Report(props) {
    const { user } = useUserValue();

    const [companies, setCompanies] = useState([])
    const [ads, setAds] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [brands, setBrands] = useState([])
    const [channels, setChannels] = useState([])
    const [categories, setCategories] = useState([])
    const [subcategory, setSubcategory] = useState([])
    const [reports, setReports] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [selectedAd, setSelectedAd] = useState([])
    const [selectedChannel, setSelectedChannel] = useState([])
    const [showBoxModal, setShowBoxModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [video, setVideo] = useState('')
    const [boxUrl, setBoxUrl] = useState('')
    const [collapseId, setCollapseId] = useState(-1)
    const [selectedEditAd, setSelectedEditAd] = useState(null)
    const [codeFilter, setCodeFilter] = useState([])
    const [tagFilter, setTagFilter] = useState([])
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });
    const [startTime, setStartTime] = useState('00:00')
    const [endTime, setEndTime] = useState('23:59')
    const [feeClass1, setFeeClass1] = useState(0)
    const [feeClass2, setFeeClass2] = useState(0)
    const [position, setPosition] = useState(0)
    const [program, setProgram] = useState('')
    const [applyToBox, setApplyToBox] = useState(1);
    const [adType, setAdType] = useState([])
    const [mediaType, setMediaType] = useState([])
    const [revo, setRevo] = useState(null)

    useEffect(() => {
        getReports();
        getCompanies();
        getTags();
        getChannel();
    }, [currentPage])

    const getParams = async () => {
        let params = new URLSearchParams();

        let hasStarTime = startTime !== '';
        let startHour = 0, startMin = 0, endHour = 0, endMin = 0;
        if (hasStarTime) {
            let tmp = startTime.split(':')
            startHour = tmp[0];
            startMin = tmp[1]
        }
        let hasEndTime = endTime !== '';
        if (hasEndTime) {
            let tmp = endTime.split(':')
            endHour = tmp[0];
            endMin = tmp[1]
        }

        if (selectedDayRange.from !== null) {
            params.append('start_time', convertTimestap(selectedDayRange.from, { hour: startHour, minute: startMin }));
        }
        if (selectedDayRange.to !== null) {
            params.append('end_time', convertTimestap(selectedDayRange.to, { hour: endHour, minute: endMin, second: 59 }))
        }

        for (let i = 0; i < codeFilter.length; i++) {
            params.append('ad_code', codeFilter[i])
        }
        for (let i = 0; i < tagFilter.length; i++) {
            params.append('tag_id', tagFilter[i])
        }
        for (let i = 0; i < subcategory.length; i++) {
            params.append('tag_id', subcategory[i])
        }
        for (let i = 0; i < selectedCompany.length; i++) {
            params.append("company_id", selectedCompany[i]);
        }
        for (let i = 0; i < selectedBrand.length; i++) {
            params.append("brand_id", selectedBrand[i]);
        }
        for (let i = 0; i < selectedAd.length; i++) {
            params.append("ad_id", selectedAd[i]);
        }
        for (let i = 0; i < adType.length; i++) {
            params.append("ad_type", adType[i]);
        }
        for (let i = 0; i < selectedChannel.length; i++) {
            params.append("channel_id", selectedChannel[i]);
        }
        for (let i = 0; i < mediaType.length; i++) {
            params.append('media_type', mediaType[i])
        }

        return params
    }
    const getReports = async () => {
        let params = await getParams();

        params.append('page', currentPage)
        params.append('items_per_page', 10)
        setReports([])

        const { data, error } = await api.getReport(params)
        if (data) {
            setPageCount(data.page_count)
            setReports(data.items)
            setShowFilterModal(false)
        }

    }


    const getAds = async () => {
        if (selectedCompany.length === 0) {
            setAds([])
            return
        }
        let params = new URLSearchParams()

        for (let i = 0; i < selectedCompany.length; i++) {
            params.append("company_id", selectedCompany[i]);
        }

        for (let i = 0; i < selectedBrand.length; i++) {
            params.append("brand_id", selectedBrand[i]);
        }

        const { data, error } = await api.getAds(params);
        if (data) {
            setAds(data)
        }


    }
    const getChannel = async () => {
        const { data, error } = await api.getChannels();
        if (data) {
            setChannels(data.sort((x, y) => x.id > y.id))
        }

    }

    const getCompanies = async () => {
        const { data, error } = await api.getCompanies();
        if (data) {
            setCompanies(data.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }

        const { data: dataBrands, error: errorBrands } = await api.getBrands();
        if (dataBrands) {
            setBrands(dataBrands.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }

    }
    const getTags = async () => {

        const { data, error } = await api.getTags();
        if (data) {
            setCategories(data)
        }

    }
    const doSearch = () => {
        getReports();
    }
    const getTypeTitle = (id) => {

        switch (id) {
            case 0:
                return t('Teaser');
            case 1:
                return t('Subtitle');
            case 2:
                return t('Advertisement logo');
            case 3:
                return t('Ad reporting')
            case 4:
                return t('Program presentation')
        }
    }
    const { t } = useTranslation(['home']); // "home" is namespace

    useEffect(() => {
        if (!showVideoModal)
            setVideo('')
        if (revo)
            URL.revokeObjectURL(revo)
        return () => {

        }
    }, [showVideoModal])
    useEffect(() => {
        if (!showBoxModal)
            setBoxUrl('')
        return () => {

        }
    }, [showBoxModal])
    const downloadVideo = (e, brand, ad, ti, chn) => {
        toast.success(t('در حال پردازش دانلود'), {
            closeButton: false
        });
        var xhr = new XMLHttpRequest();

        xhr.onload = function () {
            const tmp = URL.createObjectURL(xhr.response);
            var a = document.createElement('a')
            a.href = tmp;
            a.download = `${brand}-${ad}-${ti}-${chn}.mp4'`;
            a.click();

        };

        xhr.open('GET', `${BASE_URL}/match/${e}/video`);
        xhr.setRequestHeader('Authorization', `Bearer  ${localStorage.getItem('accessToken')}`)
        xhr.onreadystatechange = function () { // Call a function when the state changes.
            if (this.readyState !== XMLHttpRequest.DONE || this.status !== 200) {

            }
        }
        xhr.onerror = () => {
            refreshToken();
        }
        xhr.responseType = 'blob';
        xhr.send();
    }
    const setVideoUrl = (e) => {
        var xhr = new XMLHttpRequest();

        xhr.onload = function () {
            const tmp = URL.createObjectURL(xhr.response);
            setVideo(tmp);
            setRevo(tmp)
            // var reader = new FileReader();
            // reader.onloadend = function () {
            //     setVideo(reader.result);
            // }
            // reader.readAsDataURL(xhr.response);
        };

        xhr.open('GET', `${BASE_URL}/match/${e}/video`);
        xhr.setRequestHeader('Authorization', `Bearer  ${localStorage.getItem('accessToken')}`)
        xhr.onreadystatechange = function () { // Call a function when the state changes.
            if (this.readyState !== XMLHttpRequest.DONE || this.status !== 200) {
                setVideo('');
            }
        }
        xhr.onerror = () => {
            refreshToken();
        }
        xhr.responseType = 'blob';
        xhr.send();
    }
    useEffect(() => {
        setCollapseId(-1)
        return () => {

        }
    }, [reports])
    useEffect(() => {
        getAds()


        return () => {

        }
    }, [selectedCompany, selectedBrand])

    const getFeeClassOption = () => {
        if (selectedEditAd !== null) {
            let min = selectedEditAd.channel.fee_class_prefix_id * 100 + 1
            let arr = []
            for (let index = min; index < min + 35; index++) {
                arr.push(<option>{index}</option>)
            }

            return arr
        }
    }
    
    const resetFields = () => {
        setSelectedCompany([])
        setSelectedAd([])
        setSelectedChannel([])
        setAdType([])
        setMediaType([0])
        setCodeFilter([])
        setTagFilter([])
        setSelectedDayRange({
            from: null,
            to: null
        });
    }
    const updateMatch = async (id) => {
        if (position === '' || position === null) {
            toast.error(t('Position Not Selected'))
            return;
        }
        if (program === '' || program === null) {
            toast.error(t('Program Title Not Selected'))
            return;
        }
        if (parseInt(feeClass1) < 101) {
            toast.error(t('Group Value Is Invalid'))
            return;
        }
        let data = new FormData();
        data.append('fee_class', feeClass1 + '+' + feeClass2 + '%');
        data.append('position', position);
        data.append('program', program);
        data.append('apply_to_box', applyToBox ? 1 : 0);

        const { data: response, error } = await api.editMatch(data, id);
        if (response) {
            setSelectedEditAd(null)
            toast.success(t('The operation was successful'))
            getReports()
        }
    }
    const exportExcel = async () => {
        if (selectedDayRange.from === null && selectedDayRange.to === null) {
            toast.error(t('It is necessary to enter the date range to get the output'))
            return;
        }
        toast.success('در حال تولید گزارش هستیم، اندکی صبر کنید')
        let params = await getParams();

        const { data: response, error } = await api.exportMatch(params);
        if (response) {
            var data = new Blob([response], { type: response.type });
            var csvURL = window.URL.createObjectURL(data);
            var a = document.createElement('a')
            if (response.type.indexOf('csv') > -1)
                a.setAttribute('download', 'export-monitoring.csv')
            else
                a.setAttribute('download', 'export-monitoring.xlsx')
            a.setAttribute('href', csvURL)
            a.click()
        }
    }

    const exportBox = async () => {
        if (selectedDayRange.from === null && selectedDayRange.to === null) {
            toast.error(t('It is necessary to enter the date range to get the output'))
            return;
        }

        var date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        if (convertTimestap(selectedDayRange.to, { hour: endTime.split(":")[0], minute: endTime.split(":")[1], second: 59 }) > convertTimestap(convertToHijri(date.getTime()))) {
            toast.error('برای خروجی باکس می‌بایست تاریخ پایان درخواست قبل از امروز باشد')
            return;
        }

        let params = await getParams();

        const { data: response, error } = await api.getBoxExport(params)

        if (response) {
            toast.success('درخواست شما با موفقیت ارسال شد', {
                closeButton: true
            })
        }
        else {
            if ((error.message.indexOf(503) > -1)) {
                toast.error('تعداد درخواستهای در حال پردازش بیش از حد مجاز است', {
                    closeButton: false
                });
            }
            else if (error.message.indexOf(404) > -1) {
                toast.error("خروجی فیلتر هیچ نتیجه ای ندارد", {
                    closeButton: false
                });
            }
            else {
                toast.error(t('The operation was unsuccessful'), {
                    closeButton: false
                });
            }
        }
    }

    return (
        <MDBContainer fluid className={Language}>
            <MDBRow>
                <MDBCol md='12' className='mb-2' >
                    <MDBRow>
                        <MDBCol md='6'>
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('All reports')}</h5>
                        </MDBCol>

                        <MDBCol md='6' className='d-flex justify-content-end align-items-center'>
                            <span className='mx-2 text-nowrap' style={{ cursor: 'pointer', textDecoration: 'underline', lineHeight: '.5rem' }} onClick={() => setShowFilterModal(true)}>{t('Advanced Search')}</span>
                            <span className={'mx-2 do-search-report text-white d-flex align-items-center ' + (isRtl() ? 'flex-row-reverse' : '')}
                                onClick={() => exportExcel()}
                                style={{ cursor: 'pointer', width: '10rem', height: '60px' }}>
                                {t('Export excel')}
                                <MDBIcon icon='file-excel'
                                    style={isRtl() ?
                                        { left: '12rem', position: 'absolute', width: '16px', height: '16px' } :
                                        { right: '12rem', position: 'absolute', width: '16px', height: '16px' }} />
                            </span>
                            <span className={'do-search-report text-white d-flex align-items-center ' + (isRtl() ? 'flex-row-reverse' : '')}
                                onClick={() => exportBox()}
                                style={{ cursor: 'pointer', width: '10rem', height: '60px' }}>
                                {t('Export box')}
                                <MDBIcon icon='tv'
                                    style={isRtl() ?
                                        { left: '2rem', position: 'absolute', width: '16px', height: '16px' } :
                                        { right: '2rem', position: 'absolute', width: '16px', height: '16px' }} />
                            </span>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md='12' className='filter-row'>
                    <form >
                        <MDBRow className='m-2 justify-content-between w-100'>
                            <MDBCol size='3' md='3' >
                                <DropDown items={companies.sort((x, y) => x.name > y.name)} placeholder={t('All companies')}
                                    selectedValues={selectedCompany}
                                    onChange={e => {
                                        setCurrentPage(1); setSelectedCompany(e)
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='3' md='3'>
                                <DropDown
                                    items={brands.filter(x => selectedCompany.length === 0 || selectedCompany.indexOf(x.company.id) > -1).sort((x, y) => x.name > y.name)}
                                    placeholder={t('All brands')}
                                    selectedValues={selectedBrand}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setSelectedBrand(e);
                                    }} >
                                </DropDown>
                            </MDBCol>
                            <MDBCol size='3' md='3'>
                                <DropDown
                                    items={channels}
                                    placeholder={t('All channels')}
                                    selectedValues={selectedChannel}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setSelectedChannel(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='3' md='3'>
                                <button className='do-search-report justify-content-between w-100'
                                    style={!isRtl() ? {
                                        display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative'
                                    } : {
                                        display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                        marginLeft: '1%'
                                    }}
                                    type='button' onClick={(e) => { doSearch() }}>
                                    <span style={isRtl() ?
                                        {
                                            float: 'left',
                                            textAlign: 'right', alignItems: 'center',
                                        } :
                                        {
                                            float: 'right', textAlign: 'left', alignItems: 'center'
                                        }}>

                                        {t('Apply filter')}
                                    </span>
                                    {isRtl() ?
                                        <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                            style={{ width: '16px', height: '16px' }} />
                                        :
                                        <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                            style={{ width: '16px', height: '16px' }} />
                                    }
                                </button>

                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCol>
            </MDBRow>
            <MDBRow className='mt-5 mb-3'>
                <MDBCol md='12'>
                    <MDBTable className='report-table' responsive>
                        <MDBTableHead className='header'>
                            <tr style={{ fontSize: '14px', color: '#8392A5', textAlign: 'center' }}>
                                <th className='table-cell col1'>{t('#')}</th>
                                <th className='table-cell col2'>{t('Advertise name')}</th>
                                <th className='table-cell col3'>{t('Company name')}<br className='my-2' />{t('Brand name')}</th>
                                <th className='table-cell col4'>{t('Broadcast date')}</th>
                                <th className='table-cell col5'>{t('Channel')}</th>
                                <th className='table-cell col6'>{t('Box')}</th>
                                <th className='table-cell col7'>{t('Type')}</th>
                                <th className='table-cell col8'>{t('View')}</th>
                                {user?.modules?.find(x => x === 'shahed') && <th className='table-cell col9'>{t('Download')}</th>}
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody className='body' >
                            {
                                reports.map((item, i) => {
                                    return (
                                        <>
                                            <tr className={'table-row ' + (collapseId === i ? 'active' : 'hide')} key={i}
                                                style={{ fontFamily: '16px', color: '#3C4858', textAlign: 'center', cursor: 'pointer' }}
                                                onClick={() => collapseId === i ? setCollapseId(-1) : setCollapseId(i)}>
                                                <td className='table-cell col1'>{i + ((currentPage - 1) * 10) + 1}</td>
                                                <td className='table-cell col2' style={{ maxWidth: '2rem', whiteSpace: 'normal', lineHeight: '15pt' }}>
                                                    {item.ad?.name}</td>
                                                <td className='table-cell col3'>
                                                    {
                                                        item.ad?.brand?.company?.name === item.ad?.brand?.name ?
                                                            item.ad?.brand?.name :
                                                            <>
                                                                {item.ad?.brand?.company?.name}
                                                                <br className='my-2' />
                                                                {item.ad?.brand?.name}
                                                            </>
                                                    }
                                                </td>
                                                <td className='table-cell col4 dir-ltr'>{convertDateStr(item.start_timestamp, true)}
                                                    <br className='my-2' />
                                                    {convertDateStr(item.end_timestamp, true)}
                                                </td>
                                                <td className='table-cell col5'>{item.channel?.name}</td>
                                                <td className='table-cell col6'>
                                                    {item.ad.type !== 1 && item.ad.type !== 4 ?
                                                        <>
                                                            {item.box}
                                                            &nbsp;&nbsp;
                                                            <MDBIcon icon='play' style={{ color: 'blue', cursor: 'pointer', }} onClick={(e) => {
                                                                e.stopPropagation();
                                                                setBoxUrl(`${BASE_URL}/match_${item.id}_box.m3u8`);
                                                                setShowBoxModal(true);
                                                            }} />
                                                        </>
                                                        : '--'}
                                                </td>
                                                <td className='table-cell col7'>{getTypeTitle(item.ad?.type)}</td>
                                                <td className='table-cell col8'>
                                                    <button type='button' onClick={(e) => {
                                                        e.stopPropagation();
                                                        setVideoUrl(item.id);
                                                        setShowVideoModal(true)
                                                    }}
                                                        style={{ borderRadius: '5px', width: '90px', height: '43px', textAlign: 'center', backgroundColor: '#CCC', color: 'white', border: 'none' }}
                                                    >
                                                        <MDBIcon icon='play' style={{ color: 'white' }} />
                                                    </button>
                                                </td>
                                                {user?.modules?.find(x => x === 'shahed') &&
                                                    <td className='table-cell col9'>
                                                        <button onClick={e => {
                                                            e.stopPropagation();
                                                            downloadVideo(item.id,
                                                                item.ad?.brand?.name,
                                                                item.ad?.name,
                                                                convertDateStr(item.start_timestamp, true),
                                                                item.channel?.name);
                                                        }}
                                                            style={{ borderRadius: '5px', width: '90px', height: '43px', textAlign: 'center', backgroundColor: '#CCC', color: 'white', border: 'none' }}>
                                                            {t('Download')}
                                                        </button>
                                                    </td>}
                                            </tr>
                                            <tr className={'collapse-row ' + (collapseId === i ? 'show active' : 'hide')} >
                                                <td></td>
                                                <td colSpan='8'>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <label style={{ fontFamily: 'DiodrumArabicBold' }}>{t('Category')}:&nbsp;</label>
                                                            </td>
                                                            <td>
                                                                {item.ad.tags.filter(x => x.type === 0).map(x => { return <span>{x.name}</span> })}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label style={{ fontFamily: 'DiodrumArabicBold' }}>{t('Subcategory')}:&nbsp;</label>
                                                            </td>
                                                            <td>
                                                                {item.ad.tags.filter(x => x.type === 3).map(x => { return <span>{x.name}</span> })}
                                                            </td>
                                                        </tr>
                                                        {user?.modules?.find(x => x === 'trend') &&
                                                            <tr>
                                                                <td>
                                                                    <label style={{ fontFamily: 'DiodrumArabicBold' }}>{t('Mega trend')}:&nbsp;</label>
                                                                </td>
                                                                <td>
                                                                    {item.ad.tags.filter(x => x.type === 1).map(x => { return <span>{x.name}</span> })}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {user?.modules?.find(x => x === 'trend') &&
                                                            <tr>
                                                                <td>
                                                                    <label style={{ fontFamily: 'DiodrumArabicBold' }}>{t('Sub trend')}:&nbsp;</label>
                                                                </td>
                                                                <td>
                                                                    {item.ad.tags.filter(x => x.type === 2).map(x => { return <span>{x.name}</span> })}
                                                                </td>
                                                            </tr>
                                                        }
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr className={'collapse-row ' + (collapseId === i ? 'show active' : 'hide')} >
                                                <td></td>
                                                <td colSpan='8'>
                                                    <table className='w-100'>
                                                        {selectedEditAd?.id === item.id ?
                                                            <tr className='row'>
                                                                <td className='col-4 d-flex justify-content-center flex-row align-items-center' style={{ direction: 'ltr' }}>

                                                                    <Fee value={feeClass1}
                                                                        onChange={e => setFeeClass1(e)}
                                                                        channel={selectedEditAd.channel}
                                                                    />
                                                                    <label className='m-0'>طبقه</label>

                                                                </td>
                                                                <td className='col-3 d-flex justify-content-center flex-row align-items-center'>
                                                                    <label>{t('Position')}</label>
                                                                    <select value={position} onChange={e => setPosition(e.target.value)}>
                                                                        <option value='0'>{t('Before')}</option>
                                                                        <option value='1'>{t('Between')}</option>
                                                                        <option value='2'>{t('After')}</option>
                                                                    </select>
                                                                </td>
                                                                <td className='col-4 d-flex justify-content-center flex-row align-items-center'>
                                                                    <label className='mx-1'>{t('Program')}</label>
                                                                    <input type='text' value={program} onChange={e => setProgram(e.target.value)} style={{ width: '70%', height: '3rem' }} />
                                                                </td>
                                                                <td className='col-6'>
                                                                    <ToggleSwitch id='applyToBox' Name='applyToBox' dir={isRtl() ? 'rtl' : 'ltr'}
                                                                        onChange={e => setApplyToBox(e)}
                                                                        active={applyToBox} label={'اعمال به کل جعبه؟'}
                                                                    />
                                                                </td>
                                                                <td className='col-1 d-flex justify-content-center flex-row align-items-center'>
                                                                    <MDBIcon
                                                                        onClick={() => { updateMatch(item.id) }}
                                                                        icon='check-square' size='2x' style={{ color: '#2A57E0', cursor: 'pointer' }} />
                                                                    <MDBIcon
                                                                        onClick={() => { setSelectedEditAd(null) }}
                                                                        icon='window-close' size='2x' style={{ marginRight: '.1rem', color: '#2A57E0', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                            :
                                                            <tr className='row'>
                                                                <td className='col-1'>{t('Fee class')}</td>
                                                                <td className='col-1'>{item.fee_class}</td>
                                                                <td className='col-1'>{t('Position')}</td>
                                                                <td className='col-2'>{
                                                                    item.position === 0 ? t('Before') :
                                                                        item.position === 1 ? t('Between') :
                                                                            item.position === 2 ? t('After') : ''}</td>
                                                                <td className='col-2'>{t('Program')}</td>
                                                                <td className='col-2'>{item.program}</td>
                                                                <td className='col-1'>{t('Cost')}</td>
                                                                <td className='col-1'>{item.cost}</td>
                                                                <td className='col-1'>
                                                                    <MDBIcon
                                                                        onClick={() => {
                                                                            setSelectedEditAd(item)
                                                                            let fee = item.fee_class?.replace('%', '').split('+')
                                                                            if (fee) {
                                                                                setFeeClass1(fee[0])
                                                                                setFeeClass2(fee[1])
                                                                            }
                                                                            setProgram(item.program)
                                                                            setApplyToBox(true)
                                                                            setPosition(item.position)
                                                                        }}
                                                                        icon='pen-square' size='2x' style={{ color: '#2A57E0', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        }
                                                    </table>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>

            </MDBRow>
            <MDBRow>
                <MDBCol center='true' style={{ direction: isRtl() ? 'rtl' : 'ltr' }}>
                    <div className='text-center d-flex flex-row justify-content-center' >
                        <label
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                width: '60px', height: '60px'
                            }}
                            onClick={() => {
                                if (currentPage > 1)
                                    setCurrentPage(currentPage - 1)
                            }}>
                            {isRtl() ? <MDBIcon icon='angle-right' /> : <MDBIcon icon='angle-left' />}
                        </label>
                        <input type='number'
                            onFocus={(e) => { e.target.select() }}
                            style={{
                                height: '60px', width: '5rem', borderRadius: '1rem',
                                display: 'inline', WebkitAppearance: 'none',
                                margin: '0 .3rem', MozAppearance: 'textfield', textAlign: 'center'
                            }}
                            min='1' max={pageCount}
                            value={currentPage} onChange={e => {
                                setCurrentPage(parseInt(e.target.value))
                                if (e.target.value > pageCount && e.target.value > 0)
                                    setCurrentPage(pageCount)
                                else if (e.target.value < 1)
                                    setCurrentPage(1)
                            }} />
                        <label
                            style={isRtl() ?
                                { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'rtl', marginRight: '.2rem', marginLeft: '.2rem' } :
                                { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'ltr', marginRight: '.2rem', marginLeft: '.2rem' }}>
                            {t('from') + ' ' + pageCount}
                        </label>
                        <label
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                width: '60px', height: '60px'
                            }}
                            onClick={() => {
                                if (currentPage < pageCount)
                                    setCurrentPage(currentPage + 1)
                            }}
                        >
                                                        {isRtl() ? <MDBIcon icon='angle-left' /> : <MDBIcon icon='angle-right' />}
                        </label>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBModal isOpen={showBoxModal} toggle={() => setShowBoxModal(!showBoxModal)} size='lg'>
                <MDBModalHeader toggle={() => setShowBoxModal(!showBoxModal)}></MDBModalHeader>
                <MDBModalBody>
                    {boxUrl === '' ?
                        <div style={{ width: '100%' }}>
                            <MDBIcon icon="spinner" pulse size="3x" fixed
                                style={{ display: 'block', margin: '0 auto', lineHeight: '1.25em', textAlign: 'center' }} /></div> :
                        <ClapprPlayer style={{ display: 'block', margin: '0 auto' }}
                            id={"video" + Math.floor(Math.random() * 100)}
                            key={1}
                            autoPlay={true}
                            source={boxUrl} />}

                </MDBModalBody>
            </MDBModal>
            <MDBModal isOpen={showVideoModal} toggle={() => setShowVideoModal(!showVideoModal)} size='lg'>
                <MDBModalHeader toggle={() => setShowVideoModal(!showVideoModal)}></MDBModalHeader>
                <MDBModalBody>
                    {video === '' ?
                        <div style={{ width: '100%' }}>
                            <MDBIcon icon="spinner" pulse size="3x" fixed
                                style={{ display: 'block', margin: '0 auto', lineHeight: '1.25em', textAlign: 'center' }} /></div> :
                        <video autoPlay style={{ width: '100%' }} controls src={video}></video>}
                </MDBModalBody>
            </MDBModal>
            <MDBModal size='lg' isOpen={showFilterModal} toggle={() => setShowFilterModal(!showFilterModal)} >
                <MDBModalHeader toggle={() => setShowFilterModal(!showFilterModal)}></MDBModalHeader>
                <MDBModalBody>
                    <form >
                        <MDBRow className='m-2 justify-content-between'>
                            <MDBCol size='6' md='6' className='mt-2' >
                                <DropDown items={companies.sort((x, y) => x.name > y.name)} placeholder={t('All companies')}
                                    selectedValues={selectedCompany}
                                    onChange={e => {
                                        setCurrentPage(1); setSelectedCompany(e)
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2' >
                                <DropDown items={brands.filter(x => selectedCompany.length === 0 || selectedCompany.indexOf(x.company.id) > -1).sort((x, y) => x.name > y.name)} placeholder={t('All brands')}
                                    selectedValues={selectedBrand}
                                    onChange={e => {
                                        setCurrentPage(1); setSelectedBrand(e)
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={ads.sort((x, y) => x.name > y.name)}
                                    placeholder={t('Advertise name')}
                                    selectedValues={selectedAd}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setSelectedAd(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={channels}
                                    placeholder={t('All channels')}
                                    selectedValues={selectedChannel}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setSelectedChannel(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={
                                        categories.filter(x => x.type === 0)
                                            .sort((x, y) => x.name > y.name)}
                                    placeholder={t('All categories')}
                                    selectedValues={tagFilter}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setTagFilter(e);
                                    }} ></DropDown>

                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={
                                        categories.filter(x => x.type === 3)
                                            .sort((x, y) => x.name > y.name)}
                                    placeholder={t('All subcategories')}
                                    selectedValues={subcategory}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setSubcategory(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <ReactTagInput
                                    placeholder={t('Enter Ads Code then Press Enter')}
                                    tags={codeFilter}
                                    onChange={(newTags) => setCodeFilter(newTags)}
                                />
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={[{ id: 0, name: t('TV') }, { id: 1, name: t('Radio') }]}
                                    placeholder={t('All media')}
                                    selectedValues={mediaType}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setMediaType(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <div className="duration-picker"
                                    style={{ width: '100%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <DatePicker
                                        locale={getTimeMode()}
                                        value={selectedDayRange}
                                        onChange={setSelectedDayRange}
                                        shouldHighlightWeekends
                                        style={isRtl() ? { float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem' } :
                                            { float: 'right', textAlign: 'right', alignItems: 'center', position: 'absolute', right: '1rem' }} />

                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setSelectedDayRange({ from: null, to: null })} />
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='d-flex justify-content-around mt-2'>
                                <div className="duration-picker"
                                    style={{ width: '48%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setStartTime('00:00')} />
                                    <input type='text' style={{ height: '60px', lineHeight: '60px' }}
                                        value={startTime}
                                        placeholder={t('Start Time')}
                                        onChange={e => {
                                            setStartTime(e.target.value)
                                            if (!isNaN(e.target.value) && e.target.value.length === 2) {
                                                setStartTime(e.target.value + ':')
                                            }

                                        }}
                                        onBlur={e => {
                                            if (!e.target.value.match('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')) {
                                                setStartTime('00:00')
                                            }
                                            else {

                                            }
                                        }} />
                                </div>
                                <div className="duration-picker"
                                    style={{ width: '48%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setEndTime('23:59')} />
                                    <input type='text' style={{ height: '60px', lineHeight: '60px' }}
                                        placeholder={t('End Time')}
                                        value={endTime}
                                        onChange={e => {
                                            setEndTime(e.target.value)
                                            if (!isNaN(e.target.value) && e.target.value.length === 2) {
                                                setEndTime(e.target.value + ':')
                                            }

                                        }}
                                        onBlur={e => {
                                            if (!e.target.value.match('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')) {
                                                setEndTime('23:59')
                                            }
                                            else {

                                            }
                                        }} />
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={[
                                        { id: 0, name: t('Teaser') },
                                        { id: 2, name: t('Advertisement logo') },
                                        { id: 1, name: t('Subtitle') },
                                        { id: 3, name: t('Ad reporting') },
                                        { id: 4, name: t('Program presentation') }]}
                                    placeholder={t('All type')}
                                    selectedValues={adType}
                                    onChange={e => {
                                        setCurrentPage(1);
                                        setAdType(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <div className='drop-down-container' >
                                    <button className='do-search-report justify-content-between'
                                        style={!isRtl() ? {
                                            display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative'
                                        } : {
                                            display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                            marginLeft: '1%'
                                        }}
                                        type='button' onClick={(e) => { doSearch() }}>
                                        <span style={isRtl() ?
                                            {
                                                float: 'left',
                                                textAlign: 'right', alignItems: 'center',
                                            } :
                                            {
                                                float: 'right', textAlign: 'left', alignItems: 'center'
                                            }}>

                                            {t('Apply filter')}
                                        </span>
                                        {isRtl() ?
                                            <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                            :
                                            <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                        }
                                    </button>
                                    <button
                                        className='do-search-report justify-content-between'
                                        style={!isRtl() ? {
                                            display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative', marginRight: '1%'
                                        } : {
                                            display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative'
                                        }}
                                        type='button'
                                        onClick={() => resetFields()}>
                                        {t('Clear filters')}
                                        {isRtl() ?
                                            <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                            :
                                            <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                        }
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer >
    )
}
