import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropDown from "../../assets/DropDown"
import { toast, MDBBadge } from 'mdbreact';
import * as api from '../../api';

export default function Special(props) {
    const { t, i18n } = useTranslation(['home']); // "home" is namespace
    const [fileInput, setFileInput] = useState(null)
    const [channelList, setChannelList] = useState([])
    const [selectedChannel, setSelectedChannel] = useState([])
    const [specials, setSpecials] = useState([])
    const [tags, setTags] = useState([])

    const [specialMonth, setSpecialMonth] = useState('')
    const [specialYear, setSpecialYear] = useState('')
    const [specialTags, setSpecialTags] = useState([])
    const [specialChannel, setSpecialChannel] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)

    useEffect(() => {
        getSpecials();
        getTags();
        api.getChannels()
            .then(({ data, error }) => {
                if (data) {
                    setChannelList(data.sort((x, y) => x.id > y.id))
                }
            })
        return () => {

        }
    }, [])

    const getSpecials = async () => {
        const { data, error } = await api.getSpecials()
        if (data) {
            setSpecials(data)
        }

    }
    const getTags = async () => {

        const { data, error } = await api.getTags()
        if (data) {
            setTags(data.filter(x => x.type === 0))
        }

    }
    const getChannelName = id => {
        var item = channelList.find(x => x.id === id)
        return item ? item.name : ''
    }

    const addSpecial = async () => {
        if (specialMonth === '' || specialYear === '' || specialTags.length === 0 || specialChannel.length === 0) {
            toast.error('مقادیر ورودی را کامل کنید')
            return;
        }

        const { data, error } = await api.addSpecial({ specialYear, specialMonth, specialTags, specialChannel })
        if (data) {
            getSpecials()
            setSpecialMonth('')
            setSpecialYear('')
            setSpecialTags([])
            setSpecialChannel([])
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response?.data?.message?.indexOf('duplicate') > -1) {
                toast.error(t('The company name is duplicate'), {
                    closeButton: false
                });
                return;
            }
        }




    }
    const deleteSpecial = async (dt, tg, ch) => {
        var record = specials.filter(x => x.date === dt)
        var theTags = record[0].tags.filter(x => x.id === tg)
        var theChannels = theTags[0].channel_ids.filter(x => x !== ch)
        const { data, error } = await api.deleteSpecial({ date: dt, tag_ids: tg, channel_ids: theChannels })

        if (data) {
            getSpecials()
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response?.data?.message?.indexOf('duplicate') > -1) {
                toast.error(t('The company name is duplicate'), {
                    closeButton: false
                });
                return;
            }
        }
    }

    const getTagTitle = (id) => {
        let a = tags.filter(x => x.id === id);
        if (a.length > 0)
            return a[0].name
        return id
    }
    return <>
        <div className='d-flex flex-row justify-content-between w-100 font-diodrum'>
            <div>
                <input placeholder='ماه' type='text' style={{ width: '10rem', height: '58px' }}
                    value={specialMonth}
                    onChange={e => {
                        if (isNaN(e.target.value) ||
                            parseInt(e.target.value) > 12 ||
                            parseInt(e.target.value) < 1) setSpecialMonth('')
                        else setSpecialMonth(e.target.value)
                    }} />
            </div>
            <div>
                <input placeholder='سال' type='text' style={{ width: '10rem', height: '58px' }}
                    value={specialYear}
                    onChange={e => {
                        if (isNaN(e.target.value)) setSpecialYear('')
                        else setSpecialYear(e.target.value)
                    }}
                    onBlur={e => {
                        if (parseInt(e.target.value) > 1500 ||
                            parseInt(e.target.value) < 1390)
                            setSpecialYear('')
                    }} />
            </div>
            <div>
                <DropDown
                    style={{ width: '10rem' }}
                    items={tags}
                    multiple={true}
                    placeholder={'بدون انتخاب'}
                    selectedValues={specialTags}
                    onChange={e => {
                        setSpecialTags(e)
                    }} ></DropDown>
            </div>
            <div>
                <DropDown
                    style={{ width: '10rem' }}
                    items={channelList}
                    placeholder={'بدون انتخاب'}
                    selectedValues={specialChannel}
                    multiple={true}
                    onChange={e => {
                        setSpecialChannel(e)
                    }} ></DropDown>
            </div>
            <div>
                <button className='do-search-report w-100 font-diodrum' onClick={e => { e.preventDefault(); addSpecial() }}>افزودن</button>
            </div>
        </div>
        <hr />
        <div className='container-fluid font-diodrum'>
            <div className="row">
                <div className="col-4">
                    <table className='table table-bordered'>
                        <thead>
                            <tr><th>ماه/سال</th></tr>
                        </thead>
                        <tbody>
                            {specials.sort((a, b) => a.date > b.date).map((item, i) => {
                                return <tr key={i} onClick={() => setSelectedDate(item.date)} className={selectedDate === item.date ? 'card-video table-secondary' : 'card-video'}>
                                    <td>{item.date}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-8">
                    <table className="table table-bordered">
                        <tr>
                            <th>صنف</th>
                            <th>شبکه</th>
                        </tr>
                        {specials.filter(x => x.date === selectedDate)[0]?.tags?.map((item, i) => {
                            return <tr>
                                <td>{getTagTitle(item.id)}</td>
                                <td>
                                    {item.channel_ids.map(x => <MDBBadge size='lg' className='mx-2'>
                                        <button className='btn btn-sm btn-danger btn-floating my-0 mr-0' onClick={e => deleteSpecial(selectedDate, item.id, x)}>
                                            <i className='fa fa-times' ></i>
                                        </button>
                                        {getChannelName(x)}</MDBBadge>)}
                                </td>
                            </tr>
                        })}
                    </table>
                </div>

            </div>
        </div>
    </>
}