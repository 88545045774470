import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { convertToHijri, getRandomColor, getTimeMode, isRtl, Language } from '../../config';
import { MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader, MDBRow, toast } from 'mdbreact';
import DropDown from '../../assets/DropDown';
import DatePicker from 'react-modern-calendar-datepicker';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import ReactTagInput from '@pathofdev/react-tag-input';
import "@pathofdev/react-tag-input/build/index.css";
import * as api from '../../api';
import { useReactToPrint } from 'react-to-print';

export default function Chart(props) {

    const [totalSum, setTotalSum] = useState(0)
    const { t } = useTranslation(['home']); // "home" is namespace
    const [chartType, setChartType] = useState('Pie')
    const [series, setSeries] = useState([])
    const [data, setData] = useState(null)
    const [customData, setCustomData] = useState([])
    const [showFilterModal, setShowFilterModal] = useState(false)

    const [companies, setCompanies] = useState([])
    const [ads, setAds] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([])
    const [selectedBrand, setSelectedBrand] = useState([])
    const [brands, setBrands] = useState([])
    const [channels, setChannels] = useState([])
    const [tagFilter, setTagFilter] = useState('')
    const [codeFilter, setCodeFilter] = useState([])
    const [categories, setCategories] = useState([])
    const [subcategory, setSubcategory] = useState([])
    const [groupBy, setGroupBy] = useState('company')
    const [aggregateBy, setAggregateBy] = useState('count')
    const [selectedAd, setSelectedAd] = useState([])
    const [selectedChannel, setSelectedChannel] = useState([])
    var fromTmp = new Date();
    fromTmp = new Date(fromTmp.setMonth(fromTmp.getMonth() - 1));
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: convertToHijri(fromTmp),
        to: convertToHijri()
    });
    const [startTime, setStartTime] = useState('00:00')
    const [endTime, setEndTime] = useState('23:59')
    const [adType, setAdType] = useState([])
    const [mediaType, setMediaType] = useState([])
    const chartRef = useRef(null);

    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'bar',
            fontFamily: 'DiodrumArabic',
            selection: {
                enabled: true
            },
            toolbar: {
                show: false
            }
        },
        colors: [],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: [],
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            labels: {
                show: true,
            },
        },
    })



    useEffect(() => {
        if (data === null)
            return;
        if (data.labels.length === 0 || data.series.length === 0) {
            toast.error('اطلاعاتی وجود ندارد')
            return
        }
        let tmpArr = data.labels.map((item, i) => ({ value: data.series[i], title: item, color: getRandomColor() }))
        const ts = data.series.reduce((a, b) => a + b)
        setTotalSum(ts)
        setCustomData(tmpArr)
        let tmp = options;
        tmp.labels = tmpArr.map(x => { return x.title });
        if (chartType === 'Pie') {
            tmp.chart.type = 'pie'
            setSeries(tmpArr.map(x => { return x.value }))
            tmp.colors = tmpArr.map(x => x.color)
        }
        else {
            tmp.chart.type = 'bar';
            tmp.colors = tmpArr.filter((x, i) => i < 10).map(x => x.color)
            setSeries([{ name: ' ', data: tmpArr.filter((x, i) => i < 10).map(x => { return x.value }) }])

            tmp.xaxis = {
                categories: tmpArr.filter((x, i) => i < 10).map(x => x.title),
                labels: {
                    style: {
                        colors: tmpArr.map(x => x.color),
                        fontSize: '12px'
                    }
                }
            }
            tmp.yaxis = {}
            tmp.yaxis.max = Math.max(...tmpArr.filter((x, i) => i < 10).map(x => { return parseInt(x.value) }))
            tmp.yaxis.labels = {}
            tmp.yaxis.labels.formatter = (value) => { return (Math.round(value * 100 / ts)).toLocaleString() + '%' }

        }

        setOptions({ ...options, tmp })

        return () => {

        }
    }, [data])

    const getReports = async () => {
        const { data, error } = await api.getPieChart({
            startTime,
            endTime,
            selectedDayRange,
            codeFilter,
            tagFilter,
            subcategory,
            selectedCompany,
            selectedBrand,
            selectedAd,
            adType,
            selectedChannel,
            mediaType,
            groupBy,
            aggregateBy,
        })
        if (data) {
            setData(data)
            setShowFilterModal(false)
        }
    }

    const getAds = async () => {
        if (selectedCompany.length === 0) {
            setAds([])
            return
        }
        let params = new URLSearchParams()

        for (let i = 0; i < selectedCompany.length; i++) {
            params.append("company_id", selectedCompany[i]);
        }
        for (let i = 0; i < selectedBrand.length; i++) {
            params.append("brand_id", selectedBrand[i]);
        }

        const { data, error } = await api.getAds(params);
        if (data) {
            setAds(data)
        }

    }
    const getChannel = async () => {
        const { data, error } = await api.getChannels()
        if (data) {
            setChannels(data.sort((x, y) => x.id > y.id))
        }
    }

    const getCompanies = async () => {

        const { data, error } = await api.getCompanies();
        if (data) {
            setCompanies(data.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }
        else {

        }
        const { data: dataBrand, error: errorBrand } = await api.getBrands();
        if (dataBrand) {
            setBrands(dataBrand.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }
        else {

        }
    }
    useEffect(() => {
        getCompanies();
        getTags();
        getChannel();
    }, [])

    useEffect(() => {
        getReports()

    }, [chartType, groupBy, aggregateBy])


    const getTags = async () => {
        const { data, error } = await api.getTags();
        if (data) {
            setCategories(data)
        }
    }
    useEffect(() => {
        getAds()

        return () => {

        }
    }, [selectedCompany, selectedBrand])
    const resetFields = () => {
        setSelectedCompany([])
        setSelectedAd([])
        setSelectedChannel([])
        setAdType([])
        setMediaType([0])
        setCodeFilter([])
        setTagFilter([])
        setSelectedDayRange({
            from: null,
            to: null
        });
    }
    const handlePrint = useReactToPrint({
        content: () => chartRef.current,
    });
    return (
        <div className={Language + ' main-container'}>
            <div className='header-container container-fluid'>
                <MDBRow>
                    <MDBCol md='2'>
                        <h5>{t('Chart')}</h5>
                    </MDBCol>
                    <MDBCol md='3' style={{ direction: isRtl() ? "ltr" : "rtl" }}>
                        <span className='mx-2 d-flex align-items-center text-nowrap'
                            style={{
                                cursor: 'pointer', textDecoration: 'underline', lineHeight: '.5rem',
                                fontFamily: 'DiodrumArabic', height: '60px', margin: '0 1rem'
                            }}
                            onClick={() => setShowFilterModal(true)}>{t('Advanced Search')}</span>
                    </MDBCol>
                    <MDBCol md='2'>
                        <select onChange={e => setChartType(e.target.value)}>
                            <option value='Pie'>{t('Pie')}</option>
                            <option value='Bar'>{t('Bar')}</option>
                        </select>
                    </MDBCol>
                    <MDBCol md='2'>
                        <select onChange={e => setGroupBy(e.target.value)} value={groupBy}>
                            <option value='company'>{t('Company')}</option>
                            <option value='channel'>{t('Channel')}</option>
                            <option value='fee_class'>{t('Fee class')}</option>
                            <option value='session'>{t('Session')}</option>
                            <option value='category'>{t('Category')}</option>
                            <option value='subcategory'>{t('Subcategory')}</option>
                        </select>
                    </MDBCol>
                    <MDBCol md='2'>
                        <select onChange={e => setAggregateBy(e.target.value)} value={aggregateBy}>
                            <option value='count'>{t('Count')}</option>
                            <option value='cost'>{t('Cost')}</option>
                            <option value='duration'>{t('Duration')}</option>
                        </select>
                    </MDBCol>
                    <MDBCol md='1'>
                        <button className='theme-button w-100' onClick={handlePrint}>PDF</button>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className='filter-container'>
                <form >
                    <MDBRow className='m-2 justify-content-between w-100'>
                        <MDBCol size='3' md='3' >
                            <DropDown items={companies.sort((x, y) => x.name > y.name)} placeholder={t('All companies')}
                                selectedValues={selectedCompany}
                                onChange={e => {
                                    setSelectedCompany(e)
                                }} ></DropDown>
                        </MDBCol>
                        <MDBCol size='3' md='3'>
                            <DropDown items={brands.filter(x => selectedCompany.length === 0 || selectedCompany.indexOf(x.company.id) > -1).sort((x, y) => x.name > y.name)} placeholder={t('All brands')}
                                selectedValues={selectedBrand}
                                onChange={e => {
                                    setSelectedBrand(e)
                                }} ></DropDown>
                        </MDBCol>
                        <MDBCol size='3' md='3'>
                            <DropDown
                                items={channels}
                                placeholder={t('All channels')}
                                selectedValues={selectedChannel}
                                onChange={e => {
                                    setSelectedChannel(e);
                                }} ></DropDown>
                        </MDBCol>
                        <MDBCol size='3' md='3'>
                            <button className='do-search-report justify-content-between w-100'
                                style={!isRtl() ? {
                                    display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative'
                                } : {
                                    display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                    marginLeft: '1%'
                                }}
                                type='button' onClick={(e) => { getReports() }}>
                                <span style={isRtl() ?
                                    {
                                        float: 'left',
                                        textAlign: 'right', alignItems: 'center',
                                    } :
                                    {
                                        float: 'right', textAlign: 'left', alignItems: 'center'
                                    }}>

                                    {t('Apply filter')}
                                </span>
                                {isRtl() ?
                                    <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                        style={{ width: '16px', height: '16px' }} />
                                    :
                                    <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                        style={{ width: '16px', height: '16px' }} />
                                }
                            </button>

                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
            <div className='body-container d-flex justify-content-between align-items-center' style={{ height: '700px' }} ref={chartRef}>
                <div className='color-container h-100 overflow-auto p-2 d-block print-hidden'>
                    <ul style={{ padding: '1rem' }}>
                        {customData !== null && customData.map((x, i) => {
                            return <li key={i} >
                                <span><i style={{ backgroundColor: x.color }}></i>{x.title}</span>
                                <label>{Math.round(x.value / totalSum * 100)}%</label>
                            </li>
                        })}
                    </ul>
                </div>
                <div className='color-container h-100 overflow-auto p-2 d-none print-block'>
                    <ul style={{ padding: '1rem' }}>
                        {customData !== null && customData.filter((x, i) => i < 10).map((x, i) => {
                            return <li key={i} >
                                <span><i style={{ backgroundColor: x.color }}></i>{x.title}</span>
                                <label>{Math.round(x.value / totalSum * 100)}%</label>
                            </li>
                        })}
                    </ul>
                </div>
                <div className='chart-container'>
                    {
                        chartType === 'Pie' ?
                            <ReactApexChart options={options} series={series?.filter((x, i) => i < 10)} type="pie" width={'100%'} />
                            :
                            <ReactApexChart options={options} series={series?.filter((x, i) => i < 10)} type="bar" width={'100%'} />
                    }
                </div>
            </div>
            <MDBModal size='lg' isOpen={showFilterModal} toggle={() => setShowFilterModal(!showFilterModal)} >
                <MDBModalHeader toggle={() => setShowFilterModal(!showFilterModal)}></MDBModalHeader>
                <MDBModalBody>
                    <form >
                        <MDBRow className='m-2 justify-content-between'>
                            <MDBCol size='6' md='6' className='mt-2' >
                                <DropDown items={companies.sort((x, y) => x.name > y.name)} placeholder={t('All companies')}
                                    selectedValues={selectedCompany}
                                    onChange={e => {
                                        setSelectedCompany(e)
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2' >
                                <DropDown items={brands.filter(x => selectedCompany.length === 0 || selectedCompany.indexOf(x.company.id) > -1).sort((x, y) => x.name > y.name)} placeholder={t('All brands')}
                                    selectedValues={selectedBrand}
                                    onChange={e => {
                                        setSelectedBrand(e)
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={ads.sort((x, y) => x.name > y.name)}
                                    placeholder={t('Advertise name')}
                                    selectedValues={selectedAd}
                                    onChange={e => {
                                        setSelectedAd(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={channels}
                                    placeholder={t('All channels')}
                                    selectedValues={selectedChannel}
                                    onChange={e => {
                                        setSelectedChannel(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={
                                        categories.filter(x => x.type === 0)
                                            .sort((x, y) => x.name > y.name)}
                                    placeholder={t('All categories')}
                                    selectedValues={tagFilter}
                                    onChange={e => {
                                        setTagFilter(e);
                                    }} ></DropDown>

                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={
                                        categories.filter(x => x.type === 3)
                                            .sort((x, y) => x.name > y.name)}
                                    placeholder={t('All subcategories')}
                                    selectedValues={subcategory}
                                    onChange={e => {
                                        setSubcategory(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <ReactTagInput
                                    placeholder={t('Enter Ads Code then Press Enter')}
                                    tags={codeFilter}
                                    onChange={(newTags) => setCodeFilter(newTags)}
                                />
                            </MDBCol>

                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={[{ id: 0, name: t('TV') }, { id: 1, name: t('Radio') }]}
                                    placeholder={t('All media')}
                                    selectedValues={mediaType}
                                    onChange={e => {
                                        setMediaType(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <div className="duration-picker"
                                    style={{ width: '100%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <DatePicker
                                        locale={getTimeMode()}
                                        value={selectedDayRange}
                                        onChange={setSelectedDayRange}
                                        shouldHighlightWeekends
                                        style={isRtl() ? { float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem' } :
                                            { float: 'right', textAlign: 'right', alignItems: 'center', position: 'absolute', right: '1rem' }} />

                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setSelectedDayRange({ from: null, to: null })} />
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='d-flex justify-content-around mt-2'>
                                <div className="duration-picker"
                                    style={{ width: '48%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setStartTime('00:00')} />
                                    <input type='text' style={{ height: '60px', lineHeight: '60px' }}
                                        value={startTime}
                                        placeholder={t('Start Time')}
                                        onChange={e => {
                                            setStartTime(e.target.value)
                                            if (!isNaN(e.target.value) && e.target.value.length === 2) {
                                                setStartTime(e.target.value + ':')
                                            }

                                        }}
                                        onBlur={e => {
                                            if (!e.target.value.match('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')) {
                                                setStartTime('00:00')
                                            }
                                            else {

                                            }
                                        }} />
                                </div>
                                <div className="duration-picker"
                                    style={{ width: '48%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                                    <MDBIcon icon='times'
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            right: '2rem',
                                            color: 'black',
                                            background: '#F3F5F9',
                                            padding: '1rem',
                                            borderRadius: '.5rem',
                                            height: '60px',
                                        }}
                                        onClick={() => setEndTime('23:59')} />
                                    <input type='text' style={{ height: '60px', lineHeight: '60px' }}
                                        placeholder={t('End Time')}
                                        value={endTime}
                                        onChange={e => {
                                            setEndTime(e.target.value)
                                            if (!isNaN(e.target.value) && e.target.value.length === 2) {
                                                setEndTime(e.target.value + ':')
                                            }

                                        }}
                                        onBlur={e => {
                                            if (!e.target.value.match('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$')) {
                                                setEndTime('23:59')
                                            }
                                            else {

                                            }
                                        }} />
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <DropDown
                                    items={[
                                        { id: 0, name: t('Teaser') },
                                        { id: 2, name: t('Advertisement logo') },
                                        { id: 1, name: t('Subtitle') },
                                        { id: 3, name: t('Ad reporting') },
                                        { id: 4, name: t('Program presentation') }]}
                                    placeholder={t('All type')}
                                    selectedValues={adType}
                                    onChange={e => {
                                        setAdType(e);
                                    }} ></DropDown>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-2'>
                                <div className='drop-down-container' >
                                    <button className='do-search-report justify-content-between'
                                        style={!isRtl() ? {
                                            display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative', fontFamily: 'DiodrumArabic'
                                        } : {
                                            display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                            marginLeft: '1%', fontFamily: 'DiodrumArabic'
                                        }}
                                        type='button' onClick={(e) => { getReports() }}>
                                        <span style={isRtl() ?
                                            {
                                                float: 'left',
                                                textAlign: 'right', alignItems: 'center',
                                            } :
                                            {
                                                float: 'right', textAlign: 'left', alignItems: 'center'
                                            }}>

                                            {t('Apply filter')}
                                        </span>
                                        {isRtl() ?
                                            <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                            :
                                            <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                        }
                                    </button>
                                    <button
                                        className='do-search-report justify-content-between'
                                        style={!isRtl() ? {
                                            display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative', marginRight: '1%', fontFamily: 'DiodrumArabic'
                                        } : {
                                            display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative', fontFamily: 'DiodrumArabic'
                                        }}
                                        type='button'
                                        onClick={() => resetFields()}>
                                        {t('Clear filters')}
                                        {isRtl() ?
                                            <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                            :
                                            <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                                style={{ width: '16px', height: '16px' }} />
                                        }
                                    </button>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </div>
    )
}