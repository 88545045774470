import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { BASE_URL } from '../../config';
import ClapprPlayer from "../../assets/clapper";
import { useTranslation } from 'react-i18next';
import ChannelCard from '../../assets/ChannelCard';
import * as api from '../../api';

export default function Live() {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [channelList, setChannelList] = useState([])
    const [selectedChannel, setSelectedChannel] = useState(null)
    useEffect(() => {
        getChannel()

    }, [])
    const getChannel = async () => {
        const { data, error } = await api.getChannels();
        if (data) {
            setChannelList(data.filter(x => x.media_type === 0).sort((x, y) => x.id > y.id))
            setSelectedChannel(data.filter(x => x.media_type === 0).sort((x, y) => x.id > y.id)[0])
        }

    }

    return (
        <MDBContainer fluid>
            <MDBCol md='12' className='mb-2' >
                <MDBRow>
                    <MDBCol md='6'>
                        <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Live')}</h5>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBRow center>
                <div >
                    <ClapprPlayer
                        name={selectedChannel?.name}
                        id={"video" + Math.floor(Math.random() * 100)}
                        key={selectedChannel?.stream_id}
                        source={`${BASE_URL}/live_${selectedChannel?.stream_id}.m3u8`}
                        height='439px'
                        width='686px'
                    />
                </div>
            </MDBRow>
            <MDBRow>
                {
                    channelList.map((item, i) => {
                        return (
                            <MDBCol key={i} md='2' sm='6' xs='6' size='6' className='mt-3'>
                                <ChannelCard channelName={item.name}
                                    channel={item}
                                    active={selectedChannel === item}
                                    onClick={e => setSelectedChannel(item)}
                                />
                            </MDBCol>
                        )
                    })
                }
            </MDBRow>
        </MDBContainer>
    )
}