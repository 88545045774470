import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import {
    MDBContainer, MDBRow, MDBCol,
    toast
} from 'mdbreact';
import { Language } from '../../config'
import * as api from '../../api';

export default function Tag() {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [selected, setSelected] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([])
    const [addInput, setAddInput] = useState('')
    const [editInput, setEditInput] = useState('')
    const inputRef = useRef(null);
    const [addInputSub, setAddInputSub] = useState('')
    const [editInputSub, setEditInputSub] = useState('')
    const [searchCategory, setSearchCategory] = useState('')
    const [searchSubcategory, setSearchSubcategory] = useState('')

    useEffect(() => {
        getCategories();

    }, [])
    useEffect(() => {
        if (selected !== -1) {
            inputRef.current.focus();
        }
    }, [selected]);
    const getCategories = async () => {

        let params = new URLSearchParams();
        params.append("type", 0)

        const { data: response, error } = await api.getTags(params);

        if (response) {
            setCategories(response.filter(x => x.type === 0));
            setSubcategories(response.filter(x => x.type === 3));
        }
    }
    const addCategory = async (tid) => {
        if ((tid === 0 && addInput === '') || (tid === 3 && addInputSub === ''))
            return;
        var data = new FormData();
        if (tid === 0)
            data.append('name', addInput);
        else if (tid === 3)
            data.append('name', addInputSub);
        data.append('type', tid);

        const { data: response, error } = await api.addTag(data);
        if (response) {
            if (tid === 0)
                setAddInput('')
            else if (tid === 3)
                setAddInputSub('')
            getCategories();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response.data.message.indexOf('duplicate') > -1)
                toast.error(t('The title of the category is duplicate'), {
                    closeButton: false
                });
        }

    }
    const deleteCategory = async (id) => {

        const { data, error } = await api.deleteTag(id);
        if (data) {
            getCategories();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }

    }
    const editCategory = async (id, tid) => {
        var data = new FormData();
        if (tid === 0)
            data.append('name', editInput);
        else if (tid === 3)
            data.append('name', editInputSub);

        const { data: response, error } = await api.editTag(id, data);
        if (response) {
            setSelected(-1)
            getCategories();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response.data.message.indexOf('duplicate') > -1)
                toast.error(t('The title of the category is duplicate'), {
                    closeButton: false
                });
        }

    }
    return (
        <MDBContainer fluid className={Language}>
            <MDBRow>
                <MDBCol md="12" lg="6" className='mt-2'>
                    <MDBRow className='mt-5 mb-3'>
                        <MDBCol md='12'>
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Categories')}</h5>
                        </MDBCol>
                        <MDBCol md='12' style={{ alignItems: 'center' }}>
                            <form onSubmit={e => { e.preventDefault(); addCategory(0) }}>
                                <input value={addInput} type='text' style={{ display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0' }} onChange={e => setAddInput(e.target.value)} />
                                <img alt="img" src={require('../../assets/img/plus.png')}
                                    onClick={e => addCategory(0)}
                                    style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                            </form>
                        </MDBCol>
                        <MDBCol md='12 mt-3' style={{ alignItems: 'center' }}>
                            <input value={searchCategory} type='text' style={{ display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0' }} onChange={e => setSearchCategory(e.target.value)} />
                            <img alt="img" src={require('../../assets/img/search.png')}
                                style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        {categories.filter(x => x.name.indexOf(searchCategory) > -1).map((item, i) => {
                            return (
                                <>
                                    {selected === item.id ?
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                style={{
                                                    display: 'inline-flex', width: '50%', height: '3rem',
                                                    backgroundColor: '#FFF',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center',
                                                    borderRadius: '1rem',
                                                    marginBottom: '0',
                                                    padding: '1rem'
                                                }}
                                                defaultValue={item.name} ref={inputRef} value={editInput}
                                                onChange={e => setEditInput(e.target.value)} />
                                            <img alt="img" src={require('../../assets/img/confirm.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', width: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                                onClick={() => { editCategory(item.id, 0) }}
                                            />
                                            <img alt="img" src={require('../../assets/img/deny.png')}
                                                onClick={() => setSelected(-1)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                        :
                                        <MDBCol md='12' className='mt-2'>
                                            <label style={{
                                                display: 'inline-flex', width: '50%', height: '3rem',
                                                backgroundColor: '#F3F5F9',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                borderRadius: '1rem',
                                                marginBottom: '0',
                                                padding: '1rem'
                                            }} >{item.name}</label>
                                            <img alt="img" src={require('../../assets/img/edit.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                                onClick={() => { setEditInput(item.name); setSelected(item.id) }}
                                            />
                                            <img alt="img" src={require('../../assets/img/trash.png')}
                                                onClick={() => deleteCategory(item.id)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                    }
                                </>

                            )
                        })
                        }
                    </MDBRow>
                </MDBCol>
                <MDBCol md="12" lg="6" className='mt-2'>
                    <MDBRow className='mt-5 mb-3'>
                        <MDBCol md='12'>
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Subcategories')}</h5>
                        </MDBCol>
                        <MDBCol md='12' style={{ alignItems: 'center' }}>
                            <form onSubmit={e => { e.preventDefault(); addCategory(3) }}>
                                <input value={addInputSub} type='text' style={{ display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0' }} onChange={e => setAddInputSub(e.target.value)} />
                                <img alt="img" src={require('../../assets/img/plus.png')}
                                    onClick={e => addCategory(3)}
                                    style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                            </form>
                        </MDBCol>
                        <MDBCol md='12 mt-3' style={{ alignItems: 'center' }}>
                            <input value={searchSubcategory} type='text' style={{ display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0' }} onChange={e => setSearchSubcategory(e.target.value)} />
                            <img alt="img" src={require('../../assets/img/search.png')}
                                style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        {subcategories.filter(x => x.name.indexOf(searchSubcategory) > -1).map((item, i) => {
                            return (
                                <>
                                    {selected === item.id ?
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                style={{
                                                    display: 'inline-flex', width: '50%', height: '3rem',
                                                    backgroundColor: '#FFF',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center',
                                                    borderRadius: '1rem',
                                                    marginBottom: '0',
                                                    padding: '1rem'
                                                }}
                                                defaultValue={item.name} ref={inputRef} value={editInputSub}
                                                onChange={e => setEditInputSub(e.target.value)} />
                                            <img alt="img" src={require('../../assets/img/confirm.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', width: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                                onClick={() => { editCategory(item.id, 3) }}
                                            />
                                            <img alt="img" src={require('../../assets/img/deny.png')}
                                                onClick={() => setSelected(-1)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                        :
                                        <MDBCol md='12' className='mt-2'>
                                            <label style={{
                                                display: 'inline-flex', width: '50%', height: '3rem',
                                                backgroundColor: '#F3F5F9',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                borderRadius: '1rem',
                                                marginBottom: '0',
                                                padding: '1rem'
                                            }} >{item.name}</label>
                                            <img alt="img" src={require('../../assets/img/edit.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                                onClick={() => { setEditInputSub(item.name); setSelected(item.id) }}
                                            />
                                            <img alt="img" src={require('../../assets/img/trash.png')}
                                                onClick={() => deleteCategory(item.id)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                    }
                                </>

                            )
                        })
                        }
                    </MDBRow>
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    )
}