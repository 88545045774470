import React, { useEffect, useState } from 'react';
import './index.css';

export default function FileUpload(props) {
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const fileHandler = e => {
        if (e.target.files.length > 0) {
            setFileName(e.target?.files[0].name)
            setFile(e.target.files)
            props.onChange(e.target.files)
        }
    }
    return (
        <div className='upload-container'>
            <label htmlFor={props.id}>
                {file !== null ? (fileName) : (props.label ? props.label : 'Browse...')}
                {props.icon && <p><br />{props.icon} </p>}
            </label>
            <input type="file" name="photo" className="upload-photo" id={props.id}
                onChange={e => fileHandler(e)} />
        </div>
    )
}