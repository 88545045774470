import React from 'react'
import { getChannelLogo } from '../../config'
import './index.scss';

export default function ChannelCard({ channel, channelName, active, onClick, className }) {

    return (
        <div onClick={e => onClick(channel)} className={`channel-card-container ${className}  ${active && 'active'}`}>
            <div alt='channelLogo' src={getChannelLogo(channel.id)}
                className={'channel-card '}
                style={{
                    backgroundImage: 'url("' + getChannelLogo(channel.id) + '")',
                }}
            ></div>
            <p className='mt-3'>{channelName}</p>
        </div>
    )
}
