import { MDBIcon } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { isRtl } from '../../../config';

export default function Fee(props) {
    const { channel, value, onChange, special, r = channel?.media_type === 1 ? 25 : 35 } = props;

    const [feeClass1, setFeeClass1] = useState(null);
    const [feeClass2, setFeeClass2] = useState(null);
    useEffect(() => {

        if (channel.media_type === 1 ? feeClass1 % 100 !== 25 : feeClass1 % 100 !== r)
            onChange(feeClass1)
        else
            onChange(`${feeClass1}+${feeClass2}%`)
        return () => {

        };
    }, [feeClass1, feeClass2]);

    useEffect(() => {
        if (value) {
            const splitted = value.toString().split('+')
            if (splitted.length > 1) {
                setFeeClass1(parseInt(splitted[0]))
                setFeeClass2(!isNaN(splitted[1].replace('%', '')) && splitted.length > 0 ? parseInt(splitted[1].replace('%', '')) : 0)
            }
            else {
                setFeeClass1(parseInt(value))
            }
        }
        return () => {

        };
    }, [value]);

    const getFeeClassOption = () => {
        let min = channel.fee_class_prefix_id * 100 + 1
        let arr = []
        if (special)
            arr.push(<option></option>)
        for (let index = min; index < min + r; index++) {
            arr.push(<option>{index}</option>)
        }

        return arr

    }
    return (
        <div className={`d-flex align-items-center w-100 ${isRtl() ? 'flex-row-reverse' : 'flex-row'}`}>
            <select value={feeClass1} onChange={e => setFeeClass1(e.target.value)}>
                {channel != null && getFeeClassOption(channel.fee_class_prefix_id)}
            </select>
            <MDBIcon icon='plus-square' className='mx-1 h-100 d-flex align-items-center'
                style={{
                    margin: '.5rem',
                    visibility: feeClass1 % 100 !== r ? 'hidden' : 'visible'
                }} />
            <input type='text' style={{
                width: '4rem', height: '3rem',
                visibility: feeClass1 % 100 !== r ? 'hidden' : 'visible'
            }}
                value={feeClass2} onChange={e => setFeeClass2(e.target.value)} />
            <MDBIcon icon='percentage' className='mx-1 h-100 d-flex align-items-center'
                style={{
                    margin: '.5rem',
                    visibility: feeClass1 % 100 !== r ? 'hidden' : 'visible'
                }} />
        </div>
    )
}
