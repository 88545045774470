import { BASE_URL, convertTimestap, instanceAxios } from "../config";

const callApi = async config => {
    try {
        const { data } = await instanceAxios(config)
        return { data }
    }
    catch (error) {
        return { error }
    }
}
const logout = async () => {
    var axios = require('axios');

    const p = []


    var configAccess = {
        method: 'post',
        url: BASE_URL + '/logout_access',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
    };
    p.push(axios(configAccess))

    var configRefresh = {
        method: 'post',
        url: BASE_URL + '/logout_refresh',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('refreshToken')
        }
    };
    p.push(axios(configRefresh))


    try {

        const { data } = await Promise.all(p)
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        console.info(data)
        return { data }
    }
    catch (error) {
        return { error }
    }

}
const refresh = async () => {
    let config = {
        method: 'post',
        url: BASE_URL + '/refresh',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("refreshToken")}`
        }
    };
    return await callApi(config)
}
const account = async (token) => {
    let accountConfig = {
        method: 'get',
        url: BASE_URL + '/account',
        headers: {
            'Authorization': `Bearer ${token || localStorage.getItem('accessToken')}`
        }
    };
    try {
        const { data } = await instanceAxios(accountConfig)
        return { data }
    }
    catch (error) {
        return { error }
    }
}
const getChannels = async () => {
    var config = {
        method: 'get',
        url: BASE_URL + '/channel',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
    };
    return await callApi(config)

}

const getBoxes = async ({ stream_id, selectedDay }) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/box_number/${stream_id}/${selectedDay}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
    };
    return await callApi(config)


}
const getBoxExport = async (params) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/box/export`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')} `
        },
        params
    };
    return await callApi(config)


}
const deleteBoxExport = async (id) => {
    var config = {
        method: 'DELETE',
        url: BASE_URL + '/box/export/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
    };
    return await callApi(config)
}
const getBoxStatusExport = async () => {
    var config = {
        method: 'GET',
        url: BASE_URL + '/box/export/status',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
    };
    return await callApi(config);
}
const getAds = async (params) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/ad`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params
    };
    return await callApi(config)


}
const getAd = async (id) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/ad/${id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
    };
    return await callApi(config)
}
const editAd = async ({ fd, id }) => {
    var config = {
        method: 'put',
        url: BASE_URL + '/ad/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: fd
    };
    return await callApi(config)
}
const deleteAd = async (id) => {
    var config = {
        method: 'DELETE',
        url: BASE_URL + '/ad/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
    };
    return await callApi(config)
}
const addAd = async ({ fd, onUploadProgress }) => {
    var config = {
        method: 'post',
        url: BASE_URL + '/ad',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            Accept: "application/json",
        },

        data: fd,
        onUploadProgress
    };
    return await callApi(config)
}
const getCompanies = async () => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/company`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
    };
    return await callApi(config)
}

const addCompany = async ({ name, groupId }) => {
    var data = new FormData();
    data.append('name', name);
    data.append('group_id', groupId);

    var config = {
        method: 'post',
        url: `${BASE_URL}/company`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };

    return await callApi(config)
}

const deleteCompany = async (id) => {

    var config = {
        method: 'delete',
        url: `${BASE_URL}/company/${id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
    };

    return await callApi(config)
}

const editCompany = async ({ id, name, groupId }) => {

    let data = new FormData();
    data.append('name', name);
    data.append('group_id', groupId);
    var config = {
        method: 'put',
        url: `${BASE_URL}/company/${id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };

    return await callApi(config)
}

const getBrands = async () => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/brand`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
    };
    return await callApi(config)
}


const addBrand = async ({ name, companyId }) => {
    let data = new FormData();
    data.append('name', name);
    data.append('company_id', companyId);
    var config = {
        method: 'post',
        url: BASE_URL + '/brand',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };

    return await callApi(config)
}

const deleteBrand = async ({ id }) => {

    var config = {
        method: 'delete',
        url: `${BASE_URL}/brand/${id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
    };

    return await callApi(config)
}

const editBrand = async ({ id, name }) => {

    let data = new FormData();
    data.append('name', name);
    data.append('id', id);
    const config = {
        method: 'put',
        url: `${BASE_URL}/brand/${id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };

    return await callApi(config)
}

const getTags = async (params) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/tag`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params
    };
    return await callApi(config)
}
const addTag = async data => {
    var config = {
        method: 'post',
        url: BASE_URL + '/tag',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };
    return await callApi(config)
}
const editTag = async (id, data) => {
    var config = {
        method: 'put',
        url: BASE_URL + '/tag/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };
    return await callApi(config)
}
const deleteTag = async (id) => {
    var config = {
        method: 'delete',
        url: BASE_URL + '/tag/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
    };
    return await callApi(config)
}

const editMatch = async (data, id) => {
    var config = {
        method: 'put',
        url: BASE_URL + '/match/' + id,
        data,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            responseType: 'blob',
        }
    };
    return await callApi(config)
}
const exportMatch = async (params) => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/match/export`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')} `
        },
        params
    };
    return await callApi(config)
}
const getPieChart = async (input) => {
    const {
        startTime,
        endTime,
        selectedDayRange,
        codeFilter,
        tagFilter,
        subcategory,
        selectedCompany,
        selectedBrand,
        selectedAd,
        adType,
        selectedChannel,
        mediaType,
        groupBy,
        aggregateBy,
    } = input;

    let params = new URLSearchParams();

    let hasStarTime = startTime !== '';
    let startHour = 0, startMin = 0, endHour = 0, endMin = 0;
    if (hasStarTime) {
        let tmp = startTime.split(':')
        startHour = tmp[0];
        startMin = tmp[1]
    }
    let hasEndTime = endTime !== '';
    if (hasEndTime) {
        let tmp = endTime.split(':')
        endHour = tmp[0];
        endMin = tmp[1]
    }

    if (selectedDayRange.from !== null)
        params.append('start_time', convertTimestap(selectedDayRange.from, { hour: startHour, minute: startMin }));
    if (selectedDayRange.to !== null)
        params.append('end_time', convertTimestap(selectedDayRange.to, { hour: endHour, minute: endMin, second: 59 }))

    for (let i = 0; i < codeFilter.length; i++) {
        params.append('ad_code', codeFilter[i])

    }
    for (let i = 0; i < tagFilter.length; i++) {
        params.append('tag_id', tagFilter[i])
    }
    for (let i = 0; i < subcategory.length; i++) {
        params.append('tag_id', subcategory[i])
    }
    for (let i = 0; i < selectedCompany.length; i++) {
        params.append("company_id", selectedCompany[i]);
    }
    for (let i = 0; i < selectedBrand.length; i++) {
        params.append("brand_id", selectedBrand[i]);
    }
    for (let i = 0; i < selectedAd.length; i++) {
        params.append("ad_id", selectedAd[i]);
    }
    for (let i = 0; i < adType.length; i++) {
        params.append("ad_type", adType[i]);
    }
    for (let i = 0; i < selectedChannel.length; i++) {
        params.append("channel_id", selectedChannel[i]);
    }
    for (let i = 0; i < mediaType.length; i++) {
        params.append('media_type', mediaType[i])
    }

    params.append('group_by', groupBy)
    params.append('aggregate_by', aggregateBy)

    var config = {
        method: 'get',
        url: `${BASE_URL}/match/chart/pie`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params
    };
    return await callApi(config)
}

const getReport = async (params) => {


    var config = {
        method: 'get',
        url: `${BASE_URL}/match`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')} `
        },
        params
    };
    return await callApi(config)
}
const editConductor = async (conductor) => {

    let fd = new FormData();
    if (conductor.group1_fee_class)
        fd.append('group1_fee_class', conductor.group1_fee_class);
    if (conductor.group2_fee_class)
        fd.append('group2_fee_class', conductor.group2_fee_class);
    if (conductor.group3_fee_class)
        fd.append('group3_fee_class', conductor.group3_fee_class);
    if (conductor.program)
        fd.append('program', conductor.program);
    if (conductor.special_fee_class)
        fd.append('special_fee_class', conductor.special_fee_class);
    else
        fd.append('special_fee_class', "null");
    if (conductor.timestamp)
        fd.append('timestamp', conductor.timestamp);


    const config = {
        method: 'put',
        url: `${BASE_URL}/conductor/${conductor.id}`,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: fd
    };

    return await callApi(config)

}
const getConductors = async ({ from, to, selectedChannel, currentPage }) => {
    let params = new URLSearchParams();

    if (from !== null)
        params.append('start_time', convertTimestap(from, { hour: 0, minute: 0 }));
    if (to !== null)
        params.append('end_time', convertTimestap(to, { hour: 23, minute: 59, second: 59 }))
    for (let i = 0; i < selectedChannel.length; i++) {
        params.append("channel_id", selectedChannel[i]);
    }
    params.append('items_per_page', 20)
    params.append('page', currentPage)

    var config = {
        method: 'get',
        url: BASE_URL + '/conductor',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        params
    };

    return await callApi(config)
}
const deleteConductors = async ({ from, to, selectedChannel }) => {
    let params = new URLSearchParams();

    if (from !== null)
        params.append('start_time', convertTimestap(from, { hour: 0, minute: 0 }));
    if (to !== null)
        params.append('end_time', convertTimestap(to, { hour: 23, minute: 59, second: 59 }))
    for (let i = 0; i < selectedChannel.length; i++) {
        params.append("channel_id", selectedChannel[i]);
    }

    var config = {
        method: 'delete',
        url: BASE_URL + '/conductor',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        params
    };

    return await callApi(config)
}
const addConductor = async (fd) => {
    var config = {
        method: 'post',
        url: BASE_URL + '/conductor',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: fd
    };
    return await callApi(config)
}

const getSpecials = async () => {
    var config = {
        method: 'get',
        url: BASE_URL + '/conductor/special',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
    };
    return await callApi(config)
}

const addSpecial = async ({ specialYear, specialMonth, specialTags, specialChannel }) => {
    var d = new FormData()
    d.append('date', specialYear + '/' + specialMonth)
    d.append('tag_ids', '[' + specialTags.join(',') + ']')
    d.append('channel_ids', '[' + specialChannel.join(',') + ']')
    var config = {
        method: 'post',
        url: BASE_URL + '/conductor/special',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: d
    };
    return await callApi(config)
}
const deleteSpecial = async ({ date, tag_ids, channel_ids }) => {
    var d = new FormData()
    d.append('date', date)
    d.append('tag_ids', '[' + tag_ids + ']')
    d.append('channel_ids', '[' + channel_ids.join(',') + ']')
    var config = {
        method: 'post',
        url: BASE_URL + '/conductor/special',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: d
    };
    return await callApi(config)
}




const getUsers = async () => {
    var config = {
        method: 'get',
        url: BASE_URL + '/user',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },

    };
    return await callApi(config)
}

const deleteUser = async (id) => {
    var config = {
        method: 'delete',
        url: BASE_URL + '/user/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }

    };
    return await callApi(config)
}

const addUser = async (data) => {
    var config = {
        method: 'post',
        url: BASE_URL + '/user',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: data
    };

    return await callApi(config)
}

const editUser = async ({ fd, id }) => {
    var config = {
        method: 'put',
        url: BASE_URL + '/user/' + id,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: fd
    };


    return await callApi(config)
}

const editPassword = async ({ fd, id }) => {
    var config = {
        method: 'put',
        url: BASE_URL + '/password',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        data: fd
    };


    return await callApi(config)
}

const getStatus = async () => {
    var config = {
        method: 'get',
        url: `${BASE_URL}/status`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
    };
    return await callApi(config)
}

export {
    account,
    refresh,
    logout,

    getChannels,
    getBoxes,
    getBoxExport,
    deleteBoxExport,
    getBoxStatusExport,
    getAds,
    getAd,
    addAd,
    editAd,
    deleteAd,

    getCompanies,
    addCompany,
    deleteCompany,
    editCompany,

    getBrands,
    editBrand,
    deleteBrand,
    addBrand,

    getPieChart,
    editMatch,
    getReport,
    exportMatch,

    editConductor,
    getConductors,
    deleteConductors,
    addConductor,

    getSpecials,
    addSpecial,
    deleteSpecial,

    getTags,
    addTag,
    editTag,
    deleteTag,

    getUsers,
    deleteUser,
    addUser,
    editUser,
    editPassword,

    getStatus,

}