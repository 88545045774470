import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
    MDBContainer, MDBRow, MDBCol, toast
} from 'mdbreact';
import { Language } from '../../config'
import { editTag, getTags } from '../../api';

export default function Trends() {
    const { t } = useTranslation(['home']); // "home" is namespace

    const [trends, setTrends] = useState([])

    const [selectedMegaTrend, setSelectedMegaTrend] = useState(-1)
    //const [addInputMegaTrend, setAddInputMegaTrend] = useState('')
    const [editInputMegaTrend, setEditInputMegaTrend] = useState('')
    const inputMegaTrendRef = useRef(null);

    const [selectedSubTrend, setSelectedSubTrend] = useState(-1)
    //const [addInputSubTrend, setAddInputSubTrend] = useState('')
    const [editInputSubTrend, setEditInputSubTrend] = useState('')
    const inputSubTrendRef = useRef(null);

    useEffect(() => {
        getTrends();

    }, [])
    useEffect(() => {
        if (selectedMegaTrend !== -1) {
            inputMegaTrendRef.current.focus();
        }
    }, [selectedMegaTrend]);
    useEffect(() => {
        if (selectedSubTrend !== -1) {
            inputSubTrendRef.current.focus();
        }
    }, [selectedSubTrend]);
    const getTrends = async () => {

        const { data, error } = await getTags();
        if (data) {
            setTrends(data.filter(x => x.type !== 0));
        }

    }
    // const addTrend = (type) => {
    //     let data = new FormData();
    //     if (type === 1)
    //         data.append('name', addInputMegaTrend);
    //     else
    //         data.append('name', addInputSubTrend);
    //     data.append('type', type);
    //     var config = {
    //         method: 'post',
    //         url: BASE_URL + '/tag',
    //         headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             setAddInputSubTrend('')
    //             setAddInputMegaTrend('')
    //             getTrends();
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
    // const deleteTag = (id) => {
    //     var config = {
    //         method: 'delete',
    //         url: BASE_URL + '/tag/' + id,
    //         headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    //         },
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             getTrends();
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
    const editCategory = async (id, t) => {
        var data = new FormData();
        if (t === 1)
            data.append('name', editInputMegaTrend);
        else if (t === 2)
            data.append('name', editInputSubTrend);

        const { data: response, error } = await editTag(id, data)

        if (response) {
            if (t === 1)
                setSelectedMegaTrend(-1)
            else if (t === 2)
                setSelectedSubTrend(-1)
            getTrends();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }



    }
    return (
        <MDBContainer fluid className={Language}>
            <MDBRow className='mt-5 mb-3'>
                <MDBCol md='6'>
                    <h5>{t('Trends')}</h5>
                </MDBCol>
                <MDBCol md='6'>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol md='6' style={{ alignItems: 'center' }}>
                    {/* <input type='text' style={{ display: 'inline-block', width: '70%', height: '3rem', marginBottom: '0' }}
                        onChange={e => setAddInputMegaTrend(e.target.value)}
                        placeholder={t('Mega trend')} />
                    <img alt="img" src={require('../../assets/img/plus.png')}
                        onClick={() => addTrend(1)}
                        style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} /> */}
                    <MDBRow>
                        {trends.filter(x => x.type === 1).map((item, i) => {
                            return (
                                <>
                                    {selectedMegaTrend === item.id ?
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                style={{
                                                    display: 'inline-flex', width: '70%', height: '3rem',
                                                    backgroundColor: '#FFF',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center',
                                                    borderRadius: '1rem',
                                                    marginBottom: '0',
                                                    padding: '1rem'
                                                }}
                                                defaultValue={item.name} ref={inputMegaTrendRef}
                                                onChange={e => setEditInputMegaTrend(e.target.value)} />
                                            <img alt="img" src={require('../../assets/img/confirm.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                                onClick={() => editCategory(item.id, 1)}
                                            />
                                            <img alt="img" src={require('../../assets/img/deny.png')}
                                                onClick={() => setSelectedMegaTrend(-1)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                        :
                                        <MDBCol md='12' className='mt-2'>
                                            <label style={{
                                                display: 'inline-flex', width: '70%', height: '3rem',
                                                backgroundColor: '#F3F5F9',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                borderRadius: '1rem',
                                                marginBottom: '0',
                                                padding: '1rem'
                                            }} >{item.name}</label>
                                            {/* <img alt="img" src={require('../../assets/img/edit.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                                onClick={() => setSelectedMegaTrend(item.id)}
                                            />
                                            <img alt="img" src={require('../../assets/img/trash.png')}
                                                onClick={() => deleteTag(item.id)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} /> */}
                                        </MDBCol>
                                    }
                                </>

                            )
                        })
                        }

                    </MDBRow>

                </MDBCol>
                <MDBCol md='6' style={{ alignItems: 'center' }}>
                    {/* <input type='text' style={{ display: 'inline-block', width: '70%', height: '3rem', marginBottom: '0' }}
                        onChange={e => setAddInputSubTrend(e.target.value)}
                        placeholder={t('Sub trend')} />
                    <img alt="img" src={require('../../assets/img/plus.png')}
                        onClick={() => addTrend(2)}
                        style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} /> */}
                    <MDBRow>
                        {trends.filter(x => x.type === 2).map((item, i) => {
                            return (
                                <>
                                    {selectedSubTrend === item.id ?
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                style={{
                                                    display: 'inline-flex', width: '70%', height: '3rem',
                                                    backgroundColor: '#FFF',
                                                    verticalAlign: 'middle',
                                                    alignItems: 'center',
                                                    borderRadius: '1rem',
                                                    marginBottom: '0',
                                                    padding: '1rem'
                                                }}
                                                onChange={e => setEditInputSubTrend(e.target.value)}
                                                defaultValue={item.name} ref={inputSubTrendRef} />
                                            <img alt="img" src={require('../../assets/img/confirm.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                                onClick={() => editCategory(item.id, 2)}
                                            />
                                            <img alt="img" src={require('../../assets/img/deny.png')}
                                                onClick={() => setSelectedSubTrend(-1)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                        </MDBCol>
                                        :
                                        <MDBCol md='12' className='mt-2'>
                                            <label style={{
                                                display: 'inline-flex', width: '70%', height: '3rem',
                                                backgroundColor: '#F3F5F9',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                borderRadius: '1rem',
                                                marginBottom: '0',
                                                padding: '1rem'
                                            }} >{item.name}</label>
                                            {/* <img alt="img" src={require('../../assets/img/edit.png')}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                                onClick={() => setSelectedSubTrend(item.id)}
                                            />
                                            <img alt="img" src={require('../../assets/img/trash.png')}
                                                onClick={() => deleteTag(item.id)}
                                                style={{ backgroundColor: '#F3F5F9', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} /> */}
                                        </MDBCol>
                                    }
                                </>

                            )
                        })
                        }

                    </MDBRow>

                </MDBCol>

            </MDBRow>
        </MDBContainer>
    )
}