import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import {
    MDBModal, MDBContainer, MDBRow, MDBCol,
    MDBModalHeader, MDBModalBody, toast
} from 'mdbreact';
import { Language } from '../../config'
import * as api from '../../api';

export default function Company() {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [selected, setSelected] = useState(-1);
    const [selectedBrand, setSelectedBrand] = useState(-1)
    const [companies, setCompanies] = useState([]);
    const [addInput, setAddInput] = useState('')
    const [editInput, setEditInput] = useState('')
    const [editInputBrand, setEditInputBrand] = useState('')
    const [groupAdd, setGroupAdd] = useState(3)
    const [groupEdit, setGroupEdit] = useState(3)
    const inputRef = useRef(null);
    const [showModal, setShowModal] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [searchCompany, setSearchCompany] = useState('')
    const [searchGroup, setSearchGroup] = useState(-1)
    useEffect(() => {
        getCompanies();

    }, [])
    useEffect(() => {
        if (selected !== -1) {
            inputRef.current.focus();
        }
    }, [selected]);
    const getCompanies = async () => {


        const { data: dataCompanies, error: errorCompanies } = await api.getCompanies()

        if (dataCompanies) {
            setCompanies(dataCompanies);
        }

        const { data: dataBrands, error: errorBrands } = await api.getBrands()

        if (dataBrands) {
            dataCompanies?.map(item => {
                item.brands = dataBrands.filter(x => x.company.name === item.name)
            })
            if (showModal && selectedCompany) {
                setSelectedCompany(dataCompanies.filter(x => x.name === selectedCompany.name)[0])
            }
        }


    }
    const addCompany = async () => {
        if (addInput === '')
            return;

        const { data, error } = await api.addCompany({ name: addInput, groupId: groupAdd });
        if (data) {
            setAddInput('')
            getCompanies();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });

        }
        else {
            if (error.response?.data?.message?.indexOf('duplicate') > -1) {
                toast.error(t('The company name is duplicate'), {
                    closeButton: false
                });
                return;
            }
        }

    }
    const deleteCompany = async (id) => {
        const { data, error } = await api.deleteCompany(id)
        if (data) {
            setAddInput('')
            getCompanies();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {

        }
    }
    const editCompany = async (id) => {

        const { data, error } = api.editCompany({ id: id, name: editInput, groupId: groupEdit })

        if (data) {
            setSelected(-1)
            getCompanies();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
    }
    const updateBrand = async (id) => {

        const { data, error } = await api.editBrand({ id, name: editInputBrand })

        if (data) {
            getCompanies();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
    }
    const deleteBrand = async (id) => {
        const { data, error } = await api.deleteBrand({ id })

        if (data) {
            setAddInput('')
            getCompanies();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
    }
    const addBrandRef = useRef(null)
    const addBrand = async () => {
        const { data, error } = await api.addBrand({ name: addBrandRef.current.value, companyId: selectedCompany.id })

        if (data) {
            getCompanies();
            addBrandRef.current.value = ''
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
    }
    return (
        <MDBContainer fluid className={Language}>
            <MDBRow className='mt-5 mb-3'>
                <MDBCol md='6'>
                    <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Companies')}</h5>
                </MDBCol>
                <MDBCol md='6'>
                </MDBCol>
                <MDBCol md="12" lg="6" className='mt-2' style={{ alignItems: 'center' }}>
                    <form onSubmit={e => { e.preventDefault(); addCompany() }}>
                        <input type='text' style={{
                            display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0', marginLeft: '1%',
                        }}
                            placeholder={t('Company name')}
                            value={addInput}
                            onChange={e => setAddInput(e.target.value)} />
                        <select onChange={e => setGroupAdd(e.target.value)} value={groupAdd} title={t('Group')}
                            style={{
                                display: 'inline-block', width: '48px', height: '3rem', marginBottom: '0',
                                backgroundImage: 'none'
                            }}>
                            <option disabled>{t('Group')}</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                        <img alt="img" src={require('../../assets/img/plus.png')}
                            onClick={e => addCompany()}
                            style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                    </form>
                </MDBCol>
                <MDBCol md="12" lg="6" className='mt-2'>
                    <form onSubmit={e => { e.preventDefault(); addCompany() }}>
                        <input type='text' style={{
                            display: 'inline-block', width: '50%', height: '3rem', marginBottom: '0', marginLeft: '1%',
                        }}
                            placeholder={t('Company name')}
                            value={searchCompany}
                            onChange={e => setSearchCompany(e.target.value)} />
                        <select onChange={e => setSearchGroup(e.target.value)} value={searchGroup} title={t('Group')}
                            style={{
                                display: 'inline-block', width: '48px', height: '3rem', marginBottom: '0',
                                backgroundImage: 'none'
                            }}>
                            <option value='-1'>{t('Group')}</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                        </select>
                        <img alt="img" src={require('../../assets/img/search.png')}
                            style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }} />
                    </form>
                </MDBCol>

            </MDBRow>
            <MDBRow>
                {companies
                    .filter(x => (x.group_id === searchGroup || searchGroup === -1) && (x.name.indexOf(searchCompany) > -1))
                    .map((item, i) => {
                        return (
                            <>
                                {selected === item.id ?
                                    <MDBCol md="12" lg="6" className='mt-2'>
                                        <input type='text'
                                            style={{
                                                display: 'inline-flex', width: '50%', height: '3rem', marginLeft: '1%',
                                                backgroundColor: '#FFF',
                                                verticalAlign: 'middle',
                                                alignItems: 'center',
                                                borderRadius: '1rem',
                                                marginBottom: '0',
                                                padding: '1rem'
                                            }}
                                            onChange={e => setEditInput(e.target.value)}
                                            value={editInput}
                                            defaultValue={item.name} ref={inputRef} />
                                        <select onChange={e => setGroupEdit(e.target.value)} value={groupEdit}
                                            style={{
                                                display: 'inline-block', width: '48px', height: '3rem', marginBottom: '0',
                                                backgroundImage: 'none'
                                            }}>
                                            <option disabled>گروه</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                        </select>
                                        <img alt="img" src={require('../../assets/img/confirm.png')}
                                            style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                            onClick={() => editCompany(item.id)}
                                        />
                                        <img alt="img" src={require('../../assets/img/deny.png')}
                                            onClick={() => setSelected(-1)}
                                            style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                    </MDBCol>
                                    :
                                    <MDBCol md="12" lg="6" className='mt-2'>
                                        <label style={{
                                            display: 'inline-flex', width: '50%', height: '3rem',
                                            backgroundColor: '#F3F5F9',
                                            verticalAlign: 'middle',
                                            alignItems: 'center',
                                            borderRadius: '1rem',
                                            marginBottom: '0',
                                            padding: '1rem',
                                            marginLeft: '1%',
                                            cursor: 'pointer'
                                        }}
                                            onClick={() => { setShowModal(true); setSelectedCompany(item) }}
                                        >{item.name}</label>
                                        <label style={{
                                            display: 'inline-flex', width: '48px', height: '3rem',
                                            backgroundColor: '#F3F5F9',
                                            verticalAlign: 'middle',
                                            alignItems: 'center',
                                            borderRadius: '1rem',
                                            marginBottom: '0',
                                            padding: '1rem'
                                        }} >{item.group_id}</label>
                                        <img alt="img" src={require('../../assets/img/edit.png')}
                                            style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%', marginRight: '3%' }}
                                            onClick={() => { setSelected(item.id); setEditInput(item.name); setGroupEdit(item.group_id) }}
                                        />
                                        <img alt="img" src={require('../../assets/img/trash.png')}
                                            onClick={() => { deleteCompany(item.id) }}
                                            style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                    </MDBCol>
                                }
                            </>

                        )
                    })
                }
            </MDBRow>
            <MDBModal size='lg' isOpen={showModal} toggle={() => setShowModal(!showModal)} >
                <MDBModalHeader toggle={() => setShowModal(!showModal)}>
                    {selectedCompany && selectedCompany.name}
                </MDBModalHeader>
                <MDBModalBody style={{ maxHeight: '50vh', overflow: 'auto' }}>
                    <p>
                        <input ref={addBrandRef}
                            type='text'
                            style={{
                                display: 'inline-flex', width: '60%', height: '3rem', marginLeft: '1%',
                                backgroundColor: '#FFF',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem'
                            }} />
                        <img alt="img" src={require('../../assets/img/plus.png')}
                            onClick={() => { addBrand() }}
                            style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                    </p>
                    {selectedCompany && selectedCompany.brands?.length > 0 && selectedCompany.brands.map(item => {
                        return <p>
                            {selectedBrand === item.id ?
                                <>
                                    <input value={editInputBrand} onChange={e => setEditInputBrand(e.target.value)}
                                        type='text'
                                        style={{
                                            display: 'inline-flex', width: '60%', height: '3rem', marginLeft: '1%',
                                            backgroundColor: '#FFF',
                                            verticalAlign: 'middle',
                                            alignItems: 'center',
                                            borderRadius: '1rem',
                                            marginBottom: '0',
                                            padding: '1rem'
                                        }} />
                                    <img alt="img" src={require('../../assets/img/confirm.png')}
                                        style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                        onClick={() => { updateBrand(item.id); setSelectedBrand(-1); }}
                                    />
                                    <img alt="img" src={require('../../assets/img/deny.png')}
                                        onClick={() => { setSelectedBrand(-1); setEditInputBrand('') }}
                                        style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                </>
                                :
                                <>
                                    <label style={{
                                        display: 'inline-flex', width: '60%', height: '3rem', marginLeft: '1%',
                                        backgroundColor: '#FFF',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem'
                                    }}>{item.name}</label>
                                    <img alt="img" src={require('../../assets/img/edit.png')}
                                        style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }}
                                        onClick={() => { setEditInputBrand(item.name); setSelectedBrand(item.id); }}
                                    />
                                    <img alt="img" src={require('../../assets/img/trash.png')}
                                        onClick={() => { deleteBrand(item.id) }}
                                        style={{ backgroundColor: '#F3F5F9', height: '48px', width: '48px', padding: '1rem', borderRadius: '.7rem', marginLeft: '3%' }} />
                                </>
                            }
                        </p>
                    })}
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    )
}