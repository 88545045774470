import React from 'react';
import { MDBFooter } from 'mdbreact';
import { Language } from '../config';

const Copyrights = props => {
  return (
    <MDBFooter
      className={props.className}
      style={{ ...props.style, zIndex: 2 }}
    >
      <p className='footer-copyright mb-0 py-3 text-center'>
        {Language === 'fa' ?
          'تمام حقوق مادی و معنوی این اثر برای مجموعه محفوظ است' :
          'All rights reserved'}
      </p>
    </MDBFooter>
  );
};
export default Copyrights;
