import React, { Component, createRef } from 'react';
import Clappr from 'clappr';
import './index.css'

export default class clapprPlayer extends Component {
    constructor(props) {
        super(props)
        this.playerRef = createRef()
    }

    componentDidMount() {
        const { id } = this.props;
        this.clappr_player = new Clappr.Player({
            parentId: `#${id}`,
            source: this.props.source,
            autoPlay: this.props.autoPlay,
            width: '100%'
        });
    }
    componentWillReceiveProps() {

    }

    componentWillUnmount() {
        this.clappr_player.destroy();
        this.clappr_player = null;
    }


    render() {
        const { id } = this.props;

        return (
            <div className="embed-responsive" style={{ textAlign: 'center', margin: '15px auto', fontFamily: 'DiodrumArabic',width:this.props.width,height:this.props.height }}>
                <p style={{marginBottom:'28px',fontSize:'23px'}}>{this.props.name}</p>
                <div className="video-player-wrapper" id={id}></div>
            </div>
        );
    }

}