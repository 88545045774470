import {
    MDBModalBody, MDBModalHeader, MDBCol, MDBContainer, MDBIcon, MDBModal, MDBRow,
    toast,
} from 'mdbreact';

import React, { useEffect, useState } from 'react';
import { Language, BASE_URL, convertDate, convertTimestap, convertToHijri, isRtl, getTimeMode } from '../../config';
import './index.scss'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { } from "react-modern-calendar-datepicker";
import ToggleSwitch from '../common/ToggleSwitch';
import FileUpload from '../common/FileUpload';
import { useTranslation } from 'react-i18next';
import { useUserValue } from '../../features/login';
import DropDown from '../../assets/DropDown';
import * as api from '../../api'

export default function Videos(props) {
    const { user } = useUserValue();
    const { t } = useTranslation(['home']); // "home" is namespace

    let moment = require('jalali-moment');
    moment.locale(getTimeMode())

    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [videos, setVideos] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [selectedVideo, setSelectedVideo] = useState(-1)
    const [nameFilter, setNameFilter] = useState('')
    const [codeFilter, setCodeFilter] = useState('')
    const [mediaType, setMediaType] = useState([0])
    const [adTypeFilter, setAdTypeFilter] = useState([])
    const [mediaTypeFilter, setMediaTypeFilter] = useState([])
    //
    const [nameInsert, setNameInsert] = useState('')
    const [dateFrom, setDateFrom] = useState(convertToHijri())
    const [dateTo, setDateTo] = useState()
    const [company, setCompany] = useState([])
    const [brands, setBrands] = useState([])
    const [brand, setBrand] = useState([])
    const [category, setCategory] = useState([])
    const [subcategory, setSubcategory] = useState([])
    const [enabled, setEnabled] = useState(false)
    const [video, setVideo] = useState(null)
    const [megaTrend, setMegaTrend] = useState([])
    const [subTrend, setSubTrend] = useState([])
    const [adType, setAdType] = useState('')
    const [description, setDescription] = useState('')
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const [code, setCode] = useState(null)
    const [revo, setRevo] = useState(null)
    const [formDisable, setFormDisable] = useState(false)
    useEffect(() => {
        setFormDisable(user.role === 4)

        return () => {

        }
    }, [user?.role])

    //
    useEffect(() => {
        getVideos();
        getCompanies();
        getBrands();
        getTags();
    }, [])
    useEffect(() => {
        if (revo)
            URL.revokeObjectURL(revo)

        return () => {

        }
    }, [showModal])

    useEffect(() => {
        getVideos();
    }, [currentPage, selectedCategory, selectedCompany])

    const deleteVideo = async (e) => {
        let params = new URLSearchParams();
        params.append('items_per_page', 1)
        params.append("ad_id", e);
        params.append('page', 1)

        const { data, error } = await api.getReport(params);
        if (data) {
            if (window.confirm('از این تبلیغ ' + data.page_count + ' تعداد گزارش ثبت شده با حذف آن گزارشهای آن نیز حذف میشود!' +
                '\nاز حذف این مورد مطمئنید؟')) {


                const { data: dataDelete, error: errorDelete } = await api.deleteAd(e);
                if (dataDelete) {
                    getVideos();
                    setSelectedVideo(-1);
                    setShowModal(false)
                }
            }
        }

    }
    const getVideos = async () => {
        let params = new URLSearchParams()

        for (let i = 0; i < selectedCompany.length; i++) {
            params.append("company_id", selectedCompany[i]);
        }
        for (let i = 0; i < selectedCategory.length; i++) {
            params.append("tag_id", selectedCategory[i]);
        }
        if (nameFilter !== null && nameFilter !== '')
            params.append('name', nameFilter)
        if (codeFilter !== null && codeFilter !== '')
            params.append('code', codeFilter)
        if (mediaTypeFilter.length > 0)
            params.append('media_type', mediaTypeFilter[0])

        for (let i = 0; i < adTypeFilter.length; i++) {
            params.append('type', adTypeFilter[i])
        }

        params.append('items_per_page', 12)
        params.append('page', currentPage)

        const { data, error } = await api.getAds(params);
        if (data) {
            setVideos(data.items);
            setPageCount(data.page_count)
        }
    }

    const getCompanies = async () => {

        const { data, error } = await api.getCompanies()
        if (data) {
            setCompanies(data.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }


    }
    const getBrands = async () => {
        const { data, error } = await api.getBrands()
        if (data) {
            setBrands(data.sort((x, y) => {
                if (x.name < y.name) { return -1; }
                if (x.name > y.name) { return 1; }
            }))
        }

    }
    const getTags = async () => {
        const { data, error } = await api.getTags()
        if (data) {
            setCategories(data)
        }
    }
    const doSearch = async () => {
        await setVideos([])
        await getVideos();
    }
    const addMovie = async () => {
        if (!validation())
            return
        if (selectedVideo === -1) {
            setUploadPercentage(0);
            var FormData = require('form-data');
            var fd = new FormData();
            fd.append('video', video[0]);
            fd.append('name', nameInsert);
            fd.append('brand_id', brand);
            fd.append('tag_ids', JSON.stringify(category.concat(megaTrend).concat(subTrend).concat(subcategory)));
            fd.append('enabled', enabled);
            fd.append('schedule', JSON.stringify([convertTimestap(dateFrom), convertTimestap(dateTo)]));
            fd.append('type', parseInt(adType));
            fd.append('description', description);
            fd.append('media_type', mediaType[0])
            if (code !== null)
                fd.append('code', code);

            const { data, error } = await api.addAd({
                fd,
                onUploadProgress: progressEvent => setUploadPercentage(Math.round(progressEvent.loaded / progressEvent.total * 100))
            });

            if (data) {
                setShowModal(false);
                getVideos();
                toast.success(t('Ad successfully added'), {
                    closeButton: false
                });
                setUploadPercentage(0)
            }
            else {
                if (error.response) {
                    if (error.response.data.message === 'Duplicate file') {
                        toast.error(t('The Ad file is duplicate'), {
                            closeButton: false
                        });
                    }
                    else if (error.response.data.message.indexOf('duplicate') > -1) {
                        toast.error(t('The Ad name is duplicate'), {
                            closeButton: false
                        });
                    }
                    else if (error.response.data.message.indexOf('Duration too long') > -1) {
                        toast.error('فایل تبلیغ برای نوع انتخابی بیش از حد طولانی است', {
                            closeButton: false
                        });
                    }
                    else {
                        toast.error('Error')
                    }
                }
                else {
                    toast.error('Error')
                }
                setUploadPercentage(0)
            }


        }
        else {
            var FormData = require('form-data');
            var fd = new FormData();
            fd.append('name', nameInsert);
            fd.append('brand_id', brand);
            fd.append('tag_ids', JSON.stringify(category.concat(megaTrend).concat(subTrend).concat(subcategory)));
            fd.append('enabled', enabled);
            fd.append('schedule', JSON.stringify([convertTimestap(dateFrom), convertTimestap(dateTo)]));
            fd.append('type', parseInt(adType));
            fd.append('description', description);
            fd.append('media_type', mediaType[0])
            if (code !== null)
                fd.append('code', code);

            const { data, error } = await api.editAd({ fd, id: selectedVideo });
            if (data) {
                setShowModal(false);
                getVideos();
                toast.success(t('Ad successfully edited'), {
                    closeButton: false
                });
            }
        }

    }
    const modalHandler = async (e) => {

        if (e === -1) {
            setNameInsert('');
            setDateFrom(convertToHijri())
            var d = new Date();
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var c = new Date(year + 1, month, day);
            setDateTo(convertToHijri(c))
            setEnabled(true)
            setSelectedVideo(-1);
            setShowModal(true)
            setAdType('')
            setDescription('')
            setMegaTrend([])
            setSubTrend([])
            setCategory([])
            setSubcategory([])
            setCompany([])
            setMegaTrend([])
            setUploadPercentage(0)
            setCode(null)
            setBrand([])
        }
        else {

            const { data, error } = await api.getAd(e)
            if (data) {
                setNameInsert(data.name)
                setDateFrom(convertDate(data.schedule[0]))
                setDateTo(convertDate(data.schedule[1]))
                setCompany([data.brand.company.id])
                setBrand([data.brand.id])
                setCategory(data.tags.filter(x => x.type === 0).map(x => x.id))
                setSubcategory(data.tags.filter(x => x.type === 3).map(x => x.id))
                setEnabled(data.enabled)
                setAdType(data.type)
                setMegaTrend(data.tags.filter(x => x.type === 1).map(x => x.id))
                setSubTrend(data.tags.filter(x => x.type === 2).map(x => x.id))
                setDescription(data.description)
                setMediaType([data.media_type])
                setCode(data.code)

                setSelectedVideo(e)
                setShowModal(true)
            }
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const tmp = URL.createObjectURL(xhr.response);
                setVideo(tmp);
                setRevo(tmp)
                // var reader = new FileReader();
                // reader.onloadend = function () {
                //     setVideo(reader.result);
                // }
                // reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', `${BASE_URL}/ad/${e}/video`);
            xhr.setRequestHeader('Authorization', `Bearer  ${localStorage.getItem('accessToken')}`)
            xhr.onreadystatechange = function () { // Call a function when the state changes.
                if (this.readyState !== XMLHttpRequest.DONE || this.status !== 200) {
                    setVideo('loading');
                }
            }
            xhr.responseType = 'blob';
            xhr.send();
        }
    }

    const fileHandler = (e) => {
        setVideo(e);
    }
    const validation = () => {

        if (nameInsert === '') {
            toast.error(t('Ad name not entered'), {
                closeButton: false
            });
            return false;
        }
        if (video === null) {
            toast.error(t('Ad file not entered'), {
                closeButton: false
            });
            return false;
        }
        if (dateFrom === '' || dateTo === '') {
            toast.error(t('No start or end date entered'), {
                closeButton: false
            });
            return false;
        }
        if (convertTimestap(dateTo) < convertTimestap(dateFrom)) {
            toast.error(t('The start date must be before the end date'), {
                closeButton: false
            });
            return false;
        }
        if (company.length === 0) {
            toast.error(t('No company selected'), {
                closeButton: false
            });
            return false;
        }
        if (brand.length === 0) {
            toast.error(t('No brand selected'), {
                closeButton: false
            });
            return false;
        }
        if (code !== null && code.length < 4) {
            toast.error(t('The minimum allowed size of the code is 4 digits'), {
                closeButton: false
            });
            return false;
        }

        if (adType === '') {
            toast.error(t('Ad type not selected'), {
                closeButton: false
            });
            return false;
        }
        return true;
    }

    const addCategory = (c, ty) => {
        var fd = new FormData();
        fd.append('name', c);
        fd.append('type', ty);

        const { data, error } = api.addTag(fd)
        if (data) {
            toast.success(t('Item added successfully'), {
                closeButton: false
            });
            getTags();
        }
    }

    return (
        <div fluid className={'w-100 ' + Language}>
            <MDBRow>
                <MDBCol md='12' style={{ marginBottom: '36px' }} className='p-0'>
                    <MDBRow>
                        <MDBCol md='6'>
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold' }}>{t('Search video from')} ...</h5>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md='12' className='p-0'>
                    <form className='search-form'>
                        <MDBRow className='mx-0'>

                            <MDBCol lg={3} md={6} className="mt-2">
                                <div style={{ height: '60px', }} >
                                    <DropDown multiple={false} placeholder={t('Media type')} selectedValues={mediaTypeFilter}
                                        onChange={e => { setCurrentPage(1); setMediaTypeFilter(e) }}
                                        items={[{ name: t('TV'), id: 0 }, { name: t('Radio'), id: 1 }]}>
                                    </DropDown>
                                </div>
                            </MDBCol>
                            <MDBCol lg={3} md={6} className="mt-2">
                                <div style={{ height: '60px', }} >
                                    <DropDown
                                        onChange={e => { setCurrentPage(1); setSelectedCompany(e) }}
                                        items={companies.sort((x, y) => x.name > y.name)}
                                        selectedValues={selectedCompany}
                                        placeholder={t('All companies')} >
                                    </DropDown>
                                </div>
                            </MDBCol>
                            <MDBCol lg={3} md={6} className="mt-2">
                                <div style={{ height: '60px', }} >
                                    <DropDown
                                        onChange={e => { setCurrentPage(1); setSelectedCategory(e) }}
                                        items={categories.filter(x => x.type === 0).sort((x, y) => x.name > y.name)
                                            .concat(categories.filter(x => x.type === 3).sort((x, y) => x.type > y.type))}
                                        selectedValues={selectedCategory}
                                        placeholder={t('All categories')} >
                                    </DropDown>
                                </div>
                            </MDBCol>
                            <MDBCol lg={3} md={6} className="mt-2">
                                <div style={{ height: '60px', }} >
                                    <DropDown
                                        onChange={e => { setCurrentPage(1); setAdTypeFilter(e) }}
                                        items={[{ id: 0, name: t('Teaser') },
                                        { id: 2, name: t('Advertisement logo') },
                                        { id: 1, name: t('Subtitle') },
                                        { id: 3, name: t('Ad reporting') },
                                        { id: 4, name: t('Program presentation') }]}
                                        selectedValues={adTypeFilter}
                                        placeholder={t('Ad type')} >
                                    </DropDown>
                                </div>
                            </MDBCol>
                            <MDBCol lg={3} md={4} className="mt-2">
                                <input
                                    className='text-search'
                                    type='text' placeholder={t('Your text') + ' ...'} value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
                            </MDBCol>
                            <MDBCol lg={3} md={4} className="mt-2">
                                <input
                                    className='text-search'
                                    type='text' placeholder={t('Ad code')} value={codeFilter} onChange={e => setCodeFilter(e.target.value)} />
                            </MDBCol>
                            <MDBCol lg={3} md={4} className='d-md-none d-lg-block mt-2'></MDBCol>
                            <MDBCol lg={3} md={4} className="mt-2">
                                <button className='do-search' type='button' onClick={(e) => { doSearch() }}
                                    style={isRtl() ?
                                        {
                                            float: 'left', width: '60px', height: '60px'
                                        } :
                                        {
                                            float: 'right', width: '60px', height: '60px'
                                        }}>
                                    <img alt="img" src={require('../../assets/img/search.png')} style={{ width: '20px', height: '20px', }} />
                                </button>
                            </MDBCol>

                        </MDBRow>
                    </form>
                </MDBCol>
            </MDBRow>
            <MDBRow className='mt-5 mb-3'>
                <MDBCol md='6'>
                    <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold' }}>{t('All videos')}</h5>
                </MDBCol>
                <MDBCol md='6' className='mb-3'>
                    {
                        [1, 2, 3].indexOf(user.role) > -1 &&
                        <button className={'d-flex justify-content-between ' + (!isRtl() ? 'float-right' : 'float-left')}
                            style={!isRtl() ?
                                {
                                    backgroundColor: '#1458e9 ', color: 'white',
                                    borderRadius: '1rem', border: 'none', padding: '0 20px 0 20px', width: '242px', height: '60px',
                                    alignItems: 'center'
                                } :
                                {
                                    backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                                    borderRadius: '1rem', border: 'none', padding: '0 20px 0 20px', width: '242px', height: '60px',
                                    alignItems: 'center', fontFamily: 'DiodrumArabic', fontSize: '16px'
                                }}
                            onClick={() => modalHandler(-1)}>
                            <span>
                                {t('Add video')}
                            </span>
                            {isRtl() ? <img alt="img" src={require('../../assets/img/plus.png')} style={{ width: '18px', height: '18px', }} />
                                : <img alt="img" src={require('../../assets/img/plus.png')} style={{ width: '18px', height: '18px', }} />}
                        </button>
                    }
                </MDBCol>
                <MDBCol md='12' className='mt-5'>
                    <MDBRow>
                        {videos.map((item, i) => {
                            return <div style={{ width: '23%', margin: '1%', }} key={i} md='3' >
                                <CardVideo onClick={e => modalHandler(item.id)} key={i} id={item.id} name={item.name} />
                            </div>
                        })}
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol center='true' style={{ direction: isRtl() ? 'rtl' : 'ltr' }}>
                    <div className='text-center d-flex flex-row justify-content-center' >
                        <label
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                width: '60px', height: '60px'
                            }}
                            onClick={() => {
                                if (currentPage > 1)
                                    setCurrentPage(currentPage - 1)
                            }}>
                            {isRtl() ? <MDBIcon icon='angle-right' /> : <MDBIcon icon='angle-left' />}
                        </label>
                        <input type='number'
                            onFocus={(e) => { e.target.select() }}
                            style={{
                                height: '60px', width: '5rem', borderRadius: '1rem',
                                display: 'inline', WebkitAppearance: 'none',
                                margin: '0 .3rem', MozAppearance: 'textfield', textAlign: 'center'
                            }}
                            min='1' max={pageCount}
                            value={currentPage} onChange={e => {
                                setCurrentPage(parseInt(e.target.value))
                                if (e.target.value > pageCount && e.target.value > 0)
                                    setCurrentPage(pageCount)
                                else if (e.target.value < 1)
                                    setCurrentPage(1)
                            }} />
                        <label
                            style={isRtl() ?
                                { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'rtl', marginRight: '.2rem', marginLeft: '.2rem' } :
                                { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'ltr', marginRight: '.2rem', marginLeft: '.2rem' }}>
                            {t('from') + ' ' + pageCount}
                        </label>
                        <label
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                width: '60px', height: '60px'
                            }}
                            onClick={() => {
                                if (currentPage < pageCount)
                                    setCurrentPage(currentPage + 1)
                            }}
                        >
                            {isRtl() ? <MDBIcon icon='angle-left' /> : <MDBIcon icon='angle-right' />}
                        </label>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBModal isOpen={showModal} size='lg' toggle={() => {
                if (showModal)
                    setSelectedVideo(-1)
                setShowModal(!showModal)
            }}>
                <MDBModalHeader style={{ textAlign: 'center', border: 'none', fontFamily: 'DiodrumArabic' }}>{t('Add video')}
                    <MDBIcon icon='times' style={{ left: '1rem', position: 'absolute', fontSize: '1rem' }} onClick={() => setShowModal(false)} />
                </MDBModalHeader>
                <MDBModalBody style={{ fontFamily: 'DiodrumArabic' }}>
                    <form>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='6' >
                                    <MDBRow>
                                        <MDBCol md='12' className='mt-2'>
                                            <DropDown multiple={false} placeholder={t('Media type')} selectedValues={mediaType}
                                                disabled={formDisable || selectedVideo !== -1}
                                                onChange={e => setMediaType(e)}
                                                items={[{ name: t('TV'), id: 0 }, { name: t('Radio'), id: 1 }]}>
                                            </DropDown>
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2' >
                                            <DropDown selectedValues={company} onChange={e => setCompany(e)}
                                                disabled={formDisable}
                                                placeholder={t('Choose company')}
                                                items={companies.sort((x, y) => x.name > y.name)}
                                                multiple={false}
                                                hasInsert={false}>
                                            </DropDown>
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2' >
                                            <DropDown selectedValues={brand} onChange={e => setBrand(e)}
                                                disabled={formDisable}
                                                placeholder={t('Choose Brand')}
                                                items={brands.filter(x => x.company.id === company[0]).sort((x, y) => x.name > y.name)}
                                                multiple={false}>
                                            </DropDown>
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <select defaultValue={adType} onChange={e => setAdType(e.target.value)} disabled={formDisable || selectedVideo !== -1}>
                                                <option value=''>{t('Choose type')}</option>
                                                <option value='0'>{t('Teaser')}</option>
                                                {mediaType[0] === 0 && <option value='2'>{t('Advertisement logo')}</option>}
                                                {mediaType[0] === 0 && <option value='1'>{t('Subtitle')}</option>}
                                                {mediaType[0] === 0 && <option value='3'>{t('Ad reporting')}</option>}
                                                <option value='4'>{t('Program presentation')}</option>
                                            </select>
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <DropDown
                                                items={categories.filter(x => x.type === 0).sort((x, y) => x.name > y.name)} // Options to display in the dropdown
                                                selectedValues={category} // Preselected value to persist in dropdown
                                                onChange={e => { setCategory(e) }} // Function will trigger on select event
                                                displayValue="name" // Property name to display in the dropdown options
                                                placeholder={t('Category')}
                                                hasInsert={[1, 2, 3].indexOf(user.role) > -1}
                                                disabled={formDisable}
                                                onAdd={e => { addCategory(e, 0) }}
                                            />
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <DropDown
                                                items={categories.filter(x => x.type === 3).sort((x, y) => x.name > y.name)} // Options to display in the dropdown
                                                selectedValues={subcategory} // Preselected value to persist in dropdown
                                                onChange={e => { setSubcategory(e) }} // Function will trigger on select event
                                                displayValue="name" // Property name to display in the dropdown options
                                                placeholder={t('Subcategory')}
                                                hasInsert={[1, 2, 3].indexOf(user.role) > -1}
                                                disabled={formDisable}
                                                onAdd={e => { addCategory(e, 3) }}
                                            />
                                        </MDBCol>
                                        {user?.modules?.find(x => x === 'trend') &&
                                            <MDBCol md='12' className='mt-2'>
                                                <DropDown
                                                    items={categories.filter(x => x.type === 1).sort((x, y) => x.name > y.name)} // Options to display in the dropdown
                                                    selectedValues={megaTrend} // Preselected value to persist in dropdown
                                                    onChange={e => { setMegaTrend(e) }} // Function will trigger on select event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    disabled={formDisable}
                                                    placeholder={t('Mega trend')}
                                                />
                                            </MDBCol>
                                        }
                                        {user?.modules?.find(x => x === 'trend') &&
                                            <MDBCol md='12' className='mt-2'>
                                                <DropDown
                                                    items={categories.filter(x => x.type === 2).sort((x, y) => x.name > y.name)} // Options to display in the dropdown
                                                    selectedValues={subTrend} // Preselected value to persist in dropdown
                                                    onChange={e => { setSubTrend(e) }} // Function will trigger on select event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    disabled={formDisable}
                                                    placeholder={t('Sub trend')}
                                                />
                                            </MDBCol>
                                        }
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                style={{ height: '58px' }}
                                                value={code}
                                                placeholder={t('Code')}
                                                disabled={formDisable}
                                                onChange={e => {
                                                    if (isNaN(e.target.value))
                                                        setCode('')
                                                    else
                                                        setCode(e.target.value)

                                                }} />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md='6'>
                                    <MDBRow>
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text'
                                                disabled={formDisable}
                                                style={{ height: '58px' }}
                                                defaultValue={nameInsert} placeholder={t('Name')} onChange={e => setNameInsert(e.target.value)} />
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <MDBRow>
                                                <MDBCol md='6'>
                                                    <DatePicker
                                                        locale={getTimeMode()}
                                                        value={dateFrom}
                                                        inputPlaceholder={t('Date from')}
                                                        shouldHighlightWeekends
                                                        calendarClassName='responsive-calendar'
                                                        minimumDate={convertToHijri()}
                                                        onChange={e => { !formDisable && setDateFrom(e) }}
                                                    />
                                                </MDBCol>
                                                <MDBCol md='6' className='mt-2 mt-md-0'>
                                                    <DatePicker
                                                        locale={getTimeMode()}
                                                        value={dateTo}
                                                        inputPlaceholder={t('Date to')}
                                                        shouldHighlightWeekends
                                                        calendarClassName='responsive-calendar'
                                                        minimumDate={dateFrom ? dateFrom : convertToHijri()}
                                                        onChange={e => { !formDisable && setDateTo(e) }}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            {
                                                selectedVideo === -1 ?
                                                    <FileUpload id='file' onChange={e => fileHandler(e)} icon={<img alt="img" src={require('../../assets/img/Browse.png')} />}
                                                        label={mediaType[0] === 0 ? t('Upload video') + '...' : 'باگزاری صوت...'} />
                                                    :
                                                    video === 'loading' ?
                                                        <div style={{ width: '100%' }}>
                                                            <MDBIcon icon="spinner" pulse size="3x" fixed
                                                                style={{ display: 'block', margin: '0 auto', lineHeight: '1.25em', textAlign: 'center' }} /></div> :
                                                        <video autoPlay style={{ maxHeight: '200px' }} controls src={video}></video>
                                            }
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <input type='text' defaultValue={description} value={description}
                                                style={{ height: '58px' }}
                                                disabled={formDisable}
                                                onChange={e => setDescription(e.target.value)} placeholder={t('Description about video')} />
                                        </MDBCol>
                                        <MDBCol md='12' className='mt-2'>
                                            <MDBRow>
                                                <MDBCol md='6' >
                                                    {formDisable ? t(enabled ? 'Active' : 'Inactive') :
                                                        <ToggleSwitch id='active' Name='active' dir={isRtl() ? 'rtl' : 'ltr'}
                                                            onChange={e => { if (!formDisable) setEnabled(e) }}
                                                            active={enabled} label={t('Active') + ' '}
                                                        />}
                                                </MDBCol>
                                                {!formDisable && selectedVideo !== -1 &&
                                                    <MDBCol md='6' className='delete-video' style={{ textAlign: 'center', lineHeight: '41px' }}
                                                        onClick={() => { deleteVideo(selectedVideo) }}>
                                                        {t('Delete') + ' '}
                                                        <img alt="img" src={require('../../assets/img/trash.png')} style={{ color: 'red' }} />
                                                    </MDBCol>
                                                }
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                <MDBCol md='12' className='mt-4'>
                                    <button type='button' onClick={() => setShowModal(false)}
                                        style={isRtl() ?
                                            {
                                                color: '#8D98A9', borderRadius: '1rem', border: 'none',
                                                padding: '.5rem', float: 'left', height: '60px', width: '161px', backgroundColor: '#F3F5F9'
                                            } : {
                                                color: '#8D98A9', borderRadius: '1rem', border: 'none',
                                                padding: '.5rem', float: 'right', height: '60px', width: '161px', backgroundColor: '#F3F5F9'
                                            }}>{t('Cancel')}</button>
                                    {
                                        [1, 2, 3].indexOf(user.role) > -1 &&

                                        <button type='button'
                                            disabled={uploadPercentage !== 0}
                                            style={isRtl() ?
                                                {
                                                    backgroundColor: '#1458e9 ', color: 'white', marginLeft: '2rem',
                                                    borderRadius: '1rem', border: 'none', padding: '.5rem', float: 'left',
                                                    width: '200px', height: '60px'
                                                } : {
                                                    backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                                                    borderRadius: '1rem', border: 'none', padding: '.5rem', float: 'right',
                                                    width: '200px', height: '60px'
                                                }}
                                            onClick={e => { addMovie() }}>
                                            {uploadPercentage !== 0 ? ' ' + t('Uploading') + ' ' + uploadPercentage + '%' : t('Save')}
                                        </button>
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </div >
    )
}
function CardVideo(props) {
    const [isLoading, setIsLoading] = useState(true)
    const defaultImage = require('../../assets/img/video-thumbnail-large.png')
    const [image, setImage] = useState(null)
    useEffect(() => {

        var xhr = new XMLHttpRequest();
        let temp = "";
        xhr.onload = function () {
            if (xhr.status === 200) {
                temp = xhr.response
                setImage(URL.createObjectURL(temp));
                setIsLoading(false)
            }
            else {
                setImage(defaultImage)
                setIsLoading(false)
            }
        };

        xhr.open('GET', `${BASE_URL}/ad/${props.id}/image`);
        xhr.setRequestHeader('Authorization', `Bearer  ${localStorage.getItem('accessToken')}`)
        // xhr.onreadystatechange = function () { // Call a function when the state changes.
        //     if (this.readyState !== XMLHttpRequest.DONE || this.status !== 200) {
        //         setImage(require('../../assets/img/video-thumbnail-large.png'));
        //     }
        // }
        // xhr.onerror = (er) => {
        //     //console.info(er)
        //     setImage(require('../../assets/img/video-thumbnail-large.png'));
        // }
        xhr.responseType = 'blob';
        xhr.send();
        return () => {
            URL.revokeObjectURL(temp)
        }
    }, [props.id, isLoading])

    return (
        <div className='card-video' onClick={e => { props.onClick(e) }} style={{ width: '100%', fontFamily: 'DiodrumArabic' }}>
            <div className='img-container ' >
                {
                    isLoading ?
                        <img alt="loading" className='img' src={defaultImage} key={props.id} /> :
                        <img alt="img" className='img' src={image} key={props.id} />
                }
            </div>
            <p style={{ marginTop: '18px', textAlign: 'center', lineHeight: '20px' }}>{props.name}</p>
        </div>
    )
}