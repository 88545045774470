import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import RoutesWithNavigation from './components/RoutesWithNavigation';
import Login from './components/Login/Login';
import Map from './components/Map'
import { isRtl, Language } from './config';
import { useUserValue, useActions } from './features/login';
import { ToastContainer } from 'mdbreact';
import * as api from './api'

export default function Main() {

    const { isLogin, user } = useUserValue();
    const { setLogin } = useActions();
    const [resultComponent, setResultComponent] = useState(<Switch></Switch>);

    useEffect(() => {
        if (localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === ''
            || localStorage.getItem('refreshToken') === null || localStorage.getItem('refreshToken') === '' ||
            isLogin === false || user === null) {
            setResultComponent(
                <Switch>
                    {user?.modules?.find(x => x === 'trend') && <Route path='/Map' exact component={Map} />}
                    <Route path='/*' exact component={Login} />
                </Switch>
            );
        }
        else {
            if (localStorage.getItem("refreshToken") != null) {
                api.refresh()
                    .then(async function ({ data, error }) {
                        if (data) {
                            localStorage.setItem('accessToken', data.access_token)
                            const { data: dataAccount, error: errorAccount } = await api.account(data.access_token)
                            if (dataAccount) {
                                setLogin({ user: { ...dataAccount, }, isLogin: true })
                                setResultComponent(
                                    <Switch >
                                        <RoutesWithNavigation />
                                    </Switch>
                                );
                            }
                            else {
                                setResultComponent(
                                    <Switch>
                                        <Route path='/*' exact component={Login} />
                                    </Switch>
                                );
                            }
                        }

                    })

            }

        }
        return () => {

        }
    }, [isLogin])


    return <>
        <div className={isRtl() ? 'rtl' : 'ltr'}>
            <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={5000}
            />
        </div>
        {resultComponent}
    </>;

}




