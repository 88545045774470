import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {
    MDBModal, MDBContainer, MDBRow, MDBCol, MDBIcon,
    MDBModalHeader, MDBModalBody,
    MDBTable, MDBTableHead, MDBTableBody, toast, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBadge
} from 'mdbreact';
import { isRtl, Language } from '../../config'
import { useForm } from "react-hook-form";
import { useUserValue } from '../../features/login';
import DropDown from '../../assets/DropDown';
import * as api from '../../api';

export default function Users() {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [selected, setSelected] = useState(-1)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showManageModal, setShowManageModal] = useState(false)
    const [user, setUser] = useState(undefined)
    const [companies, setCompanies] = useState([])
    const [brands, setBrands] = useState([])
    const [users, setUsers] = useState([])
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const { register, watch, handleSubmit, reset, formState: { errors } } = useForm();
    const { user: currentUser } = useUserValue();
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])

    const deleteModalHandler = (e) => {
        setSelected(e)
        setShowDeleteModal(true)
    }
    const manageModalHandler = (e) => {
        setUser(undefined)
        setSelected(e);
        setShowManageModal(true)
    }
    useEffect(() => {
        getUsers();
        getCompanies()
        return () => {

        }
    }, [])
    useEffect(() => {
        if (!showManageModal) {
            setUser(undefined)
            reset({});
        }
        else {
            if (user) {
                setSelectedCompanies(user?.brands.map(x => x.company.id))
                setTimeout(() => {
                    setSelectedBrands(user?.brands.map(x => x.id))
                }, 100);
            }
        }
        return () => {

        }
    }, [showManageModal])

    useEffect(() => {
        setSelectedBrands(brands.filter(x => selectedBrands.indexOf(x.id) > -1 && selectedCompanies.indexOf(x.company.id) > -1).map(x => x.id))
        return () => {

        }
    }, [selectedCompanies])

    const getCompanies = async () => {

        {
            const { data, error } = await api.getCompanies()
            if (data) {
                setCompanies(data)
            }
        }

        {
            const { data, error } = await api.getBrands()
            if (data) {
                setBrands(data.sort((x, y) => {
                    if (x.name < y.name) { return -1; }
                    if (x.name > y.name) { return 1; }
                }))
            }
        }

    }
    const getUsers = async () => {

        const { data, error } = await api.getUsers();
        if (data) {
            setUsers(data);
            if (data.brand_ids)
                setSelectedBrands(data.brand_ids)
        }

    }
    const deleteUser = async (id) => {
        const { data, error } = await api.deleteUser(id)

        if (data) {
            setShowDeleteModal(false)
            getUsers();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }

    }

    const addUser = async (formData) => {
        console.info(formData)
        //var FormData = require('form-data');
        const data = new FormData();
        if (selected === -1) {
            //return;
            data.append('username', formData.username);
            if (formData.email !== '')
                data.append('email', formData.email);
            data.append('first_name', formData.first_name);
            data.append('last_name', formData.last_name);
            data.append('role', formData.role !== undefined && formData.role);
            data.append('phone', formData.phone);
            if (selectedBrands && selectedBrands.length > 0)
                data.append('brand_ids', '[' + selectedBrands.join(',') + ']');
            data.append('password', formData.password);

            const { data: response, error } = await api.addUser(data);
            if (response) {
                setShowManageModal(false)
                getUsers();
                setSelectedCompanies([])
                setSelectedBrands([])
                reset({})
                toast.success(t('The operation was successful'), {
                    closeButton: false
                });
            }

        }
        else {
            data.append('username', formData?.username);
            if (selectedBrands && selectedBrands.length > 0)
                data.append('brand_ids', '[' + selectedBrands.join(',') + ']');
            data.append('role', formData.role !== undefined && formData.role);
            data.append('first_name', formData.first_name);
            data.append('last_name', formData.last_name);
            data.append('email', formData.email);
            data.append('phone', formData.phone);
            if (formData.password && formData.password !== '')
                data.append('password', formData.password);
            const { data: response, error } = await api.editUser({ fd: data, id: selected });
            if (response) {
                getUsers();
                setSelectedCompanies([])
                setSelectedBrands([])
                reset({})
                setShowManageModal(false)
                toast.success(t('The operation was successful'), {
                    closeButton: false
                });
            }
        }
    }
    return (
        <MDBContainer fluid className={Language}>
            <MDBRow className='mt-5 mb-5'>
                <MDBCol md='6'>
                    <h5>{t('All users')}</h5>
                </MDBCol>
                <MDBCol md='6'>
                    <button style={!isRtl() ?
                        {
                            right: '2rem', position: 'absolute', backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                            borderRadius: '1rem', border: 'none', padding: '1rem', width: '240px', height: '60px',
                            display: 'flex', alignItems: 'center'
                        } :
                        {
                            left: '2rem', position: 'absolute', backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                            borderRadius: '1rem', border: 'none', padding: '1rem', width: '240px', height: '60px',
                            display: 'flex', alignItems: 'center',
                        }}
                        onClick={() => { setUser({}); reset({}); manageModalHandler(-1) }}>
                        {t('Create new user')}
                        {isRtl() ? <img alt="img" src={require('../../assets/img/plus.png')} style={{ color: '#ffc212', left: '1rem', width: '16px', position: 'absolute' }} />
                            : <img alt="img" src={require('../../assets/img/plus.png')} icon='plus' style={{ color: '#ffc212', right: '1rem', width: '16px', position: 'absolute' }} />}
                    </button>
                </MDBCol>

            </MDBRow>
            <hr />
            <MDBRow>
                <MDBCol md='12'>
                    <MDBTable responsive>
                        <MDBTableHead>
                            <tr className='row'>
                                <th className='col-1' ></th>
                                <th className='col-2'>{t('Username')}</th>
                                <th className='col-2'>{t('First Name')}</th>
                                <th className='col-2'>{t('Last name')}</th>
                                <th className='col-2'>{t('Brands name')}</th>
                                <th className='col-2'>{t('Role')}</th>
                                <th className='col-1'></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <MDBContainer fluid className='users-list'>
                                {
                                    users?.map((item, i) => {
                                        return (
                                            <tr key={i} className='row' style={{ alignItems: 'center', borderTop: '1px solid #CCC' }}>
                                                <td className='col-1' style={{ verticalAlign: 'middle' }}><img alt="img" src={require('../../assets/img/userIcon.png')} style={{ width: '44px', height: '44px' }} /></td>
                                                <td className='col-2' style={{ verticalAlign: 'middle' }}>{item.username}</td>
                                                <td className='col-2' style={{ verticalAlign: 'middle' }}>{item.first_name}</td>
                                                <td className='col-2' style={{ verticalAlign: 'middle' }}>{item.last_name}</td>
                                                <td className='col-2' style={{ verticalAlign: 'middle' }}>{item.brands.map(x => <MDBBadge className='m-1' style={{ fontSize: '10pt' }} color='blue'>{x.name}</MDBBadge>)}</td>
                                                <td className='col-2' style={{ verticalAlign: 'middle' }}>
                                                    {
                                                        item.role === 1 ? 'Admin' :
                                                            item.role === 2 ? 'Supervisor' :
                                                                item.role === 3 ? 'Operator' : 'Customer'}</td>
                                                <td className='col-1' style={{ verticalAlign: 'middle' }}>
                                                    <MDBDropdown className="more-option">
                                                        <MDBDropdownToggle color="indigo" className="m-0 rounded-0">
                                                            <MDBIcon icon='ellipsis-v' />
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu basic color="indigo">
                                                            <MDBDropdownItem style={{ direction: 'rtl' }}
                                                                onClick={() => {
                                                                    setSelected(item.id);
                                                                    setSelectedBrands([])
                                                                    setSelectedCompanies([])
                                                                    setUser(item);
                                                                    setShowManageModal(true);
                                                                    reset(item);

                                                                }}>
                                                                <img alt="img" src={require('../../assets/img/edit.png')} className='d-inline'
                                                                    style={{
                                                                        cursor: 'pointer', marginLeft: '.5rem', marginRight: '.5rem', borderRadius: '.3rem',
                                                                        height: '16px', width: '16px'
                                                                    }} />
                                                                {t('Edit')}
                                                            </MDBDropdownItem>
                                                            {(item.id !== currentUser.id) &&
                                                                <MDBDropdownItem onClick={() => deleteModalHandler(item.id)}>
                                                                    <img alt="img" src={require('../../assets/img/trash.png')} className='d-inline'
                                                                        style={{
                                                                            cursor: 'pointer', marginLeft: '.5rem', marginRight: '.5rem', borderRadius: '.3rem',
                                                                            height: '16px', width: '16px'
                                                                        }} />
                                                                    {t('Delete')}
                                                                </MDBDropdownItem>
                                                            }
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </MDBContainer>
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>

            </MDBRow>
            <MDBModal isOpen={showDeleteModal} size='md' toggle={() => {
                if (showDeleteModal)
                    setSelected(-1)
                setShowDeleteModal(!showDeleteModal)
            }}>
                <MDBModalHeader style={{ textAlign: 'center', border: 'none' }}>{t('Delete user')}</MDBModalHeader>
                <MDBModalBody className=''>
                    <h6><MDBIcon icon='exclamation-circle' /> {t('Warning! Do You want to continue?')}</h6>
                    <br />
                    <div className='text-center'>
                        <button className='mx-2'
                            style={{ border: 'none', borderRadius: '.5rem', color: 'white', width: '7rem', height: '2.5rem', backgroundColor: '#2A57E0' }}
                            onClick={() => { deleteUser(selected) }}
                        >{t('Yes')}</button>
                        <button className='mx-2'
                            style={{ border: 'none', borderRadius: '.5rem', width: '7rem', height: '2.5rem', color: 'black' }}
                            onClick={() => { setShowDeleteModal(false); setSelected(-1) }}
                        >{t('No')}</button>
                    </div>
                </MDBModalBody>
            </MDBModal>
            <MDBModal isOpen={showManageModal} size='lg' toggle={() => {
                if (showManageModal)
                    setSelected(-1)
                setShowManageModal(!showManageModal)
            }}>
                <MDBModalHeader style={{ textAlign: 'center', border: 'none' }}>{t('Create new user')}</MDBModalHeader>
                <MDBModalBody className='manage-user-modal'>
                    <form onSubmit={handleSubmit(addUser)}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='12' className='mb-2' >
                                    <h5>{t('Profile')}</h5>
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <input type='text'
                                        {...register("username", { required: true })} defaultValue={user?.username} placeholder={t('User name')} onChange={e => setUser({ ...user, username: e.target.value })} />
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <input type='text'
                                        {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, })}
                                        defaultValue={user ? user?.email : ''} placeholder={t('Email')} onChange={e => setUser({ ...user, email: e.target.value })} />
                                    {errors.email && errors.email.type === 'required' && <p className='mt-1 alert alert-danger'>ایمیل وارد نشده است</p>}
                                    {errors.email && errors.email.type === 'pattern' && <p className='mt-1 alert alert-danger'>ایمیل درست وارد نشده است</p>}
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <input type='text' {...register("first_name")}
                                        defaultValue={user?.first_name} placeholder={t('First name')} onChange={e => setUser({ ...user, first_name: e.target.value })} />
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <input type='text' {...register("last_name")}
                                        defaultValue={user?.last_name} placeholder={t('Last name')} onChange={e => setUser({ ...user, last_name: e.target.value })} />
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <select defaultValue={user?.role}
                                        name='role'

                                        {...register("role", {
                                            onChange: e => { setSelectedBrands([]); setSelectedCompanies([]) }
                                        })}>
                                        <option value='3'>اپراتور</option>
                                        <option value='4'>مشتری</option>
                                        <option value='2'>سوپروایزر</option>
                                        <option value='1'>ادمین</option>
                                    </select>
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <input type='text' {...register("phone", { required: true, pattern: /^[0][0-9]{10}$/ })}
                                        defaultValue={user?.phone} placeholder={t('Tel')} onChange={e => setUser({ ...user, phone: e.target.value })} />
                                    {errors.phone && errors.phone.type === 'pattern' && <p className='mt-1 alert alert-danger'>تلفن به درستی وارد نشده است</p>}
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <DropDown
                                        disabled={watch('role') === '1'}
                                        multiple={true}
                                        items={companies?.sort((x, y) => x.name > y.name)} placeholder={t('All companies')}
                                        selectedValues={selectedCompanies}
                                        onChange={e => {
                                            setSelectedCompanies(e)
                                        }} >
                                    </DropDown>
                                </MDBCol>
                                <MDBCol md='6' className='mb-2'>
                                    <DropDown
                                        disabled={watch('role') === '1'}
                                        multiple={true}
                                        items={
                                            brands
                                                .filter(x => selectedCompanies.length > 0 && selectedCompanies.includes(x.company.id))
                                        }
                                        placeholder={t(selectedCompanies.length === 0 ? 'All brands' : 'Choose brands')}
                                        selectedValues={selectedBrands}
                                        onChange={e => setSelectedBrands(e)} >

                                    </DropDown>
                                    {selectedCompanies.length > 0 && selectedBrands.length === 0 && <p className='mt-1 alert alert-danger'>برند شرکت انتخاب نشده است</p>}
                                </MDBCol>
                                {
                                    (currentUser.role === 1 || selected === -1) &&
                                    <>
                                        <MDBCol md='6' className='mb-2' style={{}}>
                                            <div className='position-relative'>
                                                <input
                                                    {...register("password", { required: selected === -1 })}
                                                    value={user?.password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder={t('Password')}
                                                    onChange={e => setUser({ ...user, password: e.target.value })} />
                                                <MDBIcon icon='eye'
                                                    style={isRtl() ? {
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        left: '1.5rem',
                                                        top: '33%',
                                                        cursor: 'pointer'
                                                    } : {
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        right: '1.5rem',
                                                        top: '33%',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setShowPassword(!showPassword)} />
                                            </div>
                                            {errors.password && errors.password.type === 'required' && <p className='mt-1 alert alert-danger'>رمز عبور وارده نشده است</p>}
                                        </MDBCol>
                                        <MDBCol md='6' className='mb-2' style={{}}>
                                            <div className='position-relative'>
                                                <input
                                                    {...register("repassword", {
                                                        validate: value => value === watch('password'),
                                                        required: selected === -1
                                                    })}
                                                    value={user?.repassword}
                                                    type={showRePassword ? 'text' : 'password'} placeholder={t('Repeat password')} onChange={e => setUser({ ...user, repassword: e.target.value })} />
                                                <MDBIcon icon='eye'
                                                    style={isRtl() ? {
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        left: '1.5rem',
                                                        top: '33%',
                                                        cursor: 'pointer'
                                                    } : {
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        right: '1.5rem',
                                                        top: '33%',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setShowRePassword(!showRePassword)} />
                                            </div>
                                            {errors.repassword && errors.repassword.type === 'required' && <p className='mt-1 alert alert-danger'>تکرار رمز عبور وارده نشده است</p>}
                                            {errors.repassword && errors.repassword.type === 'validate' && <p className='mt-1 alert alert-danger'>تکرار رمز عبور وارده شده با رمز عبور برابر نیست</p>}
                                        </MDBCol>
                                    </>
                                }
                                <MDBCol md='12' className='mt-2 align-items-center justify-content-end d-flex'>
                                    <button type='submit'
                                        style={{ minWidth: '10rem', backgroundColor: '#2957DF', borderRadius: '1rem', border: 'none', height: '3rem', color: 'white' }}
                                    >{t('Save')}</button>
                                    <button type='button' style={{ background: 'none', border: 'none', marginLeft: '3rem', marginRight: '3rem' }}
                                        onClick={(e) => { setShowManageModal(false) }}>{t('Cancel')}</button>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </MDBContainer>
    )
}