import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Videos from './Videos';
import Report from './Report';
import Live from './Live';
import Archive from './Archive';
import Profile from './Profile';
import Notification from './Notification'
import Users from './Users'
import Company from './Company'
import Tag from './Tag'
import Trends from './Trends'
import Map from './Map'
import Chart from './Chart'
import Conductor from './Conductor'
import { useUserValue, useActions } from '../features/login';
import Box from './Box';
import Dashboard from './Dashboard';


export default function Routes(props) {
  const fourtOFour = () => <h1 className="text-center">404</h1>
  const { user } = useUserValue();
  const renderComponent = () => {
    switch (user?.role) {
      case 1://admin
        return <Switch>
          <Route path='/' exact={true} name='root' component={Videos} />
          {user?.modules?.find(x => x === 'trend') && <Route path='/Map' component={Map} />}
          <Route exact={true} path='/Report' component={Report} />
          <Route exact={true} path='/Chart' component={Chart} />
          <Route exact={true} path='/Profile' component={Profile} />
          <Route exact={true} path='/Live' component={Live} />
          <Route exact={true} path='/Archive' component={Archive} />
          <Route exact={true} path='/Users' component={Users} />
          <Route exact={true} path='/Conductor' component={Conductor} />
          <Route exact={true} path='/Companies' component={Company} />
          <Route exact={true} path='/Categories' component={Tag} />
          <Route exact={true} path='/Trends' component={Trends} />
          <Route exact={true} path='/box' component={Box} />
          {/* <Route path='/dashboard' component={Dashboard} /> */}
          <Route component={fourtOFour} />
        </Switch>
      case 2://supervisor
        return <Switch>
          <Route path='/' exact={true} name='root' component={Videos} />
          <Route path='/Map' component={Map} />
          <Route path='/Report' component={Report} />
          <Route path='/Chart' component={Chart} />
          <Route path='/Profile' component={Profile} />
          <Route path='/Live' component={Live} />
          <Route path='/Archive' component={Archive} />
          <Route path='/Conductor' component={Conductor} />
          <Route path='/Companies' component={Company} />
          <Route path='/Categories' component={Tag} />
          <Route path='/Trends' component={Trends} />
          <Route path='/box' component={Box} />
          <Route component={fourtOFour} />
        </Switch>
      case 3://operator
        return <Switch>
          <Route path='/' exact={true} name='root' component={Videos} />
          <Route path='/Report' component={Report} />
          <Route path='/Profile' component={Profile} />
          <Route path='/Live' component={Live} />
          <Route path='/Archive' component={Archive} />
          <Route path='/Conductor' component={Conductor} />
          <Route path='/Companies' component={Company} />
          <Route path='/Categories' component={Tag} />
          <Route path='/Trends' component={Trends} />
          <Route path='/box' component={Box} />
          <Route component={fourtOFour} />
        </Switch>
      case 4://customer
        return <Switch>
          <Route path='/' exact={true} name='root' component={Videos} />
          <Route path='/Report' component={Report} />
          <Route path='/Chart' component={Chart} />
          <Route path='/Profile' component={Profile} />
          <Route component={fourtOFour} />
        </Switch>
      default:
        return <Switch>
           <Route path='/Map' exact component={Map} />
          <Route component={fourtOFour} />
        </Switch>
    }
  }
  return (
    renderComponent()
  );

}

