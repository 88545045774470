import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '../src/config';
import Translation  from './components/Translation.local';
i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        resources: {
            ...Translation,
        },
        lng: Language, // default language
        fallbackLng: Language,
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });
export default i18n;