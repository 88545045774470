import React, { Component } from 'react';
import SideNavigation from './SideNavigation';
import TopNavigation from './TopNavigation';
import Copyrights from './Footer';
import Routes from './Routes';
import { isRtl, Language } from '../config';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: '',
      sideNavToggled: false,
      breakWidth: 1024
    };
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.assessLocation(this.props.location.pathname);
    }
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.assessLocation(this.props.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  toggleSideNav = () => {
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled
      });
    }
  };

  assessLocation = location => {
    let locationString;

    this.setState({
      currentPage: locationString
    });
  };

  render() {
    const dynamicPadding = isRtl() ? {
      paddingRight:
        this.state.windowWidth > this.state.breakWidth ? '21.7%' : '0'
    } : {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? '21.7%' : '0'
    };

    return (
      <div className={`${isRtl() ? 'rtl' : 'ltr'} app w-100`}>
        <div className='' style={{ transition: 'all .3s', padding: '10rem !important' }}>
          <SideNavigation
            breakWidth={this.state.breakWidth}
            style={{ transition: 'all .3s', }}
            triggerOpening={this.state.sideNavToggled}
            onLinkClick={() => this.toggleSideNav()}
          />
        </div>
        <div className=''>
          <TopNavigation
            toggle={this.state.windowWidth < this.state.breakWidth}
            onSideNavToggleClick={this.toggleSideNav}
            routeName={this.state.currentPage}
            onLinkClick={() => this.toggleSideNav()}
          />
          <main style={{ ...dynamicPadding, margin: '100px 75px 6rem' }}>
            <Routes onChange={() => this.assessLocation()} />
          </main>
          <Copyrights
            style={{ ...dynamicPadding, position: 'fixed', width: '100%' }}
            className='d-none d-lg-block'
          />
        </div>
      </div>
    );
  }
}

export default App;
