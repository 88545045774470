import React, { useState, useEffect } from 'react';
import { BASE_URL, convertTimestap, getTimeMode, isRtl, Language } from '../../config';
import { MDBCol, MDBContainer, MDBRow, MDBTimePicker, toast } from 'mdbreact';
import DatePicker from "react-modern-calendar-datepicker";
import { useTranslation } from 'react-i18next';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import ClapprPlayer from "../../assets/clapper";
import './index.scss';
import ChannelCard from '../../assets/ChannelCard';
import * as api from '../../api';

export default function Archive(props) {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [channelList, setChannelList] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [startTime, setStartTime] = useState(-1)
    const [endTime, setEndTime] = useState(-1);
    const [channelId, setChannelId] = useState(-1)
    const [streamUrl, setStreamUrl] = useState('')
    const [autoPlay, setAutoPlay] = useState(false)
    useEffect(() => {
        getChannel();
        return () => {

        }
    }, [])
    const getChannel = async () => {
        const { data, error } = await api.getChannels()
        if (data) {
            setChannelList(data.filter(x => x.media_type === 0).sort((x, y) => x.id > y.id))
        }
    }

    const validation = () => {
        if (channelId === -1) {
            toast.error(t('Channel not selected'), {
                closeButton: false
            });
            return false;
        }
        else if (startDate === '' || endDate === '') {
            toast.error(t('No start or end date entered'), {
                closeButton: false
            });
            return false;
        }
        else if (startDate.year + '/' + startDate.month + '/' + startDate.day > endDate.year + '/' + endDate.month + '/' + endDate.day
            || (startDate.year + '/' + startDate.month + '/' + startDate.day === endDate.year + '/' + endDate.month + '/' + endDate.day
                && startTime.hour + ':' + startTime.minute > endTime.hour + ':' + endTime.minute)) {
            toast.error(t('The start date must be before the end date'), {
                closeButton: false
            });
            return false;
        }
        return true;
    }
    return (
        <MDBContainer fluid className='archive'>

            <div className='channel-row' style={{ overflowX: 'auto', whiteSpace: 'nowrap', }}>
                {
                    channelList.map((item, i) => {
                        return (

                            <ChannelCard
                                key={i}
                                className='channel-img-container d-inline-block mx-3'
                                channelName={item.name}
                                channel={item}
                                active={channelId === item.stream_id}
                                onClick={e => {
                                    setStreamUrl('')
                                    setChannelId(item.stream_id)
                                    setAutoPlay(false);
                                }}
                            />
                        )
                    })
                }
            </div>
            <MDBRow className='mt-4 channel-row' style={{ fontFamily: 'DiodrumArabic' }}>
                <MDBCol md='5'>
                    <MDBRow>
                        <MDBCol md='12' className='mb-3'>
                            {t('From (date / time)')}
                        </MDBCol>
                        <MDBCol md='6'>
                            <DatePicker locale={getTimeMode()}
                                value={startDate}
                                inputPlaceholder={t('Date from')}
                                shouldHighlightWeekends
                                onChange={e => {
                                    setStreamUrl('');
                                    setStartDate(e)
                                }}
                                calendarClassName='responsive-calendar' className='w-100'></DatePicker>
                        </MDBCol>
                        <MDBCol md='6' className='mt-md-0 mt-2 mr-0 pr-0'>
                            <MDBTimePicker id="timePickerStart"
                                hoursFormat={24}
                                getValue={e => {
                                    setStreamUrl('');
                                    setStartTime({ hour: e.split(':')[0], minute: e.split(':')[1] })
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md='5'>
                    <MDBRow>
                        <MDBCol md='12' className='mb-3'>
                            {t('To (date / time)')}
                        </MDBCol>
                        <MDBCol md='6'>
                            <DatePicker locale={getTimeMode()}
                                value={endDate}
                                inputPlaceholder={t('Date from')}
                                shouldHighlightWeekends
                                onChange={e => { setStreamUrl(''); setEndDate(e) }}
                                calendarClassName='responsive-calendar' className='w-100'></DatePicker>
                        </MDBCol>
                        <MDBCol md='6' className='mt-md-0 mt-2  mr-0 pr-0'>
                            <MDBTimePicker id="timePickerEnd" style={{ margin: 0 }}
                                hoursFormat={24}
                                getValue={e => {
                                    setStreamUrl('');
                                    setEndTime({ hour: e.split(':')[0], minute: e.split(':')[1] })
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol md='2' style={{ alignItems: 'center', display: 'flex' }}>
                    <button
                        style={{
                            color: '#F5C445', backgroundColor: '#2A57E0', border: 'none', borderRadius: '12px',
                            height: '60px', width: '60px', display: 'flex', alignItems: 'center', position: 'absolute', top: (isRtl() ? '2rem' : '2.5rem')
                        }}
                        onClick={() => {
                            if (!validation())
                                return;
                            setStreamUrl(`${BASE_URL}/archive_${channelId}_${convertTimestap(startDate, startTime)}_${convertTimestap(endDate, endTime)}.m3u8`);
                            setAutoPlay(true);
                        }}
                        type='button'>
                        <img alt="img" src={require('../../assets/img/Play.png')} icon='play' style={{ display: 'block', margin: '0 auto', width: '20px', height: '20px' }} />
                    </button>
                </MDBCol>
            </MDBRow>
            <MDBRow center>
                <MDBCol md='12' sm='12'>
                    {streamUrl !== '' &&
                        <ClapprPlayer
                            style={{ display: 'block', margin: '0 auto' }}
                            id={"video" + Math.floor(Math.random() * 100)}
                            key={channelId}
                            autoPlay={autoPlay}
                            source={streamUrl}
                        />}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )

}