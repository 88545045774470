import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon,
    toast,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
} from 'mdbreact';

import { Language, convertDateStr, convertTimestap, convertDate, convertToHijri, convertToMiladi, isRtl, getTimeMode } from '../../config'
import * as api from '../../api';
import DatePicker from 'react-modern-calendar-datepicker';
import DropDown from '../../assets/DropDown';
import Special from './Special';
import { useUserValue } from '../../features/login';
import Fee from '../common/Fee';

export default function Conductor() {
    const { user } = useUserValue();
    const { t } = useTranslation(['home']); // "home" is namespace
    const [fileInput, setFileInput] = useState(null)
    const [conductors, setConductors] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [channelList, setChannelList] = useState([])
    const [selectedChannel, setSelectedChannel] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedConductor, setSelectedConductor] = useState(null)
    const [mainDate, setMainDate] = useState(null);
    const [showSpecialModal, setShowSpecialModal] = useState(false)

    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    })
    useEffect(() => {
        api.getChannels().then(data => {
            setChannelList(data.data.sort((x, y) => { return x.id - y.id }))
        })

        return () => {

        }
    }, [])



    useEffect(() => {
        getConductors();

    }, [currentPage])


    const setConductor = async e => {
        e.preventDefault();

        const { data, error } = await api.editConductor(selectedConductor)

        if (!!data) {
            setSelectedConductor(null)
            getConductors()
            setShowModal(false)
            toast.success('عملیات با موفقیت صورت گرفت')
        }
        else {
            if (error?.response?.data?.message === 'Incorrect Time') {
                toast.error('زمان انتخاب شده با برنامه قبل یا بعد از آن تداخل دارد')
            }
            else
                toast.error('خطایی در انجام عملیات رخ داده')
        }


    }
    const getConductors = async () => {

        const { data, error } = await api.getConductors({
            ...selectedDayRange,
            currentPage,
            selectedChannel
        })
        if (data) {
            setConductors(data.items);
            setPageCount(data.page_count)
        }


    }
    const deleteConductors = async () => {

        if (!(selectedDayRange.from && selectedDayRange.to)) {
            toast.error('تاریخ شروع یا پایان انتخاب نشده است')
            return;
        }
        else if (selectedChannel.length === 0) {
            toast.error('هیچ کانالی انتخاب نشده است')
            return;
        }
        else if (selectedChannel.length > 1) {
            toast.error('فقط امکان حذف کنداکتورهای یک کانال وجود دارد')
            return;
        }
        else {
            var from = convertToMiladi(selectedDayRange.from)
            var to = convertToMiladi(selectedDayRange.to)
            if (!(from.year == to.year && from.month === to.month && from.day === to.day)) {
                toast.error('برای حذف، ابتدا و انتهای بازه باید یک روز باشد')
                return;
            }
        }
        if (!window.confirm('آیا از حذف رکوردهای انتخابی مطمئنید؟')) {
            return
        }

        const { data, error } = await api.deleteConductors({
            ...selectedDayRange,
            selectedChannel
        })
        if (data) {
            toast.success(`تعداد ${data.num_deleted} رکورد با موفقیت حذف شد`)
            setCurrentPage(1)
        }

    }
    const addConductor = async () => {
        if (fileInput === null)
            return;

        var fd = new FormData();
        fd.append('file', fileInput);
        const { data, error } = await api.addConductor(fd)

        if (data) {
            setFileInput(null)
            getConductors();
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response?.data?.message?.indexOf('duplicate') > -1) {
                toast.error(t('The company name is duplicate'), {
                    closeButton: false
                });
            }
        }

    }
    const getChannelName = (id) => {
        let a = channelList.filter(x => x.id === id);
        if (a.length > 0)
            return a[0].name
        return id
    }




    return (
        <>
            <MDBContainer fluid className={`${Language} font-diodrum`} >
                <MDBRow className='mt-5 mb-3'>
                    <MDBCol md='6'>
                        <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Conductor')}</h5>
                    </MDBCol>
                    <MDBCol md='6' style={{ alignItems: 'center', }}>
                        <form onSubmit={e => { e.preventDefault(); addConductor() }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <input type='file' style={{
                                display: 'inline-block', width: '60%', height: '3rem', marginBottom: '0', marginLeft: '1%', padding: '.7rem'
                            }} placeholder='نام شرکت' onChange={e => setFileInput(e.target.files[0])} />
                            <img alt="img" src={require('../../assets/img/plus.png')}
                                onClick={e => addConductor()}
                                style={{ backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginRight: '3%' }} />
                        </form>
                    </MDBCol>

                    <MDBCol md='3' className='mt-3'>
                        <div className="duration-picker"
                            style={{ width: '100%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                            <DatePicker
                                locale={getTimeMode()}
                                value={selectedDayRange}
                                onChange={setSelectedDayRange}
                                shouldHighlightWeekends
                                style={isRtl() ? { float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem' } :
                                    { float: 'right', textAlign: 'right', alignItems: 'center', position: 'absolute', right: '1rem' }} />

                            <MDBIcon icon='times'
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    right: '2rem',
                                    color: 'black',
                                    background: '#F3F5F9',
                                    padding: '1rem',
                                    borderRadius: '1rem',
                                    height: '60px',
                                }}
                                onClick={() => setSelectedDayRange({ from: null, to: null })} />
                        </div>
                    </MDBCol>
                    <MDBCol md='3' className='mt-3'>
                        <DropDown
                            items={channelList.sort((a, b) => a.id > b.id)}
                            placeholder={t('All channels')}
                            selectedValues={selectedChannel}
                            onChange={e => {
                                setCurrentPage(1);
                                setSelectedChannel(e);
                            }} ></DropDown>
                    </MDBCol>
                    {user?.modules?.find(x => x === 'senf') &&
                        <MDBCol md='2' className='mt-3 d-flex justify-content-end font-diodrum'>
                            <button className='do-search-report' type='button'
                                onClick={() => setShowSpecialModal(true)}
                                style={{
                                    width: '100%',
                                    padding: '0',
                                    height: '60px'
                                }}>
                                <span>تنظیمات صنف</span>
                            </button>
                        </MDBCol>
                    }
                    <MDBCol md='2' className='mt-3 d-flex justify-content-end'>
                        <button className='do-search-report w-100'
                            style={!isRtl() ? {
                                display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative'
                            } : {
                                display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                marginLeft: '1%'
                            }}
                            type='button' onClick={(e) => { deleteConductors() }}>
                            <span style={isRtl()?
                                {
                                    float: 'left',
                                    textAlign: 'right', alignItems: 'center',
                                } :
                                {
                                    float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem'
                                }}>

                                {t('Delete items')}
                            </span>
                            {isRtl() ?
                                <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                    style={{ left: '2rem', position: 'absolute', width: '16px', height: '16px' }} />
                                :
                                <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                    style={{ right: '2rem', position: 'absolute', width: '16px', height: '16px' }} />
                            }
                        </button>
                    </MDBCol>
                    <MDBCol md='2' className='mt-3 d-flex justify-content-end'>
                        <button className='do-search-report w-100'
                            style={!isRtl() ? {
                                display: 'flex', alignItems: 'center', color: 'white', height: '60px', position: 'relative'
                            } : {
                                display: 'flex', alignItems: 'center', color: 'white', flexDirection: 'row-reverse', height: '60px', position: 'relative',
                                marginLeft: '1%'
                            }}
                            type='button' onClick={(e) => { getConductors() }}>
                            <span style={isRtl() ?
                                {
                                    float: 'left',
                                    textAlign: 'right', alignItems: 'center',
                                } :
                                {
                                    float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem'
                                }}>

                                {t('Apply filter')}
                            </span>
                            {isRtl() ?
                                <img alt="img" src={require('../../assets/img/arrow-left.png')}
                                    style={{ left: '2rem', position: 'absolute', width: '16px', height: '16px' }} />
                                :
                                <img alt="img" src={require('../../assets/img/arrow-right.png')}
                                    style={{ right: '2rem', position: 'absolute', width: '16px', height: '16px' }} />
                            }
                        </button>
                    </MDBCol>

                </MDBRow>

                <table style={{ borderSpacing: '.5rem', borderCollapse: 'separate' }}>
                    <thead>
                        <tr md='12' className='mt-3'>
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                marginLeft: '0.5%',
                                justifyContent: 'center'
                            }} >{t('Program')}</th>
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                marginLeft: '0.5%',
                                justifyContent: 'center'
                            }} >{t('Channel')}</th>
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                marginLeft: '0.5%',
                                justifyContent: 'center'
                            }} >{t('Fee class') + 1}</th>
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                marginLeft: '0.5%',
                                justifyContent: 'center'
                            }} >{t('Fee class') + 2}</th>
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                marginLeft: '0.5%',
                                justifyContent: 'center'
                            }} >{t('Fee class') + 3}</th>
                            {user?.modules?.find(x => x === 'senf') &&
                                <th style={{
                                    height: '3rem',
                                    backgroundColor: 'rgb(64, 87, 132)',
                                    color: 'white',
                                    verticalAlign: 'middle',
                                    alignItems: 'center',
                                    borderRadius: '1rem',
                                    marginBottom: '0',
                                    padding: '1rem',
                                    marginLeft: '0.5%',
                                    justifyContent: 'center'
                                }} >{t('Fee class special')}</th>
                            }
                            <th style={{
                                height: '3rem',
                                backgroundColor: 'rgb(64, 87, 132)',
                                color: 'white',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                marginBottom: '0',
                                padding: '1rem',
                                justifyContent: 'center'
                            }} >{t('Play time')}</th>
                            <th style={{ borderRadius: '1rem', height: '3rem', backgroundColor: 'rgb(64, 87, 132)', }}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {conductors.map((item, i) => {
                            return (
                                <tr key={i}>

                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        marginLeft: '0.5%',
                                        justifyContent: 'center'
                                    }} >{item.program}</td>
                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        marginLeft: '0.5%',
                                        justifyContent: 'center'
                                    }} >{getChannelName(item.channel_id)}</td>
                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        marginLeft: '0.5%',
                                        justifyContent: 'center'
                                    }} >{item.group1_fee_class}</td>
                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        marginLeft: '0.5%',
                                        justifyContent: 'center'
                                    }} >{item.group2_fee_class}</td>
                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        marginLeft: '0.5%',
                                        justifyContent: 'center'
                                    }} >{item.group3_fee_class}</td>
                                    {user?.modules?.find(x => x === 'senf') &&
                                        <td style={{
                                            minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                            backgroundColor: '#F3F5F9',
                                            verticalAlign: 'middle',
                                            alignItems: 'center',
                                            borderRadius: '1rem',
                                            marginBottom: '0',
                                            padding: '1rem',
                                            marginLeft: '0.5%',
                                            justifyContent: 'center'
                                        }} >{item.special_fee_class}</td>}
                                    <td style={{
                                        minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                        backgroundColor: '#F3F5F9',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        borderRadius: '1rem',
                                        marginBottom: '0',
                                        padding: '1rem',
                                        direction: 'ltr',
                                        justifyContent: 'center'
                                    }} >{convertDateStr(item.timestamp, true)}</td>
                                    <td
                                        style={{
                                            minHeight: '3rem', height: '100%', lineHeight: '1.5em',
                                            backgroundColor: '#F3F5F9',
                                            verticalAlign: 'middle',
                                            alignItems: 'center',
                                            borderRadius: '1rem',
                                            marginBottom: '0',
                                            padding: '1rem',
                                            direction: 'ltr',
                                            justifyContent: 'center'
                                        }}
                                        onClick={() => {
                                            setMainDate(item.timestamp);
                                            setSelectedConductor(item); setShowModal(true)
                                        }}
                                        className='text-center'>
                                        <MDBIcon

                                            icon='pen-square' size='2x' style={{ color: '#2A57E0', cursor: 'pointer' }} />
                                    </td>

                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>



                <MDBRow className='mt-3'>
                    <MDBCol center='true' style={{ direction: isRtl() ? 'rtl' : 'ltr' }}>
                        <div className='text-center d-flex flex-row justify-content-center' >
                            <label
                                className='d-flex align-items-center justify-content-center'
                                style={{
                                    backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                    width: '60px', height: '60px'
                                }}
                                onClick={() => {
                                    if (currentPage > 1)
                                        setCurrentPage(currentPage - 1)
                                }}>
                                {isRtl() ? <MDBIcon icon='angle-right' /> : <MDBIcon icon='angle-left' />}
                            </label>
                            <input type='number'
                                onFocus={(e) => { e.target.select() }}
                                style={{
                                    height: '60px', width: '5rem', borderRadius: '1rem',
                                    display: 'inline', WebkitAppearance: 'none',
                                    margin: '0 .3rem', MozAppearance: 'textfield', textAlign: 'center'
                                }}
                                min='1' max={pageCount}
                                value={currentPage} onChange={e => {
                                    setCurrentPage(parseInt(e.target.value))
                                    if (e.target.value > pageCount && e.target.value > 0)
                                        setCurrentPage(pageCount)
                                    else if (e.target.value < 1)
                                        setCurrentPage(1)
                                }} />
                            <label
                                style={isRtl() ?
                                    { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'rtl', marginRight: '.2rem', marginLeft: '.2rem' } :
                                    { border: 'rgb(20, 88, 234) 1px solid', padding: '1rem', borderRadius: '1rem', color: 'rgb(20, 88, 234)', direction: 'ltr', marginRight: '.2rem', marginLeft: '.2rem' }}>
                                {t('from') + ' ' + pageCount}
                            </label>
                            <label
                                className='d-flex align-items-center justify-content-center'
                                style={{
                                    backgroundColor: '#1458EA', borderRadius: '1rem', color: 'white', cursor: 'pointer',
                                    width: '60px', height: '60px'
                                }}
                                onClick={() => {
                                    if (currentPage < pageCount)
                                        setCurrentPage(currentPage + 1)
                                }}
                            >
                                {isRtl() ? <MDBIcon icon='angle-left' /> : <MDBIcon icon='angle-right' />}
                            </label>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MDBModal size='xl' isOpen={showSpecialModal} toggle={() => setShowSpecialModal(!showSpecialModal)}>
                <MDBModalHeader toggle={() => setShowSpecialModal(!showSpecialModal)}>
                </MDBModalHeader>
                <MDBModalBody style={{ height: '50vh', overflow: 'auto' }} className='d-flex flex-column'>
                    <Special />
                </MDBModalBody>
            </MDBModal>



            <MDBModal size='lg' isOpen={showModal} toggle={() => setShowModal(!showModal)} className='DiodrumArabic-font' >
                <MDBModalHeader toggle={() => setShowModal(!showModal)}>
                    کنداکتور
                </MDBModalHeader>
                <MDBModalBody style={{ minHeight: '50vh', overflow: 'auto' }}>
                    <form >
                        <MDBRow className='m-2 justify-content-between'>
                            <MDBCol size='6' md='6' className='mt-3' >
                                <label className='DiodrumArabic-font'>{t('Program')}</label>
                                <input type='text' value={selectedConductor?.program}
                                    onChange={async e => {
                                        e.persist();
                                        setSelectedConductor(pre => ({ ...pre, program: e.target.value }))
                                    }}
                                />
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-3'>
                                <p>
                                    <DurationPicker mainDate={mainDate} value={selectedConductor?.timestamp} onChange={async e => {
                                        setSelectedConductor(pre => ({ ...pre, timestamp: e }))
                                    }} />
                                </p>
                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-3' >
                                <label >{t('Fee class') + ' 1'}</label>
                                <Fee
                                    value={selectedConductor?.group1_fee_class}
                                    onChange={async e => {

                                        setSelectedConductor(pre => ({ ...pre, group1_fee_class: e }))
                                    }}
                                    channel={channelList.find(x => x.id === selectedConductor?.channel_id)} />

                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-3' >
                                <label>{t('Fee class') + ' 2'}</label>
                                <Fee
                                    value={selectedConductor?.group2_fee_class}
                                    onChange={async e => {
                                        setSelectedConductor(pre => ({ ...pre, group2_fee_class: e }))
                                    }}
                                    channel={channelList.find(x => x.id === selectedConductor?.channel_id)} />

                            </MDBCol>
                            <MDBCol size='6' md='6' className='mt-3' >
                                <label>{t('Fee class') + ' 3'}</label>
                                <Fee
                                    value={selectedConductor?.group3_fee_class}
                                    onChange={async e => {
                                        setSelectedConductor(pre => ({ ...pre, group3_fee_class: e }))
                                    }}
                                    channel={channelList.find(x => x.id === selectedConductor?.channel_id)} />

                            </MDBCol>
                            {user?.modules?.find(x => x === 'senf') &&
                                <MDBCol size='6' md='6' className='mt-3' >
                                    <label>{t('Fee class special')}</label>
                                    <Fee
                                        special={true}
                                        value={selectedConductor?.special_fee_class}
                                        onChange={async e => {
                                            setSelectedConductor(pre => ({ ...pre, special_fee_class: e }))
                                        }}
                                        channel={channelList.find(x => x.id === selectedConductor?.channel_id)} />

                                </MDBCol>
                            }
                            <MDBCol size='6' md='6' className='mt-3 align-items-end justify-content-end d-flex'>

                                <a
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#2A57E0', height: '3rem', padding: '1rem', borderRadius: '.7rem', marginRight: '3%'
                                    }} onClick={e => {
                                        e.preventDefault();
                                        setConductor(e)
                                    }}>{t('Save')}</a>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalBody>
            </MDBModal>

        </>
    )
}

const DurationPicker = ({ mainDate, value, onChange }) => {

    const dt = convertDate(value ? value : null, true)
    const main = convertDate(mainDate ? mainDate : null, true)

    const getDurationHourOption = () => {
        const arr = []
        if (mainDate) {

            const h = main.hour;
            for (let index = -3; index < 4; index++) {
                arr.push(<option>{(((h + index) % 24) + 24) % 24}</option>)
            }
        }
        return arr
    }
    const getDurationMinuteOption = () => {
        const arr = []
        for (let index = 0; index < 60; index++) {
            arr.push(<option key={index}>{index}</option>)

        }
        return arr
    }
    const minuteChange = e => {
        const m = e.target.value
        onChange(convertTimestap(dt, { ...dt, minute: parseInt(m) }, true))
    }
    const hourChange = e => {
        const h = e.target.value
        onChange(convertTimestap(dt, { ...dt, hour: parseInt(h) }, true))
    }
    return (
        <MDBRow>
            <MDBCol>
                <label>زمان پخش دقیقه</label>
                <select onChange={e => minuteChange(e)} value={dt.minute}>
                    {getDurationMinuteOption()}
                </select>
            </MDBCol>
            <MDBCol>
                <label>زمان پخش ساعت</label>
                <select onChange={e => hourChange(e)} value={dt.hour}>
                    {getDurationHourOption()}
                </select>
            </MDBCol>
        </MDBRow>)
}