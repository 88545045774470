import { MDBCol, MDBContainer, MDBRow, MDBIcon, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, toast } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isRtl, Language } from '../../config';
import { useUserValue, useActions } from '../../features/login';
import './index.css'
import { useForm } from "react-hook-form";
import * as api from '../../api';

export default function Profile() {
    const { t } = useTranslation(['home']); // "home" is namespace
    const { user } = useUserValue();
    const { setLogin } = useActions();
    const [tempUser, setTempUser] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showReNewPassword, setShowReNewPassword] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [reNewPassword, setReNewPassword] = useState('')
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const { register: registerP, setValue: setValueP, handleSubmit: handleSubmitP, formState: { errorsP } } = useForm();
    useEffect(() => {
        setTempUser(user)
        return () => {

        }
    }, [user])
    const editUser = async (formData) => {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('username', formData.username);
        data.append('first_name', formData.first_name);
        data.append('last_name', formData.last_name);
        data.append('email', formData.email);
        data.append('phone', formData.phone);

        const { data: response, error } = await api.editUser({ fd: data, id: user.id })

        if (response) {
            setIsEdit(false)
            setLogin({ user: tempUser, isLogin: true })
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }

    }
    const editPassword = async (formData) => {

        if (formData.newPassword !== formData.reNewPassword) {
            toast.error(t('The new password is not the same as repeating it'), {
                closeButton: false
            });
            return;
        }

        var FormData = require('form-data');
        var data = new FormData();
        data.append('current_password', formData.oldPassword);
        data.append('new_password', formData.reNewPassword);

        const { data: response, error } = await api.editPassword({ fd: data })

        if (response) {
            toast.success(t('The operation was successful'), {
                closeButton: false
            });
        }
        else {
            if (error.response.data.msg === 'Wrong current password!') {
                toast.error(t('The current password is incorrect'), {
                    closeButton: false
                });
            }
            else if (error.response.data.msg === 'New and current password must be different!') {
                toast.error(t('The current password and the new password must be different'), {
                    closeButton: false
                });
            }
        }




    }
    const [activeTab, setActiveTab] = useState('1')
    return (
        <>
            {isEdit ?
                <MDBContainer fluid className={Language}>
                    <MDBRow>
                        <MDBCol md='6' className='mb-5' >
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Profile')}</h5>
                        </MDBCol>
                    </MDBRow>
                    <div className="classic-tabs">
                        <MDBNav classicTabs color="primary"  >
                            <MDBNavItem>
                                <MDBNavLink link to="#" active={activeTab === "1"} onClick={() => setActiveTab("1")}>
                                    <h6>{t('Profile')}</h6>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink link to="#" active={activeTab === "2"} onClick={() => setActiveTab("2")}>
                                    <h6>{t('Password')}</h6>
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>
                        <MDBTabContent
                            className="card"
                            activeItem={activeTab}
                        >
                            <MDBTabPane tabId="1">
                                <form onSubmit={handleSubmit(editUser)}>
                                    <MDBRow>
                                        <MDBCol md='6' className='mb-3'>
                                            <input type='text' defaultValue={tempUser.username}
                                                placeholder={t('User name')} onChange={e => {
                                                    setValue('username', e.target.value)
                                                    setTempUser({ ...tempUser, username: e.target.value })
                                                }}
                                                name='username'
                                                {...register("username", { required: true, })}
                                                style={{ height: '60px' }} />
                                        </MDBCol>
                                        <MDBCol md='6' className='mb-3'>
                                            <input type='text'
                                                {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, })}
                                                defaultValue={tempUser.email}
                                                placeholder={t('Email')}
                                                name='email'
                                                onChange={e => {
                                                    setValue('email', e.target.value)
                                                    setTempUser({ ...tempUser, email: e.target.value })
                                                }}
                                                style={{ height: '60px' }}
                                            />

                                            {errors.email && errors.email.type === 'required' && <p className='mt-1 alert alert-danger'>ایمیل وارد نشده است</p>}
                                            {errors.email && errors.email.type === 'pattern' && <p className='mt-1 alert alert-danger'>ایمیل درست وارد نشده است</p>}
                                        </MDBCol>
                                        <MDBCol md='6' className='mb-3'>
                                            <input type='text' defaultValue={tempUser.first_name}
                                                name='first_name'
                                                placeholder={t('First name')} onChange={e => {
                                                    setValue('first_name', e.target.value)
                                                    setTempUser({ ...tempUser, first_name: e.target.value })
                                                }}
                                                style={{ height: '60px' }}
                                                {...register("first_name", { required: true })} />

                                        </MDBCol>
                                        <MDBCol md='6' className='mb-3'>
                                            <input type='text' defaultValue={tempUser.last_name} placeholder={t('Last name')}
                                                name='last_name'
                                                onChange={e => {
                                                    setValue('last_name', e.target.value)
                                                    setTempUser({ ...tempUser, last_name: e.target.value })
                                                }}
                                                style={{ height: '60px' }}
                                                {...register("last_name", { required: true, })} />
                                        </MDBCol>
                                        <MDBCol md='6' className='mb-3'>
                                            <input type='text' defaultValue={tempUser.phone}
                                                name='phone'
                                                placeholder={t('Tel')} onChange={e => {
                                                    setValue('phone', e.target.value)
                                                    setTempUser({ ...tempUser, phone: e.target.value })
                                                }}
                                                style={{ height: '60px' }}
                                                {...register("phone", { required: true, pattern: /^[0][0-9]{10}$/ })} />
                                            {errors.phone && errors.phone.type === 'pattern' && <p className='mt-1 alert alert-danger'>تلفن به درستی وارد نشده است</p>}
                                        </MDBCol>

                                        <MDBCol md='12' className='mt-3'>
                                            <button type='submit' style={{ minWidth: '10rem', backgroundColor: '#2957DF', borderRadius: '12px', border: 'none', height: '60px', width: '360px', color: 'white' }}
                                            >{t('Save')}</button>
                                            <button style={{ background: 'none', border: 'none', marginLeft: '3rem', marginRight: '3rem' }} onClick={() => setIsEdit(false)}>{t('Cancel')}</button>
                                        </MDBCol>
                                    </MDBRow>
                                </form>
                            </MDBTabPane>
                            <MDBTabPane tabId="2">
                                <form onSubmit={handleSubmitP(editPassword)}>
                                    <MDBRow>
                                        <MDBCol md='4' className='mb-3'>
                                            <input type={showOldPassword ? 'text' : 'password'} placeholder={t('Current password')}
                                                onChange={e => {
                                                    setValueP('oldPassword', e.target.value)
                                                    setOldPassword(e.target.value)
                                                }}
                                                defaultValue=''
                                                style={{ height: '60px' }}
                                                name='oldPassword'
                                                {...registerP("oldPassword", { required: true, })} />
                                            <MDBIcon icon={showOldPassword ? 'eye-slash' : 'eye'} style={isRtl() ? {
                                                position: 'absolute',
                                                display: 'flex',
                                                left: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            } : {
                                                position: 'absolute',
                                                display: 'flex',
                                                right: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => setShowOldPassword(!showOldPassword)} /></MDBCol>
                                        <MDBCol md='4' className='mb-3'>
                                            <input type={showNewPassword ? 'text' : 'password'} placeholder={t('New password')}
                                                onChange={e => {
                                                    setValueP('newPassword', e.target.value)
                                                    setNewPassword(e.target.value)
                                                }}
                                                defaultValue=''
                                                style={{ height: '60px' }}
                                                name='newPassword'
                                                {...registerP("newPassword", { required: true, })} />
                                            <MDBIcon icon={showNewPassword ? 'eye-slash' : 'eye'} style={isRtl() ? {
                                                position: 'absolute',
                                                display: 'flex',
                                                left: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            } : {
                                                position: 'absolute',
                                                display: 'flex',
                                                right: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => setShowNewPassword(!showNewPassword)} /></MDBCol>
                                        <MDBCol md='4' className='mb-3'>
                                            <input type={showReNewPassword ? 'text' : 'password'} placeholder={t('Repeat new password')}
                                                onChange={e => {
                                                    setValueP('reNewPassword', e.target.value)
                                                    setReNewPassword(e.target.value)
                                                }}
                                                defaultValue=''
                                                style={{ height: '60px' }}
                                                name='reNewPassword'
                                                {...registerP("reNewPassword", { required: true, })} />
                                            <MDBIcon icon={showReNewPassword ? 'eye-slash' : 'eye'} style={isRtl() ? {
                                                position: 'absolute',
                                                display: 'flex',
                                                left: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            } : {
                                                position: 'absolute',
                                                display: 'flex',
                                                right: '1.5rem',
                                                top: '33%',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => setShowReNewPassword(!showReNewPassword)} /></MDBCol>
                                        <MDBCol md='12' className='mt-3'>
                                            <button type='submit' style={{ minWidth: '10rem', backgroundColor: '#2957DF', borderRadius: '12px', border: 'none', height: '60px', width: '360px', color: 'white' }}
                                            >{t('Save')}</button>
                                            <button style={{ background: 'none', border: 'none', marginLeft: '3rem', marginRight: '3rem' }} onClick={() => setIsEdit(false)}>{t('Cancel')}</button>
                                        </MDBCol>
                                    </MDBRow>
                                </form>
                            </MDBTabPane>
                        </MDBTabContent>
                    </div>

                </MDBContainer> :
                <MDBContainer fluid className={Language}>
                    <MDBRow>
                        <MDBCol md='6' className='mb-5' >
                            <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Profile')}</h5>
                        </MDBCol>
                        <MDBCol md='6' className='mb-5'>
                            <button style={!isRtl() ?
                                {
                                    right: '2rem', position: 'absolute', backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                                    borderRadius: '12px', border: 'none', padding: '1rem', width: '240px', height: '56px',
                                    display: 'flex', alignItems: 'center'
                                } :
                                {
                                    left: '2rem', position: 'absolute', backgroundColor: '#1458e9 ', color: 'white', marginRight: '2rem',
                                    borderRadius: '12px', border: 'none', padding: '1rem', width: '240px', height: '56px',
                                    display: 'flex', alignItems: 'center',
                                }}
                                onClick={(e) => setIsEdit(true)}>
                                {t('Edit information')}

                                <img alt="img" src={require('../../assets/img/edit.png')} style={isRtl() ? { left: '1rem', position: 'absolute' } : { right: '1rem', position: 'absolute' }} />
                            </button>

                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <label className='text-muted'>{t('User name')}: </label><span className={isRtl() ? 'mr-4' : 'ml-4'}>{user?.username}</span>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                            <label className='text-muted'>{t('Email')}: </label><span className={isRtl() ? 'mr-4' : 'ml-4'}>{user?.email}</span>
                        </MDBCol>
                        <MDBCol md='6' className='mb-3'>
                        <label className='text-muted'>{t('Full name')}: </label><span className={isRtl() ? 'mr-4' : 'ml-4'}>{user?.first_name + ' ' + user?.last_name}</span>
                    </MDBCol>
                    <MDBCol md='6' className='mb-3'>
                        <label className='text-muted'>{t('Tel')}: </label><span className={isRtl() ? 'mr-4' : 'ml-4'}>{user?.phone}</span>
                    </MDBCol>
                </MDBRow>
                </MDBContainer >
            }
        </>
    )
}