import React, { useEffect, useState } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { BASE_URL, Language, convertTimestap, convertToHijri, convertDateStr, getTimeMode } from '../../config';
import ClapprPlayer from "../../assets/clapper";
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-modern-calendar-datepicker';
import ChannelCard from '../../assets/ChannelCard';
import * as api from '../../api';

export default function Box(props) {
    const { t } = useTranslation(['home']); // "home" is namespace
    const [channelList, setChannelList] = useState([])
    const [selectedChannel, setSelectedChannel] = useState(null)
    const [selectedDay, setSelectedDay] = useState('')
    const [boxCount, setBoxCount] = useState([])
    const [selectedBox, setSelectedBox] = useState(-1)
    const [sourceUrl, setSourceUrl] = useState('')
    useEffect(() => {
        getChannel()
        getBoxes()
        setSelectedDay(convertToHijri())
    }, [])

    useEffect(() => {
        getBoxes()
    }, [selectedChannel, selectedDay])
    useEffect(() => {

        return () => {

        }
    }, [selectedBox])
    useEffect(() => {

        if (selectedBox !== -1 && selectedDay !== '') {
            setSourceUrl(`${BASE_URL}/box_${selectedChannel?.stream_id}_${selectedBox}_${convertTimestap(selectedDay)}.m3u8`);
        }
        else {
            setSourceUrl('')
        }
        return () => {

        }
    }, [selectedChannel, selectedBox, selectedDay])
    const getBoxes = async () => {
        if (selectedDay === '' || selectedChannel === null)
            return

        const { data, error } = await api.getBoxes({
            stream_id: selectedChannel?.stream_id,
            selectedDay: convertTimestap(selectedDay)
        })
        if (data) {
            setBoxCount(data.box_timestamps)
            if (data.box_timestamps.length > 0)
                setSelectedBox(1)
        }
        else {

        }

    }
    const getChannel = async () => {

        const { data, error } = await api.getChannels()
        if (data) {
            setChannelList(data.filter(x => x.media_type === 0).sort((x, y) => x.id > y.id))
            setSelectedChannel(data.filter(x => x.media_type === 0).sort((x, y) => x.id > y.id)[0])
        }

    }


    return (
        <MDBContainer fluid>
            <MDBCol md='12' className='mb-2' >
                <MDBRow>
                    <MDBCol md='6'>
                        <h5 style={{ fontSize: '30px', fontFamily: 'DiodrumArabicBold', marginBottom: '44px' }}>{t('Box')}</h5>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBRow center>
                <div >
                    {sourceUrl &&
                        <ClapprPlayer
                            name={selectedChannel?.name}
                            id={"video" + Math.floor(Math.random() * 100)}
                            key={"video" + Math.floor(Math.random() * 100)}
                            source={sourceUrl}
                            height='439px'
                            width='686px'
                        />}
                </div>
            </MDBRow>
            <MDBRow>
                <MDBCol md='3'></MDBCol>
                <MDBCol md='3'>
                    <div className="duration-picker"
                        style={{ width: '100%', display: 'flex', alignItems: 'center', color: 'white', height: '60px' }}>
                        <DatePicker
                            locale={getTimeMode()}
                            shouldHighlightWeekends
                            style={{ float: 'right', textAlign: 'left', alignItems: 'center', position: 'absolute', left: '1rem' }}
                            value={selectedDay !== '' ? selectedDay : convertToHijri()}
                            onChange={e => setSelectedDay(e)}
                        />

                        <MDBIcon icon='times'
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                right: '2rem',
                                color: 'black',
                                background: '#F3F5F9',
                                padding: '1rem',
                                borderRadius: '1rem',
                                height: '60px',
                            }}
                        />
                    </div>
                </MDBCol>
                <MDBCol md='3'>

                    <select onChange={e => setSelectedBox(e.target.value)} value={selectedBox}>
                        <option value=''>{t('No Choice')}</option>
                        {boxCount.map((item, i) => {
                            return <option value={i + 1}>{convertDateStr(item, true).split(' ')[1]}</option>
                        }
                        )}
                    </select>
                </MDBCol>
                <MDBCol md='3' ></MDBCol>
                {
                    channelList.map((item, i) => {
                        return (
                            <MDBCol key={i} md='2' sm='6' xs='6' size='6' className='mt-3'>
                                <ChannelCard channelName={item.name}
                                    channel={item}
                                    active={selectedChannel === item}
                                    onClick={e => setSelectedChannel(item)}
                                />
                            </MDBCol>
                        )
                    })
                }
            </MDBRow>
        </MDBContainer >
    )
}