import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SET_LOGIN } from './actionTypes';

const useActions = () => {
    const dispatch = useDispatch();
    const setLogin = useCallback(
        (userInput) => {
            dispatch({
                type: SET_LOGIN,
                user: userInput.user,
                isLogin: userInput.isLogin,
            })
        },
        [dispatch]
    );
    return { setLogin };
};

export default useActions;
