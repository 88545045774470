import { useActions } from './features/login';

export const BASE_URL = 'http://46.209.236.179:26000/backend'//process.env.REACT_APP_BACKEND_IP ? process.env.REACT_APP_BACKEND_IP : 'http://46.32.16.90:7013/backend';
export const Language = process.env.REACT_APP_Language ? process.env.REACT_APP_Language : 'ru'; // en => english, fa=> persian, ru=> russian

export const isRtl = () => {
    switch (Language) {
        case 'fa':
            return true;
        case 'en':
            return false;
        case 'ru':
            return false;
        default:
            return false
    }
}
export const getTimeMode = () => {
    if (isRtl()) {
        return 'fa'
    }
    else
        return 'en'
}

export const refreshToken = () => {
    var axios = require('axios');

    var config = {
        method: 'post',
        url: BASE_URL + '/refresh',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('refreshToken')
        }
    };

    axios(config)
        .then(function (response) {
            localStorage.setItem('accessToken', response.data.access_token)
        })
        .catch(function (error) {
            console.log(error);
            window.location = '/';
        });

}
let moment = require('jalali-moment');
moment.locale(getTimeMode());
export const convertTimestap = (d, t) => {
    if (t) {
        if (t.second)
            return moment(d.year + "/" + d.month + '/' + d.day).add(t.hour, 'H').add(t.minute, 'm').add(t.second, 's').unix();
        return moment(d.year + "/" + d.month + '/' + d.day).add(t.hour, 'H').add(t.minute, 'm').unix();
    }
    else {
        return moment(d.year + "/" + d.month + '/' + d.day).unix();
    }
}
export const convertDate = (timestamp, withTime) => {
    let m = moment(timestamp * 1000)
    if (withTime)
        return {
            year: parseInt(m.format('YYYY')), month: parseInt(m.format('MM')), day: parseInt(m.format('DD')),
            hour: parseInt(m.format('HH')), minute: parseInt(m.format('mm')), second: parseInt(m.format('ss'))
        }
    return { year: parseInt(m.format('YYYY')), month: parseInt(m.format('MM')), day: parseInt(m.format('DD')) }
}
export const convertDateStr = (timestamp, withTime, withSecond) => {
    if (withSecond)
        return moment(timestamp * 1000).format('YYYY/MM/DD - HH:mm:ss');
    if (withTime)
        return moment(timestamp * 1000).format('YYYY/MM/DD HH:mm');
    return moment(timestamp * 1000).format('YYYY/MM/DD');
}
export const convertToHijri = (input) => {
    if (input === undefined) {
        let current = new Date();
        input = current
    }
    let d = moment(input).locale(getTimeMode());
    let newD = { year: parseInt(d.format('YYYY')), month: parseInt(d.format('MM')), day: parseInt(d.format('DD')) }
    return newD;
}
export const convertToMiladi = (input) => {
    if (input === undefined) {
        let current = new Date();
        input = current
    }
    let d = moment(input).locale("En");
    let newD = { year: parseInt(d.format('YYYY')), month: parseInt(d.format('MM')), day: parseInt(d.format('DD')) }
    return newD;
}
export const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
}

export const getChannelLogo = (id) => {
    switch (id) {
        case 1:
            return require('./assets/img/1.png')
        case 2:
            return require('./assets/img/2.png')
        case 3:
            return require('./assets/img/3.png')
        case 4:
            return require('./assets/img/4.png')
        case 5:
            return require('./assets/img/5.png')
        case 6:
            return require('./assets/img/khabar.png')
        case 7:
            return require('./assets/img/amozesh.png')
        case 8:
            return require('./assets/img/quran.png')
        case 9:
            return require('./assets/img/mostanad.png')
        case 10:
            return require('./assets/img/omid.png')
        case 11:
            return require('./assets/img/iran-kala.png')
        case 12:
            return require('./assets/img/namayesh.png')
        case 13:
            return require('./assets/img/ofogh.png')
        case 14:
            return require('./assets/img/ifilm.png')
        case 15:
            return require('./assets/img/tamasha.png')
        case 16:
            return require('./assets/img/shoma.png')
        case 17:
            return require('./assets/img/Varzeshtv.png')
        case 18:
            return require('./assets/img/koodak.png')
        case 19:
            return require('./assets/img/salamat.png')
        case 20:
            return require('./assets/img/nasim.png')
        default:
            return require('./assets/img/1.png')
    }
}

const instance = require('axios');
instance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        // Prevent infinite loops
        if (error && (error.response?.status === 401 || error.response?.status === 422) && originalRequest.url === BASE_URL + '/refresh') {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return Promise.reject(error);
        }

        if ((error.response?.status === 401 &&
            error.response?.statusText === "UNAUTHORIZED") || error.response?.status === 422) {
            const refresh_Token = localStorage.getItem('refreshToken');
            if (refresh_Token && refresh_Token !== null) {
                var config = {
                    method: 'post',
                    url: BASE_URL + '/refresh',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('refreshToken')
                    }
                };

                return instance(config)
                    .then(function (response) {
                        localStorage.setItem('accessToken', response.data.access_token);
                        instance.defaults.headers['Authorization'] = "Bearer " + response.data.access_token;
                        originalRequest.headers['Authorization'] = "Bearer " + response.data.access_token;

                        return instance(originalRequest);
                    })
                    .catch(function (error) {
                        console.log(error);
                        Promise.reject(error)
                    });

            } else {
                console.log("Refresh token not available.")
                //window.location = '/'
                const { setLogin } = useActions();
                setLogin({ user: {}, isLogin: false })
            }
        }
        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);
export const instanceAxios = instance;

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
