import React, { useState } from 'react';
import { useActions } from '../../features/login';
import { MDBContainer, MDBCol, toast } from 'mdbreact';
import { BASE_URL, Language } from '../../config';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './Login.scss'

const Login = () => {
    const { t } = useTranslation(['home']); // "home" is namespace
    const { setLogin } = useActions();

    const submitHandler = (e) => {
        e.preventDefault();

        var data = new FormData();
        data.append('username', usernameInput);
        data.append('password', password);

        var config = {
            method: 'post',
            url: BASE_URL + '/login',
            headers: {
                username: usernameInput,
                password: password
            },
            data: data
        };




        axios(config)
            .then(function (response) {
                localStorage.setItem('accessToken', response.data.access_token)
                localStorage.setItem('refreshToken', response.data.refresh_token)
                setLogin({ user: {}, isLogin: true })
            })
            .catch(function (error) {
                console.error(error);
                toast.error(t('Wrong user name or password'), {
                    closeButton: false
                });
            });
    }
    const [usernameInput, setUsernameInput] = useState('')
    const [password, setPassword] = useState('')
    return (
        <MDBContainer fluid className={Language + ' login '} >
            <p className='alert alert-danger'>{t('For full system operation, please use Firefox browser')}</p>
            <form className="wrapper "

                onSubmit={e => submitHandler(e)} >
                <MDBCol md='6' sm='12' xs='12' style={{
                    backgroundColor: 'white',
                    borderTopRightRadius: '50px',
                    borderBottomRightRadius: '50px',
                    padding: '3rem'
                }} className=' d-flex align-items-center justify-content-center flex-column'>
                    <input
                        className='form-control'
                        style={{
                            margin: "0 auto 15px auto",
                        }}
                        type="text"
                        placeholder={t('Username')}
                        name="username"
                        onChange={e => setUsernameInput(e.target.value)}
                    />
                    <label htmlFor="inputPassword" className="sr-only">Password</label>
                    <input className='form-control'
                        style={{
                            margin: "0 auto 30px auto",
                        }}
                        type="password"
                        placeholder={t('Password')}
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                    />

                    <button
                        type="submit"
                        style={{
                            fontFamily: "AnjomanBold",
                            background: "#2A57E0",
                            borderRadius: "1rem",
                            border: 'none',
                            maxWidth: "460px",
                            width: "100%",
                            color: "#fff",
                            marginBottom: "35px",
                        }}
                    >
                        {t('Login')}
                    </button>
                </MDBCol>
                <MDBCol md='6' sm='12' xs='12' className='login-pic d-flex align-items-center justify-content-center' >
                    <img alt="img" src={require('../../assets/img/logo.png')}
                        style={{
                            width: "12rem", verticalAlign: 'middle',
                            borderStyle: 'none'
                        }} />
                </MDBCol>
            </form>

        </MDBContainer>
    );
};

export default Login;
